/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// collapse.coffee
//= ==============================================================================

// Adds/removes an "in" class on a target based on element state.
new Component('collapse', ['collapsible'], (c) => $(document).ready(() => {
  const $parent = $(c.collapsible).parent();
  const data = $(c.collapsible).data();
  if (data && data.collapseType) {
    const $children = $parent.find(data.collapseType);
    if ($children.length === 0) {
      $parent.hide();
    }
  }

  return $(c.this).on('click', (e) => {
    if (c.this.dataset.collapseCollapsible === 'expand_all') {
      $(c.this).attr('aria-expanded', true);
      $('.accordion-heading').attr('aria-expanded', true);
      $('.accordion-content').addClass('in');
    } else if (c.this.dataset.collapseCollapsible === 'collapse_all') {
      $(c.this).attr('aria-expanded', false);
      $('.accordion-heading').attr('aria-expanded', false);
      $('.accordion-content').removeClass('in');
    } else if ($(c.this).attr('aria-expanded') === 'true') {
      $(c.this).attr('aria-expanded', false);
      $(c.collapsible).removeClass('in');
    } else {
      $(c.this).attr('aria-expanded', true);
      $(c.collapsible).addClass('in');
    }

    if ($('.accordion-heading:visible[aria-expanded="true"]').length === $('.accordion-heading:visible').length) {
      $('.accordion-switcher-expand').addClass('hide');
      return $('.accordion-switcher-collapse').removeClass('hide');
    }
    $('.accordion-switcher-collapse').addClass('hide');
    return $('.accordion-switcher-expand').removeClass('hide');
  });
}));
