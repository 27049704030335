/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// datetime.coffee
//= ==============================================================================

// Handles concatenation of date and time fields into a hidden field.
new Component('datetime', ['date', 'time', 'time_picker_outbound_will_call', 'time_picker_return_will_call'], ((c) => {
  const changeHandler = function (will_call_direction) {
    let datetime;
    const date = $(c.date).val();
    const time = $(c.time).val();

    const outboundWillCall = $(c.time_picker_outbound_will_call).is(':checked');
    const returnWillCall = $(c.time_picker_return_will_call).is(':checked');

    if (outboundWillCall || returnWillCall) {
      datetime = `${date} will_call`;
    } else {
      datetime = `${date} ${time}`;
    }
    return $(c.this).val(datetime);
  };

  $(c.date).on('change', () => changeHandler());
  $(c.time).on('change', () => changeHandler());
  $(c.time_picker_outbound_will_call).on('change', () => changeHandler('outbound'));
  $(c.time_picker_return_will_call).on('change', () => changeHandler('return'));
  return $('document').ready(() => changeHandler());
}));
