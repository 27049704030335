/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// overlay-ride-menu.coffee
//= ==============================================================================

// Adds listeners to open/close overlay-ride-menu.
new Component('overlay-ride-menu', ['open'], ((c) => {
  const topOffset = 10;
  const leftOffset = -10;

  // Add event to close overlay-ride-menu when clicked outside
  const addCloseEvent = () => $(document).on('mouseup.overlayMenu', (e) => {
    const container = $(c.this);
    if (!container.is(e.target) && (container.has(e.target).length === 0)) {
      container.removeClass('is-active');
      return $(document).off('mouseup.overlayMenu');
    }
  });

  // Add provider decline text to the ride_decline modal
  const setProviderDeclineText = function () {
    const providerName = $(c.open).data('provider-name');
    return $('#providerDeclineText').html(`Decline the ride on behalf of ${providerName}.`);
  };

  // Open overlay-ride-menu
  $(c.open).on('click', (e) => {
    e.preventDefault();

    // Show overlay-ride-menu around the position of click
    $(c.this).css({ left: e.pageX + leftOffset, top: e.pageY + topOffset });
    $(c.this).addClass('is-active');

    setProviderDeclineText();
    return addCloseEvent();
  });

  // Close overly-ride-menu on clicking .mark-decline option
  return $(c.this).find('.mark-decline').on('click', (e) => $(c.this).removeClass('is-active'));
}));
