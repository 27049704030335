/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// venue-mapping.coffee
//= ==============================================================================

// Handles the venue mapping select element for locations
new Component('venue-mapping', [], ((c) => {
  const authToken = $(c.this).data('auth-token');
  let location = {};

  const setLocationData = function () {
    const $modal = $('.modal-overlay.is-active');
    const $zonesSelect = $modal.find('.venue-zones');
    location = {
      id: parseInt($modal.find('.location-id').val()),
      latitude: $modal.find('.latitude').val(),
      longitude: $modal.find('.longitude').val(),
      venue_mapping_id: $modal.find('.venue-mapping-id').val(),
    };
    return $zonesSelect.off().on('change', function (e) {
      $venueMappingId.val($(this, ':selected').val());
    });
  };

  const clearAllZoneSelects = function () {
    const $zonesBlock = $('.modal-overlay .venue-zones-block');
    $zonesBlock.each((i, $block) => {
      const $zonesSelect = $($block).find('.venue-zones');
      const $defaultOption = $zonesSelect.find('.venue-zones-no-zone');
      $zonesSelect.html('');
      return $zonesSelect.prepend($defaultOption);
    });
    return $zonesBlock.hide();
  };

  $(document).on('locationModalClosed', (e) => clearAllZoneSelects());

  $(document).on('locationUpdaterOpened', (e, locationId) => {
    setLocationData();
    if (location.id === parseInt(locationId)) {
      return getVenues({ lat: location.latitude, lng: location.longitude });
    }
  });

  $('body').off().on('google-autocomplete-place-changed', (e, data) => getVenues(data));

  var getVenues = function (data) {
    const url = '/api/v1/locations/venues';

    // format data
    data.lat = parseFloat(data.lat).toFixed(7);
    data.lng = parseFloat(data.lng).toFixed(7);

    const headers = { Authorization: `Token token=${authToken}` };

    return $.ajax({
      data,
      dataType: 'json',
      error(error) {
        return console.log(error);
      },
      headers,
      success(response, status) {
        setLocationData();
        const $zonesBlock = $('.modal-overlay.is-active .venue-zones-block');
        const $venueMappingId = $('.modal-overlay.is-active').find('.venue-mapping-id');
        const $zonesSelect = $zonesBlock.find('.venue-zones');
        const $defaultOption = $zonesSelect.find('.venue-zones-no-zone');

        if (response.data.length) {
          $zonesSelect.html('');
          $zonesBlock.show();

          response.data.forEach((zone) => {
            const $zone = document.createElement('option');
            $zone.value = zone.id;
            $zone.text = zone.zone_name;
            return $zonesSelect.append($zone);
          });

          $zonesSelect.prepend($defaultOption);

          if (location.venue_mapping_id != null) {
            const venueIdExistsInData = (response.data.filter((venue) => venue.id === parseInt(location.venue_mapping_id)))[0];
            $zonesSelect.val(venueIdExistsInData ? location.venue_mapping_id : '0');
            $venueMappingId.val(venueIdExistsInData ? location.venue_mapping_id : null);

            if (venueIdExistsInData) {
              $(document).trigger('google-autocomplete-place-changed-trigger-map', {
                lat: venueIdExistsInData.zone_latitude,
                lng: venueIdExistsInData.zone_longitude,
              });
            }
          } else {
            $zonesSelect.val('0');
            $venueMappingId.val(null);
          }

          $zonesSelect.off().on('change', function (e) {
            $venueMappingId.val($(this, ':selected').val());
            const selected = $(this, ':selected').val();
            const venueSelected = (response.data.filter((venue) => venue.id === parseInt(selected)))[0];
            $(document).trigger('google-autocomplete-place-changed-trigger-map', {
              lat: venueSelected.zone_latitude,
              lng: venueSelected.zone_longitude,
            });
          });
        } else {
          $zonesBlock.hide();
          $venueMappingId.val(null);
        }
      },
      type: 'GET',
      url,
    });
  };
}));
