import React, { useMemo } from 'react';

import type { CellComponent, RideColumn } from '@/features/RidesTable/types';

import Cell from '@/features/RidesTable/components/Table/Cell';
import RideStatusBadge from '@/features/RideStatusBadge';
import {
  careCoordinatorRideBadgeText,
  humanizedTimeliness,
} from '@/features/RideStatusBadge/helpers';
import {
  CC_STATUS_BADGE_TEXT,
  ICONS,
  STYLES,
} from '@/features/RideStatusBadge/types';

const RideStatusBadgeCell: CellComponent = ({ ride, href }) => {
  const { name, badgeText } = useMemo(
    () => ({
      name: ride.riderFullName.split(' ').join('-').toLowerCase(),
      badgeText: careCoordinatorRideBadgeText(ride),
    }),
    [ride],
  );

  const style = STYLES[badgeText];
  const Icon = ICONS[badgeText];
  let SubIcon = null;
  let subtext = null;

  if (badgeText === CC_STATUS_BADGE_TEXT.inProgress) {
    subtext = humanizedTimeliness(ride);
  }

  const displayTooltip = false;

  return (
    <Cell
      label="Status"
      tdClass="time-remaining -overflow"
      href={typeof href === 'function' ? href(ride) : href}
      anchorClass="py-8"
    >
      <RideStatusBadge.Root color={style}>
        <RideStatusBadge.Content id={`${name}-bid-status-message`}>
          {Icon && <Icon />}
          {badgeText}
        </RideStatusBadge.Content>

        {displayTooltip && (
          <RideStatusBadge.Tooltip>
            <p>
              You can claim this trip prior to others in the area for a set
              period of time
            </p>
          </RideStatusBadge.Tooltip>
        )}

        {subtext && (
          <RideStatusBadge.Subtext>
            {/* {SubIcon && <SubIcon />} {subtext} */}
            {subtext}
          </RideStatusBadge.Subtext>
        )}
      </RideStatusBadge.Root>
    </Cell>
  );
};

export default RideStatusBadgeCell;
