/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
new Component('location-time-display', ['select'], ((component) => {
  const $typeSelect = $(component.select);
  const $component = $(component.this);

  const defaultMessage = $component.data('default-message');
  const direction = $component.data('direction');

  const pickupLocation = $component.data('pickup-location');
  const dropoffLocation = $component.data('dropoff-location');

  const init = function () {
    component.memory.data = {};

    if (pickupLocation && dropoffLocation) {
      component.memory.data.pickup = pickupLocation;
      component.memory.data.dropoff = dropoffLocation;
      component.memory.data.leave_now = dropoffLocation;
    }

    update();

    $(document).on('location-updated', (event, data) => {
      component.memory.data[data.direction] = data.location;
      return update();
    });

    return $typeSelect.on('change', () => update());
  };

  const set = function (str) {
    str ??= defaultMessage;
    return $component.html(str);
  };

  var update = function () {
    const type = $typeSelect.val();

    switch (direction) {
      case 'outbound':
        if (component.memory.data[type] != null) {
          let str = type === 'pickup' ? 'at ' : 'to ';
          str += component.memory.data[type];
          return set(str);
        } return set();

      case 'return':
        if (type === 'pickup') {
          if (component.memory.data.dropoff != null) {
            return set(`at ${component.memory.data.dropoff}`);
          } return set();
        } if ((type === 'dropoff') || (type === 'leave_now')) {
          if (component.memory.data.pickup != null) {
            return set(`to ${component.memory.data.pickup}`);
          } return set();
        }
        return set();
    }
  };

  return init();
}));
