/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// patient-card-adder.coffee
//= ==============================================================================

new Component('patient-card-adder', ['cards', 'empty', 'fields', 'hfields',
  'overlay', 'open', 'close', 'add', 'edit', 'update', 'remove', 'delete', 'output',
  'template', 'delete_card', 'no_cards', 'output_cards_chooser', 'delete_cards',
  'delete_card_chooser_output', 'stripe_key', 'deleted_card_replacement', 'auth-token'], ((c) => {
  // variables
  const template = Handlebars.compile($(c.template).html()); // new card list template
  let newCardId = parseInt($(c.this).data('id'));
  let editingCardId = null;
  let removingCardId = null;

  const scope = $(c.this).attr('data-scope');

  const stripe = Stripe($(c.this).data('stripe_key'));
  const headers = { Authorization: `Token token=${$(c.this).data('auth-token')}` };

  let number;
  let expiry;
  let cvc;

  // selector for stripe forms
  let number_selector = `.modal-overlay.is-active #payer_cards_attributes_${newCardId}_number`;
  let expiry_selector = `.modal-overlay.is-active #payer_cards_attributes_${newCardId}_expiry`;
  let cvv_selector = `.modal-overlay.is-active #payer_cards_attributes_${newCardId}_cvv`;

  const stripe_style = {
    base: {
      '::placeholder': {
        color: 'rgba(117, 117, 117, .47)',
      },
      color: '#71767d',
      fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif',
      fontSize: '14.5px',
      fontWeight: 200,
      letterSpacing: '0.2px',
      lineHeight: '21px',
      padding: 0,
    },
  };

  const use_stripe = function () {
    number_selector = `.modal-overlay.is-active #payer_cards_attributes_${newCardId}_number`;
    expiry_selector = `.modal-overlay.is-active #payer_cards_attributes_${newCardId}_expiry`;
    cvv_selector = `.modal-overlay.is-active #payer_cards_attributes_${newCardId}_cvv`;
    const elements = stripe.elements({
      fonts: [
        {
          family: '"Source Sans Pro"',
          fontWeight: 200,
          src: 'local("Source Sans Pro"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNzbP97U9sKh0jjxbPbfOKg.ttf) format("truetype")',
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
        },
      ],
    });
    number = elements.create('cardNumber', { placeholder: 'Card Number', style: stripe_style });
    expiry = elements.create('cardExpiry', { style: stripe_style });
    cvc = elements.create('cardCvc', { style: stripe_style });

    number.mount(number_selector);
    expiry.mount(expiry_selector);
    cvc.mount(cvv_selector);

    // add error classes if number is empty
    number.on('blur', (e) => {
      $(c.fields).find(number_selector).parent('.field').addClass('disabled');
      if (number._empty) {
        return $(c.fields).find(number_selector).parent('.field').addClass('-error');
      }
    });

    // add error classes if number is wrong
    number.on('change', (e) => {
      if (e.error) {
        return $(c.fields).find(number_selector).parent('.field').addClass('-error');
      }
      $(c.fields).find(number_selector).parent('.field').removeClass('-error');
      return $(c.add).attr('disabled', false);
    });

    // add error classes if expiry is empty
    expiry.on('blur', (e) => {
      if (expiry._empty) {
        return $(c.fields).find(expiry_selector).parent('.field').addClass('-error');
      }
    });

    // add error classes if expiry is wrong
    expiry.on('change', (e) => {
      if (e.error) {
        return $(c.fields).find(expiry_selector).parent('.field').addClass('-error');
      }
      $(c.fields).find(expiry_selector).parent('.field').removeClass('-error');
      return $(c.add).attr('disabled', false);
    });

    // add error classes if cvv is empty
    cvc.on('blur', (e) => {
      if (cvc._empty) {
        return $(c.fields).find(cvv_selector).parent('.field').addClass('-error');
      }
    });

    // add error classes if cvv is wrong
    return cvc.on('change', (e) => {
      if (e.error) {
        return $(c.fields).find(cvv_selector).parent('.field').addClass('-error');
      }
      $(c.fields).find(cvv_selector).parent('.field').removeClass('-error');
      return $(c.add).attr('disabled', false);
    });
  };

  // Clear modal fields
  const clear_modal_fields = function () {
    $(c.overlay).find(c.fields).find('.field.-error').removeClass('-error');
    $(c.overlay).find(c.fields).find('input').attr('checked', false);
    return $(c.overlay).find(c.fields).find('input').val('');
  };

  // Hide/show no credit card added message
  const hide_show_empty_card_message = function () {
    if ($(c.cards).length === 0) {
      return $(c.empty).addClass('is-active');
    }
    return $(c.empty).hide();
  };

  const get_card_class = function (card_type) {
    const classes = {
      'American Express': '-amex',
      Discover: '-discover',
      MasterCard: '-master-card',
      Visa: '-visa',
    };
    return classes[card_type];
  };

  const update_deleted_card_attributes = function (replacementCardId, deletedCardId) {
    const replacementCardSelector = `#payer_cards_attributes_${replacementCardId}`;
    const deletingCardSelector = `#payer_cards_attributes_${deletedCardId}`;

    $(`${deletingCardSelector}_replaced_with`).val(replacementCardId);
    $(`${deletingCardSelector}_destroy`).val(true);

    $(`${replacementCardSelector}_card_have_trips`).val(true);
    $(`${replacementCardSelector}_replaced_for`).val(deletedCardId);

    return $(c.cards).each(function () {
      const cardId = $(this).attr('id') ? ($(this).attr('id').match(/\w+-(\d+)/)[1]) : null;
      if (cardId && ($(this).find(`#payer_cards_attributes_${cardId}_replaced_with`).val() === deletedCardId)) {
        return $(this).find(`#payer_cards_attributes_${cardId}_replaced_with`).val(replacementCardId);
      }
    });
  };

  const modal_closed_without_action = function () {
    $(c.overlay).removeClass('is-active');
    if (!editingCardId) {
      newCardId -= 1;
    }
    editingCardId = null;

    if (removingCardId) {
      $(`#delete-card-${removingCardId}`).show();
      return removingCardId = null;
    }
  };

  hide_show_empty_card_message();

  // listener called when a card is selected as a replacement for the other deleted card
  $(c.deleted_card_replacement).on('click', (e) => {
    e.preventDefault();
    if (!removingCardId) {
      $(c.overlay).removeClass('is-active');
    }

    const $selected_card = $("[name='card-chooser-choice']:checked");
    const replacementCardId = $selected_card.val();
    // if not card is choosen in deleted card replacement modal
    if (!replacementCardId) {
      return;
    }
    update_deleted_card_attributes(replacementCardId, removingCardId);
    $selected_card.prop('checked', false); // reset selected card for next time deleted card selection
    $(`#card-${removingCardId}`).hide();
    removingCardId = null;
    clear_modal_fields();
    return $(c.overlay).removeClass('is-active');
  });

  // Open modal (set modal to state 1), and clear fields
  $(c.open).on('click', (e) => {
    e.preventDefault();
    clear_modal_fields();
    $(c.this).removeClass('state-3 state-2').addClass('state-1');
    $(c.overlay).addClass('is-active');

    // Add an incrementing index to the names/ids of modal fields (and labels).
    newCardId += 1;
    $(c.fields).find('[for]').each(function () {
      return $(this).attr('for', $(this).attr('for').replace(/\_\d+\_/g, `_${newCardId}_`));
    });
    $(c.fields).find('[name]').each(function () {
      return $(this).attr('name', $(this).attr('name').replace(/\[\d+\]/g, `[${newCardId}]`));
    });
    $(c.fields).find('[id]').each(function () {
      return $(this).attr('id', $(this).attr('id').replace(/\_\d+\_/g, `_${newCardId}_`));
    });
    return use_stripe();
  });

  // Click close icon to close modal
  $(c.close).on('click', (e) => {
    e.preventDefault();
    return modal_closed_without_action();
  });

  // Click overlay to close modal
  $(c.overlay).on('click', (e) => modal_closed_without_action());

  // Prevent modal close by clicking within modal
  $(c.this).on('click', (e) => e.stopPropagation());

  // Add a new card. Copy modal fields into template and append to output
  // container.
  $(c.add).on('click', function (e) {
    e.preventDefault();
    $(this).attr('disabled', true);
    const card_select = `#payer_cards_attributes_${newCardId}`;
    return stripe.createToken(number, { name: $(c.fields).find(`${card_select}_name`).val() }).then((result) => {
      if (result.error) {
        $(c.fields).find(number_selector).parent('.field').addClass('-error');
        $(c.fields).find(expiry_selector).parent('.field').addClass('-error');
        $(c.fields).find(cvv_selector).parent('.field').addClass('-error');
      } else {
        // remove required credit card atrribute as one card is added
        $('#payer-type-patient').parents().removeClass('-error');
        $('#payer-type-patient').removeAttr('required');
        $("[data-validator-target='submit-patient-button']").attr('disabled', false);

        const stripe_token = result.token;
        const card_type = stripe_token.card.brand;
        const card_name = stripe_token.card.name;
        const stripe_token_id = stripe_token.id;
        const expiry_month = (`0${stripe_token.card.exp_month}`).slice(-2);
        const expiry_year = stripe_token.card.exp_year % 100;
        const last_4 = stripe_token.card.last4;

        const card_selector = `#payer_cards_attributes_${newCardId}`;
        $(`${card_selector}_stripe_token`).val(stripe_token_id);
        $(`${card_selector}_expiry_date`).val(`${expiry_month}/${expiry_year}`);
        $(`${card_selector}_card_number`).val(last_4);
        $(`${card_selector}_card_added`).val(true);
        $(`${card_selector}_card_type`).val(card_type);
        $(`${card_selector}_destroy`).val(false);
        $(`${card_selector}_replaced_for`).val('');
        $(`${card_selector}_replaced_with`).val('');
        $(`${card_selector}_card_have_trips`).val(false);

        // Render new card element, append cloned fields, and clean up data
        // attributes. Needed to manually remove data-patient-card-adder-target to
        // prevent default selectors from targeting dynamically added elements.
        const $card_fields = $(c.fields).clone(true, true);
        const html = template({
          card_class: get_card_class(card_type), card_type, id: newCardId, last_four: last_4,
        });
        $(c.output).append(html);
        const $new_card = $(c.output).find(c.cards).last();
        $new_card.attr('id', `card-${newCardId}`);
        $new_card.find(c.hfields).append($card_fields);
        $new_card.find(c.fields).removeAttr('data-patient-card-adder-target');

        const delete_card_template = Handlebars.compile($(c.delete_card).html());
        const delete_html = delete_card_template({
          card_class: get_card_class(card_type),
          card_name,
          card_type,
          expiry_month,
          expiry_year,
          id: newCardId,
          last_four: last_4,
        });
        $(c.delete_card_chooser_output).append(delete_html);

        if (removingCardId) {
          update_deleted_card_attributes(newCardId, removingCardId);
          $(`#card-${removingCardId}`).hide();
          removingCardId = null;
        }

        clear_modal_fields();
        $(c.overlay).removeClass('is-active');
      }
      return hide_show_empty_card_message();
    });
  });

  // Redisplay empty message if all cards get removed
  return $(document).on('click', c.remove, function (e) {
    e.preventDefault();

    const single_card_without_trips_message = 'You must have at least one card on file in order to book trips. Would like to add a new card at this time?';
    const single_card_without_trips_modal_title = 'Unable to Delete Card';

    const single_card_with_trips_message = 'This card was chosen as the payment method for a trip that has already been scheduled. Please add a new card.';
    const single_card_with_trips_modal_title = 'Add Replacement Card';

    const card_with_trips_message = 'This card was chosen as the payment method for a trip that has already been scheduled. Please either select a replacement card or add a new card.';
    const card_with_trips_modal_title = 'Select Replacement Card';

    $("[data-patient-card-adder-target='delete-card-replacement']").show();
    $('.replacement_message').html(card_with_trips_message);
    $('.delete-modal-title').html(card_with_trips_modal_title);

    const $card = $(this).parents(c.cards).first();
    const cardId = $card.attr('id').match(/\w+-(\d+)/)[1];

    if ($card.find(`#payer_cards_attributes_${cardId}_card_have_trips`).val() === 'false') {
      if ($(`${c.cards}:visible`).length === 1) {
        removingCardId = cardId;
        $(`#delete-card-${cardId}`).hide();
        $("[data-patient-card-adder-target='delete-card-replacement']").hide();
        $('.delete-modal-title').html(single_card_without_trips_modal_title);
        $('.replacement_message').html(single_card_without_trips_message);
        $(c.this).removeClass('state-2 state-1').addClass('state-3');
        $(c.overlay).addClass('is-active');
      } else {
        $(this).parents(c.cards).find(`#payer_cards_attributes_${cardId}_destroy`).val(true);
        $(this).parents(c.cards).hide();
        $(`#delete-card-${cardId}`).hide();
      }
    } else {
      removingCardId = cardId;
      if ($(`${c.cards}:visible`).length === 1) {
        $("[data-patient-card-adder-target='delete-card-replacement']").hide();
        $('.replacement_message').html(single_card_with_trips_message);
        $('.delete-modal-title').html(single_card_with_trips_modal_title);
      }
      $(`#delete-card-${cardId}`).hide();
      $(c.this).removeClass('state-2 state-1').addClass('state-3');
      $(c.overlay).addClass('is-active');
    }
    return hide_show_empty_card_message();
  });
}));
