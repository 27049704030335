/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
new Component('google-autocomplete', ['street-address', 'city', 'state', 'zip', 'latitude', 'longitude'], ((c) => {
  const autocompleteParams = {
    componentRestrictions: {
      country: 'us',
    },
  };

  let autocomplete = null;

  const addressComponentsMap = function (components) {
    const map = {};
    for (const component of Array.from(components)) {
      map[component.types[0]] = component.long_name;
    }
    return map;
  };

  const getAutocomplete = function (autocomplete, callback) {
    const place = autocomplete.getPlace();
    if ((typeof place.geometry === 'undefined') || !place.geometry) {
      $('#address-error').html("The address you've provided could not be saved, please try again").removeClass('hidden-in-modal');
    } else {
      $('#address-error').addClass('hidden-in-modal').html();
    }
    return callback(place);
  };

  const onPlaceChanged = () => getAutocomplete(autocomplete, (result) => {
    if (result.geometry != null) {
      const components = addressComponentsMap(result.address_components);
      const lat = result.geometry.location.lat();
      const lng = result.geometry.location.lng();
      const city = (() => {
        if ('locality' in components) {
          return components.locality;
        } if ('sublocality_level_1' in components) {
          return components.sublocality_level_1;
        } if ('neighborhood' in components) {
          return components.neighborhood;
        } if ('administrative_area_level_3' in components) {
          return components.administrative_area_level_3;
        }
      })();

      const eventData = {
        lat,
        lng,
      };

      $('body').trigger('google-autocomplete-place-changed', eventData);
      $(document).trigger('google-autocomplete-place-changed-trigger-map', eventData);

      const input_val = $('.modal-overlay.is-active [data-component="google-autocomplete"]').val();
      const street_address = (components.street_address || components.street_number) && components.route
        // Google API returns both a street address (e.g. 5176) and a route
        // (e.g. Apricot St).
        ? [components.street_address || components.street_number, components.route].join(' ')
        : input_val.indexOf(',')
        // The input element has a string formatted as something similar to
        // 5176 Apricot St, Thadmin Town, US. The Google autocomplete should
        // guarantee this sort of formatting.
          ? input_val.substring(0, input_val.indexOf(','))
          :
        // Something went awry.
          '';
      $('.modal-overlay.is-active .street-address').val(street_address);
      $('.modal-overlay.is-active .city').val(city);
      $('.modal-overlay.is-active .state').val(components.administrative_area_level_1);
      $('.modal-overlay.is-active .zip').val(components.postal_code);
      $('.modal-overlay.is-active .latitude').val(lat);
      $('.modal-overlay.is-active .longitude').val(lng);
      $('.modal-overlay.is-active .autocomplete-location-name').val(result.name);
      return validateAddress();
    }
  });

  const generateAutocomplete = function (e) {
    if (autocomplete != null) {
      google.maps.event.clearInstanceListeners(autocomplete);
      $('.modal').find('[data-component="google-autocomplete"]').off();
      $('.pac-container').detach();
    }

    autocomplete = new google.maps.places.Autocomplete($('.modal-overlay.is-active').find('[data-component="google-autocomplete"]')[0], autocompleteParams);
    return autocomplete.addListener('place_changed', onPlaceChanged);
  };

  $(document).on('locationUpdaterOpened', (e) => generateAutocomplete(e));

  $(document).on('locationAdderOpened', (e) => generateAutocomplete(e));

  var validateAddress = function () {
    let error = '';
    if ($('.modal-overlay.is-active .street-address').val().trim().length === 0) {
      error = 'Invalid street address';
    } else if ($('.modal-overlay.is-active .zip').val().length === 0) {
      error = 'Zipcode can not be empty';
    } else if ($('.modal-overlay.is-active .state').val().length === 0) {
      error = 'State can not be empty';
    } else if ($('.modal-overlay.is-active .city').val().length === 0) {
      error = 'City can not be empty';
    }

    if (error.length === 0) {
      $('.modal-overlay.is-active .full-address').removeClass('-error');
    } else {
      $('.modal-overlay.is-active .full-address').addClass('-error');
      $('.modal-overlay.is-active .full-address .field-error-note small').html(error);
    }

    return $('.modal-overlay.is-active .full-address').trigger('change');
  };

  return false;
}));
