/* eslint-disable import/prefer-default-export */
import type {
  CreateUserPreferencesParams,
  UpdateUserPreferencesParams,
  UserColumnPreferencesState,
} from '@/features/RidesTable/store/userColumnPreferencesSlice';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getUserPrefs: build.query({
      query: (userId: number) => {
        return {
          url: `user_preferences/${userId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: UserColumnPreferencesState) =>
        keysToCamel(response) as UserColumnPreferencesState,
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as Record<string, unknown>, // TODO: Type error response
    }),
    createUserPrefs: build.mutation<void, Partial<CreateUserPreferencesParams>>(
      {
        query: (params) => ({
          url: '/user_preferences',
          method: 'POST',
          body: params,
        }),
      },
    ),
    updateUserPrefs: build.mutation<void, Partial<UpdateUserPreferencesParams>>(
      {
        query: (params) => {
          const { userId } = params;
          if (userId === undefined) {
            throw new Error('userId must be defined');
          }

          return {
            url: `user_preferences/${userId}`,
            method: 'PATCH',
            body: params,
          };
        },
      },
    ),
  }),
});

export const {
  useGetUserPrefsQuery,
  useCreateUserPrefsMutation,
  useUpdateUserPrefsMutation,
} = extendedApi;
