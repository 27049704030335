import type { RideStatusQueryParams } from '@/types';
import type { RideFilterState } from '../types';

import { useSelector } from 'react-redux';

import { useGetRidesQuery } from '@/api';

import { selectRideFilters } from '../store/ridesFilterSlice';

const defaultParams: Partial<RideFilterState> = {
  hospitalIds: [],
  items: 10,
  page: 1,
  radius: null,
  vehicleTypes: [],
  willCall: null,
};

const useRideCount = (status: RideStatusQueryParams) => {
  const { search, endTime, startTime } = useSelector(selectRideFilters);

  const { data: { totalCount: count } = { totalCount: 0 } } = useGetRidesQuery({
    ...defaultParams,
    ...{ startTime, endTime, search },
    ...status,
  } as RideFilterState);

  return { count };
};

export default useRideCount;
