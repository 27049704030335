/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// multi-select-filter.coffee
//= ==============================================================================

new Component('multi-select-filter', ['apply', 'cancel'], ((c) => {
  const attributeKey = $(c.this).data('attributeKey');
  const initiallySelectedIds = $(c.this).data('selectedIds') || [];
  const headers = { Authorization: `Token token=${$('#auth-token').data('token')}` };

  const disableApply = () => $(`#${attributeKey}_filter`).find('#apply').attr('disabled', true);

  const enableApply = () => $(`#${attributeKey}_filter`).find('#apply').attr('disabled', false);

  const handleDisableApply = function () {
    const ids = $(`#${attributeKey}`).val() || [];
    const disable_apply = (ids.length === 0)
      || ((initiallySelectedIds.length === ids.length)
        && (new Set([...Array.from(ids), ...Array.from(initiallySelectedIds)]).size === ids.length));

    if (disable_apply) {
      return disableApply();
    }
    return enableApply();
  };

  $(`#${attributeKey}`).multiselect({
    onOptionClick: handleDisableApply,
    onSelectAll: handleDisableApply,
    search: true,
    selectAll: true,
    texts: {
      search: 'Find by name',
    },
  });

  const setInitialState = function () {
    $(`#${attributeKey}-multi-select`)
      .find('select[multiple]').multiselect('reset');
    $(`#${attributeKey}-multi-select`)
      .find('.ms-options').addClass('custom-ms-options');
    $(`#${attributeKey}-multi-select`)
      .find('.ms-options-wrap').find(':button').addClass('hide');
    $(`#${attributeKey}-multi-select`)
      .find('.ms-search').find('input').addClass('input -search');
    return disableApply();
  };

  setInitialState();

  const apply = function () {
    disableApply();
    const ids = $(`#${attributeKey}`).val() || [];
    const attribute_key_mapping = {
      facility: 'facility_ids',
    };

    return $.ajax({
      data: {
        attribute_key: attribute_key_mapping[attributeKey],
        attribute_values: ids,
      },
      dataType: 'json',
      error() {
        enableApply();
        return Flash.error('Oops, something went wrong. Please refresh this page and try again.');
      },
      headers,
      success(response, status) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('filter_query_id', response.data.id);
        return window.location.search = urlParams.toString();
      },
      type: 'POST',
      url: '/api/v1/filter_queries/',
    });
  };

  $(c.cancel).on('click', (e) => $(`#${attributeKey}_filter`).find('.filter').removeClass('-is-active'));

  $(c.apply).on('click', (e) => apply());

  $(c.this).on('click', (e) => {
    const isFilterModalOpen = $(`#${attributeKey}_filter`).find('.filter').hasClass('-is-active');
    if (!isFilterModalOpen) {
      setInitialState();
      return $(`#${attributeKey}_filter`).find('.filter').addClass('-is-active');
    }
  });

  return $(document).on('click', (e) => {
    const isFilterModalOpen = $(`#${attributeKey}_filter`).find('.filter').hasClass('-is-active');
    if (isFilterModalOpen && ($(e.target).attr('data-attribute-key') !== attributeKey)) {
      e.preventDefault();
      return $(`#${attributeKey}_filter`).find('.filter').removeClass('-is-active');
    }
  });
}));
