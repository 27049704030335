/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// row-count.coffee
//= ==============================================================================

// Provides a count of rows in a trips table
new Component('row-count', [], (c) => $(document).ready(() => {
  const target = $(c.this).data('row-count-target');
  const $data_target = $(`[data-${target}]`);

  const updateCount = function (number) {
    const text = `${number} Trip${number !== 1 ? 's' : ''} on `;
    return $('[data-trip-count]').html(text);
  };

  return $data_target.on('recount_rows', (e) => {
    const count = $(`[data-${target}] tr:visible`).length - 1;
    return updateCount(count);
  });
}));
