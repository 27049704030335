import React from 'react';

import { Cross2Icon } from '@radix-ui/react-icons';

const ClearFilters: React.FC<{ reset: () => void; visible?: boolean }> = ({
  reset,
  visible = true,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="">
      <span className="filter-label t-grey-50" onClick={reset}>
        Clear filters
        <Cross2Icon />
      </span>
    </div>
  );
};

export default ClearFilters;
