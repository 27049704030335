// Formats the timer display text to be displayed in lowercase.
//
// @param {Integer} seconds
// @return {String}
const timerText = (seconds) => `${Math.floor(seconds / 60)}min ${seconds % 60}s`;

class BiddingHelper {
  // Returns the time left to reach the passed time.
  //
  // @param {Time} cutoffTime
  // @return {String}
  remainingTimeCalculator(cutoffTime) {
    if (moment().add(1, 's').isAfter(cutoffTime)) { return; }

    const remainingTime = cutoffTime.diff(moment(), 's');

    return timerText(remainingTime);
  }
}

window.BiddingHelper = BiddingHelper;
