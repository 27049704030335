/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// card-chooser.coffee
//= ==============================================================================

new Component('card-chooser', ['cards', 'overlay', 'open', 'close',
  'choose'], ((c) => {
  // Open modal and remember which element opened it. Preselect a modal card
  // if one was previously selected. Disable a modal card if it is already
  // selected by another card selector.
  $(c.open).on('click', function (e) {
    e.preventDefault();
    c.memory.opened_with = $(this);

    // Clear and enable all previous modal selections and disable button
    $(c.cards).parent().find("[type='radio']").attr('checked', false);
    $(c.cards).parent().find("[type='radio']").attr('disabled', false);
    $(c.choose).attr('disabled', true);

    // Preselect modal location if this element has a selected card
    const card_id = $(this).find("[type='hidden']").val();
    const $card = $(c.overlay).find(`[value='${card_id}']`);
    $card.parents('.-radio').click();
    const card_type = $card.parents('.modal-state').attr('id');

    // hide change buttons
    $(c.overlay).find('.change-button').css('display', 'none');

    // Open modal
    return $(c.overlay).addClass('is-active');
  });

  // Close modal
  $(c.close).on('click', (e) => {
    e.preventDefault();
    return $(c.overlay).removeClass('is-active');
  });

  // Click overlay to close modal
  $(c.overlay).on('click', (e) => $(c.overlay).removeClass('is-active'));

  // Prevent modal close by clicking within modal
  $(c.this).on('click', (e) => e.stopPropagation());

  // Re-enable button when a selection is made
  $(c.this).on('click', '.-radio', function (e) {
    if (!window.getSelection().toString()) {
      if ($(this).find(c.cards).length && !$(this).find("[type='radio']:disabled").length) {
        return $(c.choose).attr('disabled', false);
      }
    }
  });

  // Update the element that opened this modal with the selected card data
  // and close the modal.
  return $(c.choose).on('click', (e) => {
    e.preventDefault();
    const $selected_card = $("[name='card-chooser-choice']:checked");
    const $card_data = $selected_card.siblings(c.cards).first().clone(true, true);
    $card_data.find('.change-button').css('display', 'block');
    c.memory.opened_with.find("[type='hidden']").val('1').blur();
    c.memory.opened_with.html($card_data);
    c.memory.opened_with.find("[name='card']").attr('name', c.memory.opened_with.attr('data-type'));
    $(c.overlay).removeClass('is-active');

    // Notify the document that a change occurred
    return $(document).trigger('change');
  });
}));
