import React from 'react';

import ExportModal from './ExportModal';
import RangePicker from './RangePicker';
import Search from './Search';
import StatusButton from './StatusButton';

const Root = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="spacer mt-0 mb-6 d-block d-sm-flex flex-justify-start">
      {children}
    </div>
  );
};

const StatusesContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="d-flex flex-justify-center flex-grow-1">
    <div className="toggle -pill">{children}</div>
  </div>
);

const Header = () => null;
Header.ExportButton = ExportModal; // TODO: Rename: Export
Header.RangePicker = RangePicker;
Header.Root = Root;
Header.Search = Search;
Header.StatusButton = StatusButton;
Header.StatusContainer = StatusesContainer;

export default Header;
