import React, { useEffect, useMemo, useState } from 'react';

import type { Facet } from '@/types';
import type { RideColumn, RideFilterState } from '../types';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSelector } from 'react-redux';

import Pagination from '@/components/Pagination/Pagination';
import EmptyRidesTable from '@/components/RidesTable/EmptyRidesTable';
import ErrorPanel from '@/components/RidesTable/ErrorPanel';
import Spinner from '@/components/Spinner/Spinner';
import Table from '@/features/RidesTable/components/Table';
import { isCommunity } from '@/path_defs';
import { selectToken } from '@/store/authSlice';

import useRides from '../hooks/useRides';
import TableProvider from '../providers/TableProvider';

dayjs.extend(customParseFormat);

const merge = (a: Facet[], b: Facet[]) => {
  const c = [...a];
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) =>
    c.some((cItem) => bItem.id === cItem.id) ? null : c.push(bItem),
  );
  return c;
};

const TableHandler: React.FC<{
  children: React.ReactNode;
  columns: RideColumn[];
  filters: RideFilterState;
  setPage: (page: number) => void;
}> = ({ columns = [], children, filters, setPage }) => {
  const [hospitalList, setHospitalList] = useState<Facet[]>([]);

  const {
    rides,
    isFetching,
    count,
    facets,
    getRides,
    isError,
    messages,
    removeRideById,
    updateRideById,
  } = useRides(filters);

  const token = useSelector(selectToken);

  /**
   * Manually trigger API request when filters change.
   */
  useEffect(() => {
    if (token) {
      getRides(); // eslint-disable-line @typescript-eslint/no-floating-promises
    }
  }, [filters, token]);

  const tableKey = rides.map(({ id }) => id).join('-');

  useEffect(() => {
    if (facets?.hospitals) {
      // Hospital list should aggregate all hospitals instead of listing the most recent API request values.
      setHospitalList(merge(hospitalList, facets.hospitals));
    }
  }, [rides]);

  const ctx = useMemo(
    () => ({ updateRideById, removeRideById, hospitals: hospitalList }),
    [tableKey, hospitalList],
  );

  return (
    <TableProvider ctx={ctx}>
      <div data-testid="react-dashboard-table">
        {children}

        {isFetching && <Spinner />}

        {rides.length > 0 && !isFetching && (
          <Table
            key={tableKey}
            rides={rides}
            type={isCommunity() ? 'community' : 'scheduled'}
            columns={columns}
          />
        )}

        {rides.length === 0 && !isFetching && !isError && <EmptyRidesTable />}
        {!isFetching && isError && <ErrorPanel messages={messages} />}

        {!isFetching && (
          <Pagination
            total={count}
            limit={filters.items}
            page={filters.page}
            setPage={setPage}
          />
        )}
      </div>
    </TableProvider>
  );
};

export default TableHandler;
