/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// tab.coffee
//= ==============================================================================

// Defines a single tab that will activate the is-active state of a tab-content
// container. Also defines its parent so that the is-active states of all other
// tab-contents go away once a tab is active. Disables all form elements in tab
// content when tab is disabled as well.
new Component('tab', ['content', 'parent'], ((c) => {
  // Define an activation procedure for tab
  $(c.this).bind('activate', () => {
    $(c.this).addClass('is-active');
    $(c.content).addClass('is-active');
    $(c.content).attr('disabled', false);
    $(c.content).find('input:not(.-skip-tab-disable)').attr('disabled', false);
    $(c.content).find('textarea').attr('disabled', false);
    $(c.content).find('select').attr('disabled', false);
    return $(c.content).find('[data-component="nested-tab"]:checked').click();
  });

  // Define a deactivation procedure for tab
  $(c.this).bind('deactivate', () => {
    $(c.this).removeClass('is-active');
    $(c.content).removeClass('is-active');
    $(c.content).attr('disabled', true);
    $(c.content).find('input:not(.-skip-tab-disable)').attr('disabled', true);
    $(c.content).find('textarea').attr('disabled', true);
    return $(c.content).find('select').attr('disabled', true);
  });

  // Disable all other tabs but activate this one
  $(c.this).on('click', (e) => {
    $(c.parent).find(c.selector).trigger('deactivate');
    return $(c.this).trigger('activate');
  });

  if ($(c.this).is(':checked')) { return $(c.this).click(); }
}));
