import React, { useState } from 'react';

import type { AppDispatch } from '@/store';

import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useDispatch } from 'react-redux';

import { setItemsPerPage } from '@/features/RidesTable/store/ridesFilterSlice';
import * as Popover from '@/lib/@radix-ui/react-popover';

import './PerPageDropdown.scss';

interface PerPageDropdownProps {
  limit: number;
}
const perPageOptions = [10, 20, 50, 100];

const PerPageDropdown: React.FC<PerPageDropdownProps> = ({ limit }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedPerPage, setSelectedPerPage] = useState(limit);

  const handleSelectChange = (selectedItem: number) => {
    setSelectedPerPage(selectedItem);
    dispatch(setItemsPerPage(selectedItem));
  };

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <span className="filter-label">
          {`${selectedPerPage} per page`}
          <ChevronDownIcon />
        </span>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="popover-content per-page-options"
          align="start"
        >
          {perPageOptions.map((option) => (
            <button
              className="per-page-option"
              key={option}
              onClick={() => handleSelectChange(option)}
            >
              {option}
            </button>
          ))}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default PerPageDropdown;
