import React, { useMemo } from 'react';

import type { RideColumn, RideFilterState } from '@/features/RidesTable/types';
import type { AppDispatch } from '@/store';
import type { AvailableStatus, Ride, VehicleType } from '@/types';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';

import BookTimeCell from '@/components/RidesTable/BookTimeCell';
import RideStatusBadgeCell from '@/components/RidesTable/dispatcher/RideStatusBadgeCell';
import DropoffLocationCell from '@/components/RidesTable/DropoffLocationCell';
import OldRideStatusBadgeCell from '@/components/RidesTable/old/CurrentStatusCell';
import PatientCell from '@/components/RidesTable/PatientCell';
import PickupLocationCell from '@/components/RidesTable/PickupLocationCell';
import PickupTimeCell from '@/components/RidesTable/PickupTimeCell';
import QuickActionCell from '@/components/RidesTable/QuickActionCell';
import VehicleTypeCell from '@/components/RidesTable/VehicleTypeCell';
import { useAuth } from '@/contexts/AuthProvider';
import Filters from '@/features/RidesTable/components/Filters';
import Header from '@/features/RidesTable/components/Header';
import ActionHeader from '@/features/RidesTable/components/Table/ActionHeader';
import TableHandler from '@/features/RidesTable/components/TableHandler';
import useUserPreferredColumns from '@/features/RidesTable/hooks/useUserPreferredColumns';
import {
  resetFilters,
  selectRideFilters,
  setAvailableStatuses,
  setEndDate,
  setPage,
  setStartDate,
  setVehicleTypes,
} from '@/features/RidesTable/store/ridesFilterSlice';
import { AVAILABLE_STATUSES, DEFAULT_COMMUNITY_STATUSES } from '@/types';

const BASE_COLUMNS: RideColumn[] = [
  {
    Cell: PatientCell,
    removeable: false,
    label: 'Rider Name',
    sortKey: 'rider_name',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: PickupLocationCell,
    label: 'Pickup',
    removeable: false,
    sortKey: 'pickup_location',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: VehicleTypeCell,
    label: 'Vehicle Type',
    removeable: false,
    sortKey: 'vehicle_type',
    hideKey: 'vehicle',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: DropoffLocationCell,
    label: 'Drop-off',
    removeable: false,
    sortKey: 'dropoff_location',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: PickupTimeCell,
    label: 'Pickup Time',
    removeable: false,
    sortKey: 'start_time',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: BookTimeCell,
    label: 'Book Time',
    removeable: false,
    sortKey: 'created_at',
    hideKey: 'book_time',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
];

const COMMUNITY_STATUS_FILTERS = {
  [AVAILABLE_STATUSES.available]: 'Available',
  [AVAILABLE_STATUSES.preferred]: 'Preferred',
  [AVAILABLE_STATUSES.responded]: 'Responded',
  [AVAILABLE_STATUSES.willCall]: 'Will Call',
};

const Community = () => {
  const { currentUser } = useAuth();
  const {
    reactDashboardQuickActionsSingleRide,
    radiusSearchFilter,
    refactoredStatusCell,
  } = useFlags();

  const dispatch = useDispatch<AppDispatch>();
  const filters = useSelector(selectRideFilters);
  const setPaginationPage = (page: number) => dispatch(setPage({ page }));

  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    statuses: AvailableStatus[],
  ) => {
    e.preventDefault();
    dispatch(setAvailableStatuses(statuses));
  };

  const declinedIsActive =
    filters.availableStatus.length === 1 &&
    filters.availableStatus[0] === AVAILABLE_STATUSES.declined;

  const columns = [
    ...BASE_COLUMNS,
    {
      Cell: refactoredStatusCell ? RideStatusBadgeCell : OldRideStatusBadgeCell,
      label: 'Status',
      removeable: false,
      sortKey: 'current_status',
      href: ({ id }: Ride) => `/dispatcher/ride/${id}`,
    },
  ];

  if (reactDashboardQuickActionsSingleRide) {
    columns.push({
      Cell: QuickActionCell,
      Header: ActionHeader,
    } as unknown as RideColumn);
  }

  const userPreferredColumns = useUserPreferredColumns(
    columns,
    currentUser.role,
    'community',
  );

  const tableFilters = useMemo(
    () =>
      ({
        ...filters,
        assignedStatus: [],
      }) as RideFilterState,
    [filters],
  );

  return (
    <>
      <Header.Root>
        <div className="d-block d-sm-flex" style={{ gap: '0.25rem' }}>
          <Header.RangePicker
            endTime={filters.endTime}
            startTime={filters.startTime}
            setStartTime={(date: string) => dispatch(setStartDate(date))}
            setEndTime={(date: string) => dispatch(setEndDate(date))}
          />
          <Header.Search />
        </div>

        <Header.StatusContainer>
          <Header.StatusButton
            active={!declinedIsActive}
            onClick={(e) => onClick(e, DEFAULT_COMMUNITY_STATUSES)}
            status={{
              availableStatus: DEFAULT_COMMUNITY_STATUSES,
            }}
          >
            Available
          </Header.StatusButton>
          <Header.StatusButton
            active={declinedIsActive}
            onClick={(e) => onClick(e, [AVAILABLE_STATUSES.declined])}
            status={{
              availableStatus: [AVAILABLE_STATUSES.declined],
            }}
          >
            Declined
          </Header.StatusButton>
        </Header.StatusContainer>
      </Header.Root>

      <TableHandler
        columns={userPreferredColumns}
        key="community"
        setPage={setPaginationPage}
        filters={tableFilters}
      >
        <Filters.Root>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <Filters.VehicleType
              types={filters.vehicleTypes}
              setTypes={(types: VehicleType[]) =>
                dispatch(setVehicleTypes(types))
              }
            />
            <Filters.RequestingFacility />
            {!declinedIsActive && (
              <Filters.Status
                key="community"
                defaultStatuses={DEFAULT_COMMUNITY_STATUSES}
                currentlySelected={filters.availableStatus}
                items={COMMUNITY_STATUS_FILTERS}
                onClose={(selected) =>
                  dispatch(
                    setAvailableStatuses(
                      selected as Partial<AvailableStatus>[],
                    ),
                  )
                }
              />
            )}
            {radiusSearchFilter && <Filters.Distance />}
            <Filters.WillCall />
            <Filters.ClearFilters
              visible={!filters.isInit}
              reset={() => dispatch(resetFilters())}
            />
          </div>
          <Filters.EditColumns />
        </Filters.Root>
      </TableHandler>
    </>
  );
};

export default Community;
