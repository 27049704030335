/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () => {
  const {
    controller,
  } = document.body.dataset;
  const {
    action,
  } = document.body.dataset;

  showSelectedVehicleSection();
  if ((controller === 'dispatcher') && (action === 'show_ride')) {
    return dispatcherRideStatusUpdate();
  }
});

// Remove hide class from Selected Vehicle section according to visibility of
// Driver Detail and Vehicle Detail sections.
var showSelectedVehicleSection = function () {
  if (($('#ride-share-vehicle-details').is(':visible') === false)
     && ($('#ride-share-driver-details').is(':visible') === false)) {
    return $('#selected-vehicle').removeClass('hide');
  }
};

// Check for the status change in dispatcher ride detail page
var dispatcherRideStatusUpdate = function () {
  // Open Modal/Submit form in case of status change
  $('#current_status').on('change', function () {
    const currentStatus = this.value;
    if (currentStatus === 'claimed') {
      const $inProgressModal = $('[data-modal-target="inprogress-status-overlay"]');
      $inProgressModal.addClass('is-active');
      $inProgressModal.trigger('modal:opened');
    } else if (currentStatus === 'canceled') {
      const $cancelModal = $('[data-modal-target="cancel-status-overlay"]');
      $cancelModal.addClass('is-active');
      $cancelModal.trigger('modal:opened');
    } else {
      this.form.submit();
    }

    // Add/Remove requried fields
    if (currentStatus === 'canceled') {
      return $('#additional_cancelation_info').attr('required', true);
    }
    $('#additional_cancelation_info').removeAttr('required');
    $('#additional_cancelation_info').parents().removeClass('-error');
    return $("[data-validator-target='update-status-button']").removeAttr('disabled');
  });

  // Toggle hide class on click of dropdown arrow
  $('#dispatcher-status-dropdown-arrow').on('click', () => $('#dispatcher-status-dropdown').toggleClass('hide'));

  // Update current status value
  $('#dispatcher-status-dropdown').on('click', () => {
    const clickedStatusValue = $(event.target).attr('data-option-value');
    return $('#current_status').val(clickedStatusValue).change();
  });

  // Hide dropdown if clicked outside of dropdown arrow
  $(document).on('mouseup', () => {
    const container = $('#dispatcher-status-dropdown-arrow');
    if (!container.is(event.target)) {
      return $('#dispatcher-status-dropdown').addClass('hide');
    }
  });

  // Add focus when inprogress modal is active
  $('[data-modal-target="inprogress-status-overlay"]').on('transitionend \
webkitTransitionEnd oTransitionEnd MSTransitionEnd', function (e) {
    if ((e.target === this) && $(this).hasClass('is-active')) {
      return $('#timeliness').focus();
    }
  });

  // Add focus when cancel modal is active
  return $('[data-modal-target="cancel-status-overlay"]').on('transitionend \
webkitTransitionEnd oTransitionEnd MSTransitionEnd', function (e) {
    if ((e.target === this) && $(this).hasClass('is-active')) {
      return $('#additional_cancelation_info').focus();
    }
  });
};
