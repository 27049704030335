// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p.flash-text {
  margin-bottom: 0;
}

p.flash-textbody {
  white-space: pre-wrap;
  font-weight: 500;
  margin-top: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./app/javascript/components/flash.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["p.flash-text {\n  margin-bottom: 0;\n}\n\np.flash-textbody {\n  white-space: pre-wrap;\n  font-weight: 500;\n  margin-top: 0.25rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
