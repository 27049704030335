import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useSubmitRideResponseMutation } from '@/api';
import { pushFlashMessage } from '@/store/flashMessagesSlice';

import './main.scss';

import DateField from '@root/components/DateField';
import TimeField from '@root/components/TimeField';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { pushToastNotification } from '@/store/toastNotificationsSlice';

import Modal from './Modal';

const SubmitETAModal: React.FC<{
  hide: () => void;
  rideId: number;
  rideStartTime: Date;
  show: boolean;
  streetAddress: string;
  timezone: string;
}> = ({ rideId, rideStartTime, hide, show, streetAddress, timezone }) => {
  const dispatch = useDispatch();
  const [dateField, setDateField] = useState(rideStartTime);
  const [timeField, setTimeField] = useState(rideStartTime);
  const { updateRideById } = useTableContext();

  const [submitRideResponse] = useSubmitRideResponseMutation();
  const minTime = new Date();
  minTime.setHours(0, 0, 0, 0);

  const formatRideTime = () => {
    const day = new Date(dateField);
    const time = new Date(timeField);

    const year = day.getFullYear();
    const month = day.getMonth();
    const date = day.getDate();

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    const milliseconds = time.getMilliseconds();

    const combinedDate = new Date(
      year,
      month,
      date,
      hours,
      minutes,
      seconds,
      milliseconds,
    );

    return combinedDate;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const combinedDate = formatRideTime();

    const body = {
      rideId,
      eta: combinedDate,
    };

    submitRideResponse(body)
      .unwrap()
      .then(() => {
        hide();
        dispatch(
          pushToastNotification({
            text: 'Your ETA was successfully submitted.',
            rideId,
            action: 'view ride',
          }),
        );
        updateRideById(rideId, { hasBid: true });
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: 'Sorry, an ETA for this ride cannot be submitted at the moment. Please try again in a few minutes.',
            type: 'error',
          }),
        );
      });
  };

  return (
    <Modal
      open={show}
      hide={hide}
      title="Submit ETA"
      subtitle={`When can you arrive at ${streetAddress}?`}
    >
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="d-flex flex-column">
          <div className="d-flex">
            <DateField
              initialDate={rideStartTime}
              minDate={rideStartTime}
              onChange={setDateField}
            />
            <TimeField
              timeInterval="5"
              initialTime={rideStartTime}
              minTime={minTime}
              onChange={setTimeField}
              position="top-end"
              tripTimezone={timezone}
            />
          </div>
          <div className="alert-dialog-actions mt-6">
            <button
              type="button"
              className="button -medium -inverted-blue"
              onClick={hide}
            >
              Nevermind
            </button>

            <button
              data-test="cancel-ride-submit-btn"
              type="submit"
              className="button -medium"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SubmitETAModal;
