/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// toggler.coffee
//= ==============================================================================

// Toggles an "is-active" class on a target.
new Component('toggler', ['toggleable'], ((c) => {
  // Toggle "is-active"
  $(c.this).on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();

    // close other toggles
    $('.header-toggle').each((i, header) => header.classList.remove('is-active'));

    return $(c.toggleable).toggleClass('is-active');
  });

  // Toggle off when click occurs out of toggler
  return $(window).on('click', (e) => $(c.toggleable).removeClass('is-active'));
}));
