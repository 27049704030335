/* eslint-disable import/no-duplicates */
import React from 'react';

import type { CellComponent, RideColumn } from '@/features/RidesTable/types';
import type { StreamableRide } from '../../types';

import Cell from '@/features/RidesTable/components/Table/Cell';

import RideshareLogo from '../../../assets/images/rideshare_logo.svg';
import VehicleLyftLuxSUVNoFillShort from '../../../assets/images/vehicle-lyft_lux_suv-no-fill-short.svg';
import VehicleLyftLuxSUVNoFill from '../../../assets/images/vehicle-lyft_lux_suv-no-fill.svg';
import VehicleLyftLuxSUV from '../../../assets/images/vehicle-lyft_lux_suv.svg';
import VehicleLyftLuxNoFillShort from '../../../assets/images/vehicle-lyft_lux-no-fill-short.svg';
import VehicleLyftLuxNoFill from '../../../assets/images/vehicle-lyft_lux-no-fill.svg';
import VehicleLyftLux from '../../../assets/images/vehicle-lyft_lux.svg';
import VehicleLyftPlusNoFillShort from '../../../assets/images/vehicle-lyft_plus-no-fill-short.svg';
import VehicleLyftPlusNoFill from '../../../assets/images/vehicle-lyft_plus-no-fill.svg';
import VehicleLyftPlus from '../../../assets/images/vehicle-lyft_plus.svg';
import VehicleLyftPremierNoFillShort from '../../../assets/images/vehicle-lyft_premier-no-fill-short.svg';
import VehicleLyftPremierNoFill from '../../../assets/images/vehicle-lyft_premier-no-fill.svg';
import VehicleLyftPremier from '../../../assets/images/vehicle-lyft_premier.svg';
import VehicleMedicalSedanLyftNoFillShort from '../../../assets/images/vehicle-medical-sedan-lyft-no-fill-short.svg';
import VehicleMedicalSedanLyftNoFill from '../../../assets/images/vehicle-medical-sedan-lyft-no-fill.svg';
import VehicleMedicalSedanLyft from '../../../assets/images/vehicle-medical-sedan-lyft.svg';
import VehicleMedicalSedanNoFillShort from '../../../assets/images/vehicle-medical-sedan-no-fill-short.svg';
import VehicleRideshareStandard from '../../../assets/images/vehicle-rideshare_standard.svg';
import VehicleRideshareXL from '../../../assets/images/vehicle-rideshare_xl.svg';
import VehicleStretcherVanNoFillShort from '../../../assets/images/vehicle-stretcher-van-no-fill-short.svg';
import VehicleTaxiNoFillShort from '../../../assets/images/vehicle-taxi-no-fill-short.svg';
import VehicleUberBlack from '../../../assets/images/vehicle-uber_black.svg';
import VehicleUberSelectNoFillShort from '../../../assets/images/vehicle-uber_select-no-fill-short.svg';
import VehicleUberSelect from '../../../assets/images/vehicle-uber_select.svg';
import VehicleUberSelectNoFill from '../../../assets/images/vehicle-uber_select.svg';
import VehicleUberSUVNoFillShort from '../../../assets/images/vehicle-uber_suv-no-fill-short.svg';
import VehicleUberSUV from '../../../assets/images/vehicle-uber_suv.svg';
import VehicleUberSUVNoFill from '../../../assets/images/vehicle-uber_suv.svg';
import VehicleUberXNoFillShort from '../../../assets/images/vehicle-uber_x-no-fill-short.svg';
import VehicleUberXNoFill from '../../../assets/images/vehicle-uber_x-no-fill-short.svg';
import VehicleUberX from '../../../assets/images/vehicle-uber_x.svg';
import VehicleUberXLNoFillShort from '../../../assets/images/vehicle-uber_xl-no-fill-short.svg';
import VehicleUberXLNoFill from '../../../assets/images/vehicle-uber_xl-no-fill-short.svg';
import VehicleUberXL from '../../../assets/images/vehicle-uber_xl.svg';
import VehicleWheelchairVanNoFillShort from '../../../assets/images/vehicle-wheelchair-van-no-fill-short.svg';
import { VEHICLE_TYPES } from '../../types';

type VehicleSVG = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
>;

const svgVersion = (
  svg: VehicleSVG,
  noFillSvg: VehicleSVG,
  NoFillShortSvg: VehicleSVG,
  fill: boolean,
  short: boolean,
) => {
  if (fill && short) {
    return NoFillShortSvg;
  }

  if (fill) {
    return noFillSvg;
  }

  return svg;
};

function getRideShareVehicleImage(
  type: string,
  rideType: string,
  fill = false,
  short = false,
) {
  switch (rideType) {
    case 'lyft':
      switch (type) {
        case 'lyft_plus':
          // TODO
          return svgVersion(
            VehicleLyftPlus,
            VehicleLyftPlusNoFill,
            VehicleLyftPlusNoFillShort,
            fill,
            short,
          );
        case 'lyft_premier':
          return svgVersion(
            VehicleLyftPremier,
            VehicleLyftPremierNoFill,
            VehicleLyftPremierNoFillShort,
            fill,
            short,
          );
        case 'lyft_lux':
          return svgVersion(
            VehicleLyftLux,
            VehicleLyftLuxNoFill,
            VehicleLyftLuxNoFillShort,
            fill,
            short,
          );
        case 'lyft_luxsuv':
          return svgVersion(
            VehicleLyftLuxSUV,
            VehicleLyftLuxSUVNoFill,
            VehicleLyftLuxSUVNoFillShort,
            fill,
            short,
          );
        default:
          return svgVersion(
            VehicleMedicalSedanLyft,
            VehicleMedicalSedanLyftNoFill,
            VehicleMedicalSedanLyftNoFillShort,
            fill,
            short,
          );
      }
    case 'uber':
      switch (type) {
        case 'uber_xl':
          return svgVersion(
            VehicleUberXL,
            VehicleUberXLNoFill,
            VehicleUberXLNoFillShort,
            fill,
            short,
          );
        case 'uber_select':
          return svgVersion(
            VehicleUberSelect,
            VehicleUberSelectNoFill,
            VehicleUberSelectNoFillShort,
            fill,
            short,
          );
        case 'uber_black':
          return VehicleUberBlack;
        case 'uber_suv':
          return svgVersion(
            VehicleUberSUV,
            VehicleUberSUVNoFill,
            VehicleUberSUVNoFillShort,
            fill,
            short,
          );
        default:
          return svgVersion(
            VehicleUberX,
            VehicleUberXNoFill,
            VehicleUberXNoFillShort,
            fill,
            short,
          );
      }
    default:
      if (type === 'lyft_plus' || type === 'uber_xl') {
        return VehicleRideshareXL;
      }

      if (type === 'lyft' || type === 'uber_x') {
        return VehicleRideshareStandard;
      }
  }

  return VehicleMedicalSedanNoFillShort;
}

function careCoordinatorVehicleImage(ride: StreamableRide): VehicleSVG {
  if (ride.rideshare) {
    // TODO:
    // if (ride.rideDispatchDetail && ride.rideDispatchDetail.vehicleImageUrl) {
    //   return ride.rideDispatchDetail.vehicleImageUrl;
    // }

    if (ride.rideshareVendor) {
      return getRideShareVehicleImage(
        ride.rideshareVehicleType,
        ride.rideshareVendor,
        true,
        true,
      );
    }

    return RideshareLogo;
  }

  if (ride.vehicleNeedType === VEHICLE_TYPES.taxi) {
    return VehicleTaxiNoFillShort;
  }

  if (ride.vehicleNeedType === VEHICLE_TYPES.wheelchair) {
    return VehicleWheelchairVanNoFillShort;
  }

  if (
    ride.vehicleNeedType === VEHICLE_TYPES.stretcherVan ||
    ride.vehicleNeedType === 'stretcher'
  ) {
    return VehicleStretcherVanNoFillShort;
  }

  return VehicleMedicalSedanNoFillShort;
}

const VehicleTypeCell: CellComponent = ({ ride, href }) => {
  const alt = ride.rideVehicleDescription;
  const Image = careCoordinatorVehicleImage(ride);

  return (
    <Cell
      label="Vehicle Type"
      tdClass="vehicle-info"
      href={typeof href === 'function' ? href(ride) : href}
    >
      {ride.showVehicleDetails && (
        <>
          <div className="vehicle-make-model">{ride.vehicleDescription}</div>
          <div className="vehicle-license">{ride.vehicleLicensePlate}</div>
        </>
      )}

      <div className="d-flex flex-items-center">
        <div className="vehicle-image d-none d-sm-block pt-3 pb-3 mr-2">
          {Image && alt && (
            <Image
              role="img"
              title={alt}
              width="100"
              className="vehicle-image"
            />
          )}
        </div>

        <div className="vehicle-info">{alt}</div>
      </div>
    </Cell>
  );
};

export const column: RideColumn = {
  Cell: VehicleTypeCell,
  label: 'Vehicle Type',
  removeable: false,
  sortKey: 'vehicle_type',
  hideKey: 'vehicle',
};

export default VehicleTypeCell;
