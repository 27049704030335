/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// facility-search.coffee
//= ==============================================================================

// Adds listeners to search box in facility dropdown
new Component('facility-search', [], ((c) => {
  $(c.this).on('click', (e) => e.stopPropagation());

  // Filters record based on user's search
  $(c.this).on('keyup', (e) => {
    const searchValue = $.trim(e.target.value.toLowerCase()).replace(/\\/g, '');
    const facilityOptions = $('.facility-dropdown-wrapper').find('[data-facility-name]');

    return facilityOptions.each(function () {
      const facilityName = $(this).data('facility-name').toLowerCase();

      if (facilityName.search(searchValue) > -1) {
        return $(this).show();
      }
      return $(this).hide();
    });
  });

  // Clear the search once the user closes the facility dropdown
  return $(window).on('click', (e) => {
    if ($('.facility-dropdown-wrapper').hasClass('is-active')) {
      $('.facility-search-box').val('');

      const facilityOptions = $('.facility-dropdown-wrapper').find('[data-facility-name]');
      return facilityOptions.each(function () {
        return $(this).fadeIn(1000);
      });
    }
  });
}));
