/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// google-maps.coffee
//= ==============================================================================

// Handles the google maps for locations
new Component('google-maps', [], ((c) => {
  let marker = null;
  let sourceMarker = null;
  let destinationMarker = null;
  let multistopMarker = null;
  let driverMarker = null;
  let map = null;
  let infoWindow = null;
  let sourceLocationLatLng = null;
  let countForRecenteringMap = 0;

  // Need to provide image with scaledSize due to issue in IE where images
  // without scaledSize aren't rendered in IE.
  // Height and width is the required size of the image in px.
  //
  // @param {string} image_url
  // @param {number} width
  // @param {number} height
  // @return {object}
  const markerImage = function (image_url, width, height) {
    const image = {
      scaledSize: new google.maps.Size(width, height),
      url: image_url,
    };
    return image;
  };

  const clearMap = function () {
    const $mapCanvas = $('.map_canvas');
    return $mapCanvas.hide();
  };

  $(document).on('locationModalClosed', (e) => clearMap());

  const getGoogleMap = function (marker, callback) {
    const place = marker.getPosition();
    return callback(place);
  };

  const addressComponentsMap = function (components) {
    const map_address = {};
    for (const component of Array.from(components)) {
      map_address[component.types[0]] = component.long_name;
    }
    return map_address;
  };

  const setBounds = function (map, markersArray) {
    const bounds = new google.maps.LatLngBounds();

    for (marker of Array.from(markersArray)) {
      if (marker.getPosition() != null) {
        bounds.extend(marker.getPosition());
      }
    }

    return map.fitBounds(bounds);
  };

  const positionChanged = () => getGoogleMap(marker, (event) => {
    const lat = event.lat();
    const lng = event.lng();
    let city = null;
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (results && results[0]) {
        const service = new google.maps.places.PlacesService(map);
        return service.getDetails({ placeId: results[0].place_id }, (place, status) => {
          const full_address = place.formatted_address;
          const location = addressComponentsMap(place.address_components);
          city = (() => {
            if ('locality' in location) {
              return location.locality;
            } if ('sublocality_level_1' in location) {
              return location.sublocality_level_1;
            } if ('neighborhood' in location) {
              return location.neighborhood;
            }
          })();
          const eventData = {
            lat,
            lng,
          };
          const street_address = (location.street_address || location.street_number) && location.route
            // Google API returns both a street address (e.g. 5176) and a route
            // (e.g. Apricot St).
            ? [location.street_address || location.street_number, location.route].join(' ')
            : full_address.indexOf(',')
            // The input element has a string formatted as something similar to
            // 5176 Apricot St, Thadmin Town, US. The Google autocomplete should
            // guarantee this sort of formatting.
              ? full_address.substring(0, full_address.indexOf(','))
              :
            // Something went awry.
              '';

          $('body').trigger('google-autocomplete-place-changed', eventData);

          $('.modal-overlay.is-active').find('[data-component="google-autocomplete"]').val(full_address);
          $('.modal-overlay.is-active .street-address').val(street_address);
          $('.modal-overlay.is-active .city').val(city);
          $('.modal-overlay.is-active .state').val(location.administrative_area_level_1);
          $('.modal-overlay.is-active .zip').val(location.postal_code);
          $('.modal-overlay.is-active .latitude').val(lat);
          $('.modal-overlay.is-active .longitude').val(lng);
          return $('.modal-overlay.is-active .autocomplete-location-name').val(place.name);
        });
      }
    });
  });

  const initializeMap = function (data) {
    const $mapCanvas = $('.modal-overlay.is-active .map_canvas');
    $mapCanvas.show();
    const $map = $mapCanvas.find('.map');
    const locationLatLng = new google.maps.LatLng(data.lat, data.lng);

    const myOptions = {
      center: locationLatLng,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      zoom: 15,
    };
    map = new google.maps.Map(document.getElementById(`${$map.attr('id')}`), myOptions);

    marker = new google.maps.Marker({
      draggable: true,
      map,
      optimized: false,
      position: locationLatLng,
    });

    return google.maps.event.addListener(marker, 'dragend', positionChanged);
  };

  const initializeStaticMap = function (data) {
    let multistopLocationLatLng;
    const $mapCanvas = $('.map_canvas');
    $mapCanvas.show();
    const $map = $mapCanvas.find('.map');
    sourceLocationLatLng = new google.maps.LatLng(data.pickup_lat, data.pickup_lng);
    const destLocationLatLng = new google.maps.LatLng(data.dropoff_lat, data.dropoff_lng);
    if (data.multistop_lat && data.multistop_lng) {
      multistopLocationLatLng = new google.maps.LatLng(data.multistop_lat, data.multistop_lng);
    }

    const myOptions = {
      center: sourceLocationLatLng,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      zoom: 15,
    };
    map = new google.maps.Map(document.getElementById(`${$map.attr('id')}`), myOptions);

    sourceMarker = new google.maps.Marker({
      icon: markerImage(data.pickup_image, 38, 42),
      map,
      optimized: false,
      position: sourceLocationLatLng,
    });

    destinationMarker = new google.maps.Marker({
      icon: markerImage(data.dropoff_image, 38, 42),
      map,
      optimized: false,
      position: destLocationLatLng,
    });

    if (multistopLocationLatLng) {
      multistopMarker = new google.maps.Marker({
        icon: markerImage(data.multistop_image, 38, 42),
        map,
        optimized: false,
        position: multistopLocationLatLng,
      });
      setBounds(map, [sourceMarker, destinationMarker, multistopMarker]);
    } else {
      setBounds(map, [sourceMarker, destinationMarker]);
    }

    driverMarker = new google.maps.Marker({
      icon: markerImage(data.driver_image, 55, 55),
      map,
      optimized: false,
    });

    // DisableAutoPan to stop moving map position whenever infowindow position is updated.
    return infoWindow = new google.maps.InfoWindow({ disableAutoPan: true });
  };

  $(document).off('google-autocomplete-place-changed-trigger-map').on('google-autocomplete-place-changed-trigger-map', (e, data) => initializeMap(data));

  $(document).off('initialize-static-google-map').on('initialize-static-google-map', (e, data) => initializeStaticMap(data));

  const recenterMap = () => setBounds(map, [sourceMarker, driverMarker, destinationMarker]);

  const handleSourceMarker = function (current_status) {
    if (current_status === 'at_pickup') {
      return setPositionOfSourceMarker(null);
    }
    return setPositionOfSourceMarker(sourceLocationLatLng);
  };

  const removePickupEta = function () {
    $('.ride-pickup-eta').text('');
    return infoWindow.close(map, driverMarker);
  };

  const removeDriverPosition = () => driverMarker.setPosition(null);

  var setPositionOfSourceMarker = (position) => sourceMarker.setPosition(position);

  $(window).on('trip-map-driver-location-updated', (e, data) => {
    countForRecenteringMap += 1;
    // We want to recenter map in every 30 seconds and because we are fetching
    // driver location in every 5 seconds so after 6 times that will complete
    // 5*6 = 30 seconds.
    if (countForRecenteringMap === 6) {
      recenterMap();
      countForRecenteringMap = 0;
    }
    const {
      location,
    } = data;
    if ((location != null) && (location.latitude != null) && (location.longitude != null)) {
      const driverPosition = new google.maps.LatLng(location.latitude, location.longitude);
      driverMarker.setPosition(driverPosition);
      if (location.updateMapBounds) {
        setBounds(map, [sourceMarker, driverMarker, destinationMarker]);
      }
      if (location.pickUpEta != null) {
        const pickUpEta = location.pickUpEta > 0 ? location.pickUpEta : '< 1';
        const pickUpEtaLabel = `${pickUpEta} min`;
        infoWindow.setContent(pickUpEtaLabel);
        $('.ride-pickup-eta').text(`ETA ${pickUpEtaLabel}`);
        infoWindow.open(map, driverMarker);
      } else {
        removePickupEta();
      }
      return handleSourceMarker(data.current_status);
    }
    return removePickupEta();
  });

  $(window).on('handle-absent-driver-location', () => {
    removePickupEta();
    removeDriverPosition();
    return setPositionOfSourceMarker(sourceLocationLatLng);
  });
}));
