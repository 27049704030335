/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// datepicker.coffee
//= ==============================================================================

// Handles datepicker toggles
new Component('datepicker', ['wrapper', 'checkable'], ((c) => {
  const weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  // Need some data for referencing instance
  let dateInstance = null;
  let options = null;

  const initialize = function () {
    if ($(c.this).data('datepicker-type') !== undefined) {
      // Set a minimum date of today for the outbound and return calendars
      options = $(c.this).data('min-and-max') ? {
        format: 'mm/dd/yyyy',
        max: $(c.this).data('max'),
        min: $(c.this).data('min'),
        onClose() {
          return $(document).trigger('datepicker-closed', $(c.this));
        },
        weekdaysShort,
      }
        : $(c.this).data('min-today') ? {
          format: 'mm/dd/yyyy',
          min: true,
          onClose() {
            return $(document).trigger('datepicker-closed', $(c.this));
          },
          weekdaysShort,
        }
          : $(c.this).data('max-today') ? {
            format: 'mm/dd/yyyy',
            max: true,
            weekdaysShort,
          }
            : $(c.this).data('min-ride-start-date') ? {
              format: 'mm/dd/yyyy',
              min: $(c.this).data('min-ride-start-date'),
              onClose() {
                return $(document).trigger('datepicker-closed', $(c.this));
              },
              weekdaysShort,
            }
              : {
                format: 'mm/dd/yyyy',
                weekdaysShort,
              };

      return dateInstance = $(c.this).pickadate(options).pickadate('picker');
    }
    // Auto resize the input field based on input length.
    $(c.this).autosizeInput();

    // Decently hacky way of triggering the autosizeInput() plugin. Triggering
    // propertychange on load doesn't auto resize, and neither does changing the
    // input value with jQuery. Have to trigger both .val() and propertychange
    // twice, one with a different text length than the second, to get the
    // behavior to trigger. If anybody can figure out a cleaner way for this to
    // work, that would be muy bueno.
    const _val = $(c.this).val();
    $(c.this).val(`${_val} `);
    $(c.this).trigger('propertychange');
    setTimeout(
      () => {
        $(c.this).val(_val);
        return $(c.this).trigger('propertychange');
      },
      1,
    );

    // Activate pickadate plugin.
    options = {
      onSet(context) {
        if (context.select && (context.select > 0)) {
          $(c.wrapper).addClass('-pickadate-set');
          const date = new Date(context.select);
          const dateString = $(c.this).data('min-ride-start-date')
            ? $(c.this).data('min-ride-start-date')
            : `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

          return window.location.href = `${window.location.pathname}?date=${dateString}`;
        }
      },
      weekdaysShort,
    };
    return dateInstance = $(c.this).pickadate(options).pickadate('picker');
  };

  initialize();

  const set_date = (date) => dateInstance.set('select', date);

  $(c.this).on('set-date', (event, date) => set_date(date));

  $(c.this).on('reset', (e) => {
    const default_value = $(c.this).attr('default_value');
    if (default_value && (default_value !== $(c.this).val())) {
      $(c.this).val(default_value);
      return set_date(default_value);
    }
  });

  return $(c.this).on('change', (e) => $(c.checkable).prop('checked', false));
}));
