const ReactRailsUJS = require('react_ujs');

const all = [];

// Enforces component attribute conventions in markup. When writing a new
// component in `javascripts/components`, create an instance of this class.
//
// Conventions:
//
//   * All instances of a component in markup should have a data attribute in the
//     following format: `data-component="{name}"`.
//
//   * All instances of a component should have data attributes corresponding to
//     its targets (elements that are affected by the component) in the following
//     format: `data-{name}-{target name}="{unique string}"`.
//
//   * All targets of a component should have a data attribute in the following
//     format: `data-{name}-target="{unique string}"`.
//
// How passed functions get run:
//
//   * {functionality} is a function that is run for each instance of the
//     component selector. Gets passed a config variable.
//   * {global} is a function that is run once before each {functionality} call.
//     Gets passed a memory variable that is available to all individual calls.
//
// @param {String}   - name
// @param {Array}    - targets
// @param {Function} - functionality
// @param {Function} - global (optional)
// @return {void}
window.Component = class Component {
  constructor(name, targets, functionality, global = null) {
    targets ??= [];
    const memory = {
      selector: `[data-component='${name}']`,
    };

    all.push({
      functionality,
      global,
      memory,
      name,
      targets,
    });
  }
};

const run = ({
  name, targets, functionality, global, memory,
}) => {
  if ($.isFunction(global)) { global(memory); }

  const { selector } = memory;

  // Run functionality function for each instance of selector
  return $(selector).each(function () {
    const config = {
      memory,
      selector,
      this: this,
    };

    // Register target selectors
    targets.forEach((t) => {
      const targetAttr = `data-${name}-${t}`;
      const targetName = $(config.this).attr(targetAttr);
      return config[t] = `[data-${name}-target='${targetName}']`;
    });

    // Run passed function with config object
    return functionality(config);
  });
};

$(document).on('turbolinks:load', (e) => {
  /**
   * Prevent React components from mounting twice on initial page loads.
   *
   * https://github.com/turbolinks/turbolinks/issues/595
   */
  if (e.originalEvent.data.timing.visitStart) { // `visitStart` is null on initial page loads
    ReactRailsUJS.mountComponents();
  }

  all.forEach((item) => run(item));
});
