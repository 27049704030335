import React from 'react';

import Cell from '@/features/RidesTable/components/Table/Cell';
import {
  getDate,
  getShortDayNameByDate,
  getShortMonth,
  getShortTz,
  getTimeHHMM,
} from '@/utils/dateTime';

const TimeCell: React.FC<{
  href: string;
  label?: string;
  time: string;
  willCall?: boolean;
}> = ({ time, href, willCall = false, label = 'Book time' }) => (
  <Cell label={label} tdClass="book-location" href={href}>
    <span className="no-wrap">
      {`${getShortDayNameByDate(time)}, ${getShortMonth(time)} ${getDate(
        time,
      )}`}
      <br />
      {willCall ? 'Will Call' : `${getTimeHHMM(time)} ${getShortTz(time)}`}
    </span>
  </Cell>
);

export default TimeCell;
