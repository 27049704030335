import React from 'react';

import * as Toast from '@/lib/@radix-ui/react-toast';

const handleRefresh = () => {
  window.location.href = '/trips/scheduled';
};

const RefreshAction = () => (
  <Toast.Action className="ToastAction" asChild altText="Goto schedule to undo">
    <button
      type="button"
      className="Button small green"
      onClick={handleRefresh}
    >
      Refresh page
    </button>
  </Toast.Action>
);

export default RefreshAction;
