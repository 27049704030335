/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// patients-search-area.coffee
//= ==============================================================================

new Component('patients-search-area-new', ['input', 'h_input', 'form', 'results_box',
  'results', 'empty', 'loading'], ((c) => {
  const resultIcon = '\
  <svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http:\/\/www.w3.org\/2000\/svg\" class=\"icon-person mr-3\">\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.9201 14.5454C13.7696 12.838 13.0858 11.6115 12.1107 10.8024C11.1131 9.97459 9.75007 9.53454 8.1924 9.53454C6.63467 9.53454 5.26959 9.97463 4.27002 10.8028C3.29309 11.6121 2.60795 12.8386 2.45738 14.5454H13.9201ZM1.72694 14.5527C2.0324 10.72 4.81058 8.80726 8.1924 8.80726C11.5742 8.80726 14.3451 10.72 14.6506 14.5527C14.6869 14.9382 14.3524 15.2727 13.9669 15.2727H2.41785C2.02513 15.2727 1.69785 14.9382 1.72694 14.5527ZM6.42078 5.73162C6.89064 6.20148 7.52791 6.46545 8.1924 6.46545C8.85689 6.46545 9.49416 6.20148 9.96402 5.73162C10.4339 5.26175 10.6979 4.62448 10.6979 3.95999C10.6979 3.2955 10.4339 2.65823 9.96402 2.18837C9.49416 1.7185 8.85689 1.45454 8.1924 1.45454C7.52791 1.45454 6.89064 1.7185 6.42078 2.18837C5.95091 2.65823 5.68694 3.2955 5.68694 3.95999C5.68694 4.62448 5.95091 5.26175 6.42078 5.73162ZM5.90652 6.24588C6.51277 6.85213 7.33503 7.19272 8.1924 7.19272C9.04977 7.19272 9.87203 6.85213 10.4783 6.24588C11.0845 5.63962 11.4251 4.81736 11.4251 3.95999C11.4251 3.10262 11.0845 2.28036 10.4783 1.67411C9.87203 1.06785 9.04977 0.727264 8.1924 0.727264C7.33503 0.727264 6.51277 1.06785 5.90652 1.67411C5.30026 2.28036 4.95967 3.10262 4.95967 3.95999C4.95967 4.81736 5.30026 5.63962 5.90652 6.24588Z\"\/>\r\n<\/svg>\
';
  const url = '/api/v1/care-coordinators/search-patients';
  const headers = { Authorization: `Token token=${$(c.this).data('auth-token')}` };

  let defer = null;

  const doSearch = function (e) {
    const query = e.target.value;
    let canDoRequest = true;

    // for the test suite, only trigger search on enter
    // this is some bullshit but tests dont pass without it
    if (/PhantomJS/.test(window.navigator.userAgent)) {
      canDoRequest = (e.keyCode === 13);
    }

    if ((query.length >= 2) && canDoRequest) {
      // Remove existing results
      $(c.results_box).find('.search-result').remove();
      $(c.results_box).addClass('is-active');
      $(c.loading).addClass('is-active');

      return $.ajax({
        data: { q: query },
        dataType: 'json',
        headers,
        success(data, status) {
          const patients = data.data;

          // hide loading indicator
          $(c.loading).removeClass('is-active');

          // remove existing results
          $(c.results_box).find('.search-result').remove();

          if (patients.length) {
            // results array
            let result;
            let $searchResults = $();
            $(c.empty).removeClass('is-active');

            for (const patient of Array.from(patients)) {
              // TODO: Remove when tier-2-search is live
              if (patient.group_name) {
                result = `\
<div class='search-result d-flex -clickable -hoverable is-active' data-search-area-target='search-result' data-id='${patient.id}'> \
${resultIcon} \
<div class='flex-1'> \
<h3 class='search-result-name'> \
<span>${patient.name}</span> \
<div class='search-result-group-name t-normal'> \
${patient.group_name} \
</div> \
</h3>`;
              } else {
                result = `\
<div class='search-result d-flex -clickable -hoverable is-active' data-search-area-target='search-result' data-id='${patient.id}'> \
${resultIcon} \
<div class='flex-1'> \
<h3 class='search-result-name'> \
<span>${patient.name}</span> \
</h3>`;
              }

              if (patient.identifier_value) {
                result += `\
<div class='search-result-detail col-5'> \
<div class='caps'>${patient.identifier_type}</div> \
${patient.identifier_value} \
</div>`;
              }

              if (patient.search_result_content) {
                result += `\
<div class='search-result-detail'> \
<div class='caps'>${patient.search_result_label}</div> \
${patient.search_result_content} \
</div>`;
              }

              result += '</div></div>';
              $searchResults = $searchResults.add(result);
            }

            // add results
            $(c.results_box).append($searchResults);

            return (() => {
              const result1 = [];
              for (result of Array.from($searchResults)) {
                result1.push($(result).on('click', function (e) {
                  e.preventDefault();
                  $(this).off();
                  $(c.h_input).val($(this).attr('data-id'));
                  return $(c.form).submit();
                }));
              }
              return result1;
            })();
          }
          return $(c.empty).addClass('is-active');
        },
        type: 'GET',
        url,
      });
    }
    return $(c.results_box).removeClass('is-active');
  };

  $(c.input).on('keydown', (e) => clearTimeout(defer));

  // show results in suggestion box, show on page on enter
  $(c.input).on('keyup click', (e) => {
    if (e.keyCode === 13) {
      $(c.results_box).removeClass('is-active');
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('search', e.target.value);
      return window.location.search = urlParams.toString();
    }
    return defer = setTimeout(
      () => doSearch(e),
      700,
    );
  });

  // Click outside results box to close
  $(document).on('click', (e) => {
    if (!$(e.target).hasClass('input')) { return $(c.results_box).removeClass('is-active'); }
  });

  // Prevent results box close by clicking inside results box
  return $(c.results_box).on('click', (e) => e.stopPropagation());
}));
