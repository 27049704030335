/* eslint-disable import/prefer-default-export */
import { roundtripBaseApiV3 } from '@/lib/rtk-query';

interface CreateReportParams {
  endDate: string;
  startDate: string;
}

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    createReport: build.mutation<void, CreateReportParams>({
      query: (report) => ({
        url: '/dispatcher_ride_reports',
        method: 'POST',
        body: { report },
      }),
    }),
  }),
});

export const { useCreateReportMutation } = extendedApi;
