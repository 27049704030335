import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '.';

type AuthState = {
  token: string | null;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>,
    ) => {
      state.token = token;
    },
  },
});

export const { setCredentials } = authSlice.actions;
export const selectToken = ({ auth }: RootState) => auth.token;

export default authSlice.reducer;
