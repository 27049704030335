import type { User } from '@/types';

import { withLDProvider as withUnconfiguredLDProvider } from 'launchdarkly-react-client-sdk';

const withLDProvider = (
  user: User,
  isDriver: boolean,
  transportationCompanyId: number,
) =>
  withUnconfiguredLDProvider({
    clientSideID: process.env.LAUNCH_DARKLY_CLIENT_KEY as string,
    context: {
      key: `${user.id}`,
      custom: {
        hospitalId: user.hospitalId,
        isDriver,
        transportationCompanyId,
      },
    },
  });

export default withLDProvider;
