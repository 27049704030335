/* eslint-disable no-return-assign */
import React from 'react';

import type { RideColumn } from '@/features/RidesTable/types';
import type {
  CcAssignedStatuses,
  CcRideStatusBadgeText,
} from '@/features/RideStatusBadge/types';
import type { AppDispatch } from '@/store';
import type { AuthStatus, Entries, Ride, VehicleType } from '@/types';

import { useDispatch, useSelector } from 'react-redux';

import RideStatusBadgeCell from '@/components/RidesTable/careCoordinator/RideStatusBadgeCell';
import DropoffLocationCell from '@/components/RidesTable/DropoffLocationCell';
import PatientCell from '@/components/RidesTable/PatientCell';
import PickupLocationCell from '@/components/RidesTable/PickupLocationCell';
import PickupTimeCell from '@/components/RidesTable/PickupTimeCell';
import VehicleTypeCell from '@/components/RidesTable/VehicleTypeCell';
import { useAuth } from '@/contexts/AuthProvider';
import Filters from '@/features/RidesTable/components/Filters';
import Header from '@/features/RidesTable/components/Header';
import TableHandler from '@/features/RidesTable/components/TableHandler';
import useUserPreferredColumns from '@/features/RidesTable/hooks/useUserPreferredColumns';
import {
  resetFilters,
  selectRideFilters,
  setAuthStatuses,
  setEndDate,
  setPage,
  setStartDate,
  setStatusBadge,
  setVehicleTypes,
} from '@/features/RidesTable/store/cc/ridesFilterSlice';
import {
  CC_ASSIGNED_STATUSES,
  CC_STATUS_BADGE_TEXT,
  DEFAULT_CC_STATUSES,
} from '@/features/RideStatusBadge/types';

const BASE_COLUMNS: RideColumn[] = [
  {
    Cell: PatientCell,
    removeable: false,
    label: 'Rider Name',
    sortKey: 'rider_name',
    href: ({ id }) => `/rides/${id}`,
  },
  {
    Cell: PickupLocationCell,
    label: 'Pickup',
    removeable: false,
    sortKey: 'pickup_location',
    href: ({ id }) => `/rides/${id}`,
  },
  {
    Cell: VehicleTypeCell,
    label: 'Vehicle Type',
    removeable: false,
    sortKey: 'vehicle_type',
    hideKey: 'vehicle',
    href: ({ id }) => `/rides/${id}`,
  },
  {
    Cell: DropoffLocationCell,
    label: 'Drop-off',
    removeable: false,
    sortKey: 'dropoff_location',
    href: ({ id }) => `/rides/${id}`,
  },
];

const CANCELED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: PickupTimeCell, // TODO: Create a CanceledAt component
    label: 'Canceled At',
    removeable: false,
    sortKey: 'canceled_at',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: RideStatusBadgeCell, // TODO: Create a CancelReason component
    label: 'Cancel Reason',
    removeable: false,
    sortKey: 'cancel_reason',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
];

const COMPLETED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: PickupTimeCell, // TODO: Create a CompletedAt component
    label: 'Completed At',
    removeable: false,
    sortKey: 'completed_at',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: RideStatusBadgeCell,
    label: 'Status',
    removeable: false,
    sortKey: 'current_status',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
];

const SCHEDULED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: PickupTimeCell,
    label: 'Pickup Time',
    removeable: false,
    sortKey: 'start_time',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: RideStatusBadgeCell,
    label: 'Status',
    removeable: false,
    sortKey: 'current_status',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
];

type CcApiStatusFilters = {
  [key in CcAssignedStatuses]: CcRideStatusBadgeText;
};

const STATUS_FILTERS: CcApiStatusFilters = (() => {
  const statusFilters = {} as CcApiStatusFilters;

  (
    Object.entries(CC_ASSIGNED_STATUSES) as Entries<typeof CC_ASSIGNED_STATUSES>
  ).map(([key, value]) => (statusFilters[value] = CC_STATUS_BADGE_TEXT[key]));

  return statusFilters;
})();

const Scheduled = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useAuth();
  const filters = useSelector(selectRideFilters);
  const setPaginationPage = (page: number) => dispatch(setPage({ page }));

  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    statuses: CcAssignedStatuses[],
  ) => {
    e.preventDefault();
    dispatch(setStatusBadge(statuses));
  };

  const completedIsActive =
    filters.statusBadge.length === 1 &&
    filters.statusBadge[0] === CC_ASSIGNED_STATUSES.completed;

  const canceledIsActive =
    filters.statusBadge.length === 1 &&
    filters.statusBadge[0] === CC_ASSIGNED_STATUSES.canceled;

  let columns = [];

  if (canceledIsActive) {
    columns = CANCELED_COLUMNS;
  } else if (completedIsActive) {
    columns = COMPLETED_COLUMNS;
  } else {
    columns = SCHEDULED_COLUMNS;
  }

  // TODO: Do we need quick actions?
  // if (!completedIsActive && !canceledIsActive) {
  //   columns.push(quickActionColumn);
  // }

  const userPreferredColumns = useUserPreferredColumns(
    columns,
    currentUser.role,
    'scheduled',
  );

  return (
    <>
      <Header.Root>
        <div className="d-block d-sm-flex" style={{ gap: '0.25rem' }}>
          <Header.RangePicker
            endTime={filters.endTime}
            startTime={filters.startTime}
            setStartTime={(date: string) => dispatch(setStartDate(date))}
            setEndTime={(date: string) => dispatch(setEndDate(date))}
          />
          <Header.Search />
        </div>

        <Header.StatusContainer>
          <Header.StatusButton
            active={!(completedIsActive || canceledIsActive)}
            onClick={(e) => onClick(e, DEFAULT_CC_STATUSES)}
            status={{
              statusBadge: DEFAULT_CC_STATUSES,
            }}
          >
            Scheduled
          </Header.StatusButton>
          <Header.StatusButton
            active={completedIsActive}
            onClick={(e) => onClick(e, [CC_ASSIGNED_STATUSES.completed])}
            status={{
              statusBadge: [CC_ASSIGNED_STATUSES.completed],
            }}
          >
            Completed
          </Header.StatusButton>

          <Header.StatusButton
            active={canceledIsActive}
            onClick={(e) => onClick(e, [CC_ASSIGNED_STATUSES.canceled])}
            status={{
              statusBadge: [CC_ASSIGNED_STATUSES.canceled],
            }}
          >
            Canceled
          </Header.StatusButton>
        </Header.StatusContainer>

        <a
          href="/book_trip/new"
          className="button book-trip py-2 px-6 hidden-sm"
          data-turbolinks={false}
          data-test="book-trip-btn"
        >
          Book Trip
        </a>
      </Header.Root>

      <TableHandler
        columns={userPreferredColumns}
        filters={filters}
        setPage={setPaginationPage}
      >
        <Filters.Root>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <Filters.VehicleType
              types={filters.vehicleTypes}
              setTypes={(types: VehicleType[]) =>
                dispatch(setVehicleTypes(types))
              }
            />
            {!(completedIsActive || canceledIsActive) && (
              <Filters.Status
                defaultStatuses={DEFAULT_CC_STATUSES}
                currentlySelected={filters.statusBadge}
                items={STATUS_FILTERS}
                onClose={(selected) =>
                  dispatch(setStatusBadge(selected as CcAssignedStatuses[]))
                }
              />
            )}
            <Filters.AuthStatus
              statuses={filters.authStatuses}
              setStatuses={(types: AuthStatus[]) =>
                dispatch(setAuthStatuses(types))
              }
            />
            <Filters.WillCall />
            <Filters.ClearFilters
              visible={!filters.isInit}
              reset={() => dispatch(resetFilters())}
            />
          </div>
        </Filters.Root>
      </TableHandler>
    </>
  );
};

export default Scheduled;
