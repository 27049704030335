import {
  useEffect, useMemo, useState, useRef,
} from 'react';
import { createConsumer } from '@rails/actioncable';

interface LogMessage {
  message: string;
  type: 'info' | 'warn' | 'error';
  verbose: boolean;
}

const log = (x: LogMessage) => {
  // eslint-disable-next-line no-console
  if (x.verbose) console[x.type](`useActionCable: ${x.message}`);
};

export function useActionCable(url?: string, { verbose } = { verbose: false }) {
  const actionCable = useMemo(() => createConsumer(url), []);

  useEffect(() => {
    log({
      message: 'Created Action Cable',
      type: 'info',
      verbose,
    });

    return () => {
      log({
        message: 'Disconnected Action Cable',
        type: 'info',
        verbose,
      });

      actionCable.disconnect();
    };
  }, []);

  return {
    actionCable,
  };
}

interface Callbacks {
  connected?: () => void;
  disconnected?: () => void;
  initialized?: () => void;
  received?: (x: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

type TChannel = ActionCable.Channel & {
  connected: () => void;
  disconnected: () => void;
  initialized: () => void;
  received: (x: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

type Action = 'ping'
  type Payload = Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any

  interface Send {
    action: Action;
    payload: Payload;
    useQueue: boolean;
  }

export function useChannel(actionCable: ActionCable.Cable, { verbose } = { verbose: false }) {
  const [queue, setQueue] = useState<{ action: Action, payload: Payload }[]>([]);
  const [connected, setConnected] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const channelRef = useRef<TChannel | null>();

  const subscribe = (data: ActionCable.ChannelNameWithParams, callbacks: Callbacks) => {
    log({
      message: `Connecting to ${data.channel}`,
      type: 'info',
      verbose,
    });

    const channel: TChannel = actionCable.subscriptions.create(data, {
      connected: () => {
        log({
          message: `Connected to ${data.channel}`,
          type: 'info',
          verbose,
        });

        setConnected(true);
        if (callbacks.connected) {
          callbacks.connected();
        }
      },
      disconnected: () => {
        log({
          message: 'Disconnected',
          type: 'info',
          verbose,
        });

        setConnected(false);
        if (callbacks.disconnected) {
          callbacks.disconnected();
        }
      },
      initialized: () => {
        log({
          message: `Init ${data.channel}`,
          type: 'info',
          verbose,
        });

        setSubscribed(true);
        if (callbacks.initialized) {
          callbacks.initialized();
        }
      },
      received: (x) => {
        log({
          message: `Received ${JSON.stringify(x)}`,
          type: 'info',
          verbose,
        });

        if (callbacks.received) {
          callbacks.received(x);
        }
      },
    });

    channelRef.current = channel;
  };

  const unsubscribe = () => {
    setSubscribed(false);

    if (channelRef.current) {
      log({
        message: `Unsubscribing from ${channelRef.current?.identifier}`,
        type: 'info',
        verbose,
      });

      actionCable.subscriptions.remove(channelRef.current);
      channelRef.current = null;
    }
  };

  const perform = (action: Action, payload: Payload) => {
    if (subscribed && !connected) {
      throw new Error('useActionCable: not connected');
    }

    if (!subscribed) {
      throw new Error('useActionCable: not subscribed');
    }

    try {
      log({
        message: `Sending ${action} with payload ${JSON.stringify(payload)}`,
        type: 'info',
        verbose,
      });

      channelRef.current?.perform(action, payload);
    } catch {
      throw new Error('useActionCable: Unknown error');
    }
  };

  const processQueue = () => {
    const action = queue[0];

    try {
      perform(action.action, action.payload);

      setQueue((prevState) => {
        const q = [...prevState];
        q.shift();

        return q;
      });
    } catch {
      log({
        message: `Unable to perform action '${action.action}'. It will stay at the front of the queue.`,
        type: 'warn',
        verbose,
      });
    }
  };

  const enqueue = (action: Action, payload: Payload) => {
    log({
      message: `Adding action to queue - ${action}: ${JSON.stringify(payload)}`,
      type: 'info',
      verbose,
    });

    setQueue((prevState) => [...prevState, {
      action,
      payload,
    }]);
  };

  const send = ({ action, payload, useQueue }: Send) => {
    if (useQueue) {
      enqueue(action, payload);
      return;
    }

    perform(action, payload);
  };

  useEffect(() => {
    if (subscribed && connected && queue.length > 0) {
      processQueue();
    } else if ((!subscribed || !connected) && queue.length > 0) {
      log({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        message: `Queue paused. Subscribed: ${subscribed}. Connected: ${connected}. Queue length: ${queue.length}`,
        type: 'info',
        verbose,
      });
    }
  }, [queue[0], connected, subscribed]);

  useEffect(() => () => unsubscribe(), []);

  return {
    send,
    subscribe,
    unsubscribe,
  };
}
