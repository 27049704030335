/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// tiger-connect-credentials.coffee
//= ==============================================================================

new Component('tiger_connect_credentials', ['overlay', 'open', 'close', 'submit', 'change'], ((c) => {
  // Open modal
  let updateTigerConnectCredentials;
  $(document).on('click', c.open, (e) => {
    e.preventDefault();
    e.stopPropagation();

    const hospitalId = $(c.overlay).find('[id="hospital-id"]').data().id;
    const userId = $(c.overlay).find('[id="user-id"]').data().id;
    const tigerConnectId = $(c.overlay).find(`[id='tiger-connect-id-${hospitalId}-${userId}']`).data().id;
    const userEmail = $(c.overlay).find(`[id='user-email-${hospitalId}-${userId}']`).data().email;
    const fieldErrorElement = $(c.overlay).find("[id='error-field']");
    const submitButton = $(c.overlay).find('[id="tiger-connect-credentials-button"]');
    const $tigerConnectId = $(c.overlay).find('[name="tiger-connect-credentials-input"]');

    removeFieldMessage(fieldErrorElement);
    submitButton.removeAttr('disabled');
    $tigerConnectId.val(tigerConnectId);
    $(c.overlay).addClass('is-active');

    const errorResponse = function () {
      removeFieldMessage(fieldErrorElement);
      return submitButton.removeAttr('disabled');
    };

    const successResponse = function (response) {
      removeFieldMessage(fieldErrorElement);
      submitButton.removeAttr('disabled');
      if (response.data.valid) {
        $(`#tiger-connect-id-${hospitalId}-${userId}`).data('id', userEmail);
        return $tigerConnectId.val(userEmail);
      }
    };

    const data = { hospital_id: hospitalId, recipient: userEmail };

    if (!tigerConnectId) {
      setFieldMessage(fieldErrorElement, '-error', 'Validating user has access on TigerConnect. Please wait');
      submitButton.attr('disabled', true);
      return validateTigerConnectId(successResponse, errorResponse, data);
    }
  });

  // Close modal
  $(document).on('click', c.close, (e) => {
    e.preventDefault();
    return $(c.overlay).removeClass('is-active');
  });

  // Prevent modal close by clicking within modal
  $(document).on('click', c.overlay, (e) => e.stopPropagation());

  // Add TigerConnect Credentials
  $(document).on('click', c.submit, function (e) {
    e.stopPropagation();
    const submitButton = $(this);
    const parent = $(this).parents('.modal-wrapper');
    const hospitalId = parent.find('[id="hospital-id"]').data().id;
    const userId = parent.find('[id="user-id"]').data().id;
    const userHospitalId = parent.find(`[id='user-hospital-id-${hospitalId}-${userId}']`).data().id;
    const fieldErrorElement = parent.find("[id='error-field']");
    const tigerConnectId = parent.find('[name="tiger-connect-credentials-input"]').val();
    submitButton.attr('disabled', true);
    setFieldMessage(fieldErrorElement, '-error', 'Still updating the TigerConnect Id. Please wait');

    const successResponse = function () {
      removeFieldMessage(fieldErrorElement);
      submitButton.removeAttr('disabled');
      $(`#tiger-connect-id-view-${hospitalId}-${userId}`).html(tigerConnectId);
      $(`#tiger-connect-id-${hospitalId}-${userId}`).data('id', tigerConnectId);
      $(`#tiger-connect-credentials-link-${hospitalId}-${userId}`).html(tigerConnectId);
      return $(c.overlay).removeClass('is-active');
    };

    const errorResponse = () => removeFieldMessage(fieldErrorElement);

    const data = { tiger_connect_id: tigerConnectId, user_hospital_id: userHospitalId };
    return updateTigerConnectCredentials(successResponse, errorResponse, data);
  });

  // Validate TigerConnect Id
  $(document).on('change', c.change, function (e) {
    e.stopPropagation();
    const parent = $(this).parents('.modal-wrapper');
    const hospitalId = parent.find('[id="hospital-id"]').data().id;
    const fieldErrorElement = parent.find("[id='error-field']");
    const submitButton = parent.find('[id="tiger-connect-credentials-button"]');
    submitButton.attr('disabled', true);
    setFieldMessage(fieldErrorElement, '-error', 'Still validating the TigerConnect Id. Please wait');

    const successResponse = function (response) {
      if (response.data.valid) {
        removeFieldMessage(fieldErrorElement);
        return submitButton.removeAttr('disabled');
      }
      return setFieldMessage(fieldErrorElement, null, 'TigerConnect Id is invalid');
    };

    const errorResponse = function () {
      $('.message.validating').addClass('-visiblity-hidden');
      return setFieldMessage(fieldErrorElement, null, 'TigerConnect Id is invalid');
    };

    const data = { hospital_id: hospitalId, recipient: $(this).val() };

    if ($(this).val().length > 0) {
      return validateTigerConnectId(successResponse, errorResponse, data);
    }
    return removeFieldMessage(fieldErrorElement);
  });

  // Returns headers for the Request.
  const headers = function () {
    const authTokenElement = document.querySelector('#auth-token');
    const authToken = authTokenElement.dataset.token;
    return { Authorization: `Token token=${authToken}` };
  };

  // Set the error field message.
  var setFieldMessage = function ($field, className, message) {
    if (className) {
      $field.addClass(className);
    }
    return $field.html(message);
  };

  // Empty the error field.
  var removeFieldMessage = function ($field) {
    $field.removeClass('-error');
    return $field.html(null);
  };

  // Validate the TigerConnect Id.
  var validateTigerConnectId = function (successFunction, errorFunction, data) {
    const url = '/api/v1/tiger-connect/validate-user';
    return $.ajax({
      data,
      dataType: 'json',
      error: errorFunction,
      headers: headers(),
      success: successFunction,
      type: 'GET',
      url,
    });
  };

  // Update the TigerConnect Credentials
  return updateTigerConnectCredentials = function (successFunction, errorFunction, data) {
    const url = '/api/v1/super-user/care-coordinator/update-tiger-connect-credentials';
    return $.ajax({
      data,
      dataType: 'json',
      error: errorFunction,
      headers: headers(),
      success: successFunction,
      type: 'PUT',
      url,
    });
  };
}));
