/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// ride-assign.coffee
//= ==============================================================================

new Component('ride-assign', ['overlay', 'token', 'form', 'ride_id',
  'transportation_company_id'], ((c) => {
  const authToken = $(c.this).data('authToken');
  const headers = { Authorization: `Token token=${authToken}` };

  const assignRide = function () {
    $(c.this).attr('disabled', true);
    const rideId = $(c.this).data('rideId');
    const transportation_company_id = parseInt($('#transportation_company_id').val());
    return $.ajax({
      data: {
        datetime: $('[data-id="assign"] [data-component="datetime"]').val(),
        ride_id: rideId,
        transportation_company_id,
      },
      dataType: 'json',
      error(xhr, status, e) {
        const {
          errors,
        } = xhr.responseJSON;
        if (errors.bid_declined) {
          return showBidFailureModal(errors.bid_declined);
        } if (errors.patient_limit_exceeded) {
          return showFailureModal(errors.patient_limit_exceeded);
        }
      },
      headers,
      success(response, status) {
        $('#assign-modal').removeClass('is-active');
        if (response.data.success) {
          const {
            tc_name,
          } = response.data;
          const {
            tc_phone,
          } = response.data;
          $('a[data-modal-target="ride-assign-close"]').trigger('click');
          $('.transportation-company-name').html(tc_name);
          $('.transportation-company-phone').html(tc_phone);
          $('#trip_transport_provider').val(tc_name);
          $('#trip_transport_provider_phone').val(tc_phone);
          return showSuccessModal('ride-assigned', response.data.success);
        }
        showBidFailureModal(response.data.error);
        return console.log(response.data.error);
      },
      type: 'POST',
      url: `/api/v1/admin/rides/${rideId}/assign`,
    });
  };

  var showFailureModal = function (error) {
    $(c.this).attr('disabled', false);
    $('#ride-assign-error-modal').addClass('is-active');
    return $('#failure_message').html(error);
  };

  var showSuccessModal = function (id, message) {
    $(c.this).attr('disabled', false);
    $(`#${id}-modal .message`).html(message);
    return $(`#${id}-modal`).addClass('is-active');
  };

  return $(c.this).on('click', (e, params) => {
    if (!params) {
      e.preventDefault();
      return assignRide();
    }
  });
}));
