/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () => {
  const {
    controller,
  } = document.body.dataset;
  const {
    action,
  } = document.body.dataset;
  if ((controller === 'rides') && ['scheduled', 'completed', 'canceled'].includes(action)) { return dashboardInit(); }
});

var dashboardInit = function () {
  $(document).ready(() => {
    // submit patient name filter form on enter
    const patientSearch = document.getElementById('patient_search');

    if (patientSearch != null) {
      patientSearch.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') { return document.forms.filter_form.submit(); }
      });
    }

    const elem = document.querySelector('.js-switch');
    if (elem) {

      $(elem).on('change', function () {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('authorizing', this.checked);

        window.location.search = urlParams.toString();
      });
    }

    return $('.editable > .ride_time_column').hover(function () {
      const ride_id = $(this).data('ride-id');
      return toggleSetRideTimeButton(ride_id);
    });
  });

  var toggleSetRideTimeButton = function (ride_id) {
    $(`#set_ride_time_${ride_id}`).toggleClass('d-none');
    return $(`#ride_time_display_${ride_id}`).toggleClass('d-none');
  };

  return $('#remove_filter').on('click', (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('filter_query_id');
    return window.location.search = urlParams.toString();
  });
};
