/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// remover.coffee
//= ==============================================================================

// Removes a specified type of parent element of the component.
new Component(
  'remover',
  [],
  (c) => true,
  (m) => // Looks for a remover target in its parents and removes it
    $(document).on('click', m.selector, function (e) {
      e.preventDefault();
      $(this).parents("[data-remover-target='true']").remove();

      // Trigger a change event on the page
      return $(document).change();
    }),
);
