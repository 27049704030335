/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// modal.coffee
//= ==============================================================================

// Adds listeners to open/close a modal.
new Component('modal', ['overlay', 'open', 'close'], ((c) => {
  let addModalData;
  const getViews = function () {
    let result;
    const id = $(c.overlay).attr('id');
    const views = localStorage.getItem(`${id}-views`);
    if (views != null) {
      result = parseInt(views);
    } else {
      localStorage.setItem(`${id}-views`, 0);
      result = 0;
    }
    return result;
  };

  const incrementViews = function () {
    let views = getViews();
    const id = $(c.overlay).attr('id');
    return localStorage.setItem(`${id}-views`, ++views);
  };

  // Open modal
  $(c.open).on('click', (e) => {
    e.preventDefault();
    addModalData(e.target);

    $(c.overlay).addClass('is-active');
    $(c.overlay).trigger('modal:opened');
    if ($(c.overlay).find('#return-date-calendar') || $(c.overlay).find('#return-date-calendar-responsive')) {
      $(c.overlay).find('#return-date-calendar').trigger('reset');
      $(c.overlay).find('#return-date-calendar-responsive').trigger('reset');
    }
    if ($(c.overlay).find('#return_trip_time') || $(c.overlay).find('#return_trip_time_responsive')) {
      $(c.overlay).find('#return_trip_time').trigger('reset');
      $(c.overlay).find('#return_trip_time_responsive').trigger('reset');
    }
    if ($(c.overlay).find('#return_trip_will_call') || $(c.overlay).find('#return_trip_will_call_responsive')) {
      let value = $('#return_trip_will_call').attr('default_value') === 'true';
      $(c.overlay).find('#return_trip_will_call').prop('checked', value);
      value = $('#return_trip_will_call_responsive').attr('default_value') === 'true';
      return $(c.overlay).find('#return_trip_will_call_responsive').prop('checked', value);
    }
  });

  // Close modal
  $(c.close).on('click', (e) => {
    e.preventDefault();
    return $(c.overlay).removeClass('is-active');
  });

  // Click overlay to close modal
  $(c.overlay).on('click', (e) => $(c.overlay).removeClass('is-active'));

  // Prevent modal close by clicking within modal
  $(c.this).on('click', (e) => {
    if ($(e.target).attr('data-method') === undefined) { return e.stopPropagation(); }
  });

  $(() => {
    if ($(c.this).data('modalAutoOpen') && $(c.this).data('modalViewLimit')) {
      const views = getViews();
      const limit = $(c.this).data('modalViewLimit');
      if (views < limit) {
        $(c.overlay).addClass('is-active');
        incrementViews();
      }
    }
    if ($(c.this).data('modalAutoOpen') && !$(c.this).data('modalViewLimit')) {
      $(c.overlay).addClass('is-active');
      return incrementViews();
    }
  });

  $(c.overlay).on('modal:opened', () => incrementViews());

  // This method allows you to append dynamic data to the modals form as hidden inputs
  // To use this, set the data-modal_data attribute of the open button to a stringified json object
  // Example usage:
  // %span= link_to "Confirm dropoff", '#', data: { modal_target: 'confirm-status-open', modal_data: "{\"ride_id\": #{ride.id}}" }
  return addModalData = function (target) {
    const $this = $(c.this);
    const data = $(target).data();

    if (data.modalData) {
      const {
        modalData,
      } = data;

      if ($this.find('form').length) {
        const $form = $this.find('form').first();
        $form.find('.modal-data').remove();
        const $hiddenInputContainer = document.createElement('div');
        $hiddenInputContainer.classList.add('modal-data');

        Object.keys(modalData).forEach((key) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', key);
          input.setAttribute('value', modalData[key]);
          return $hiddenInputContainer.appendChild(input);
        });

        return $form.append($hiddenInputContainer);
      }
    }
  };
}));
