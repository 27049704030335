/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// printer.coffee
//= ==============================================================================

// Adds listeners to print.
new Component('printer', [], ((c) => {
  $(document).ready(() => {
    try {
      if ((window.self !== window.top) || !window.print) {
        return $(c.this).hide();
      }
    } catch (e) {
      return $(c.this).hide();
    }
  });

  return $(c.this).on('click', (e) => {
    e.preventDefault();
    return window.print();
  });
}));
