const reactCommunityRidesTable = () =>
  document.getElementById('dispatcher-community-rides-table-react');

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).on('turbolinks:load', () => {
  if (reactCommunityRidesTable()) {
    return;
  }
  let identifier;
  let userId;
  const disable_socket_element = document.querySelector('#disable-web-sockets');

  if (
    disable_socket_element &&
    disable_socket_element.dataset &&
    disable_socket_element.dataset.disable === 'true'
  ) {
    return;
  }

  const path = window.location.pathname.split('/');
  if (['dispatcher', 'admin'].indexOf(path[1]) !== -1) {
    userId = document.body.dataset.currentUserId;
  }

  if (Number.isInteger(parseInt(userId))) {
    identifier = { channel: 'NotificationsChannel', user_id: userId };

    // This ensures that new subscription is not created with same identifier
    if (App.cable.subscriptions.findAll(JSON.stringify(identifier)).length) {
      return;
    }

    return (App.notifications = App.cable.subscriptions.create(identifier, {
      connected() {},
      // Called when the subscription is ready for use on the server

      disconnected() {},
      // Called when the subscription has been terminated by the server

      received(data) {
        if (reactCommunityRidesTable()) {
          return;
        }
        // Called when there's incoming data on the websocket for this channel
        if (data.notification_count != null) {
          const count = parseInt(data.notification_count);
          if (count === 0) {
            $(`.${path[1]}-notification-count`).hide();
          }
          if (count > 0) {
            $(`.${path[1]}-notification-count`).show();
          }
          return $(`.${path[1]}-notification-count`).html(
            count > 9 ? '9+' : count,
          );
        }
      },
    }));
  }
  if (App.notifications != null) {
    const activeSubscriptions = App.cable.subscriptions.findAll(
      App.notifications.identifier,
    );
    activeSubscriptions.map((sub) => sub.unsubscribe());
    return (App.notifications = null);
  }
});
