import React from 'react';

import AuthStatus from './AuthStatus';
import ClearFilters from './Clear';
import Distance from './Distance';
import EditColumns from './EditColumns';
import RequestingFacility from './RequestingFacilities';
import Status from './Status';
import VehicleType from './VehicleType';
import WillCall from './WillCall';

const Root: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      className="d-flex flex-column flex-sm-row flex-justify-between mb-4"
      style={{ overflowX: 'auto' }}
    >
      {children}
    </div>
  );
};

const Filters = {
  AuthStatus,
  Root,
  ClearFilters,
  Distance,
  RequestingFacility,
  Status,
  VehicleType,
  WillCall,
  EditColumns,
};

export default Filters;
