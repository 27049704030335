/* eslint-disable import/prefer-default-export */

import { roundtripBaseApiV3 } from '../../lib/rtk-query';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    completeRide: build.mutation<void, number>({
      query: (rideId: number) => ({
        url: `rides/${rideId}`,
        method: 'PUT',
        body: {
          currentStatus: 'completed',
          id: rideId,
        },
      }),
    }),
  }),
});

export const { useCompleteRideMutation } = extendedApi;
