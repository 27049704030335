/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// flash.coffee
//= ==============================================================================

// Configures globally available flash functions to dynamically display them.
$(document).on('turbolinks:load', () => {
  if ($("[data-flash='template']").length > 0) {
    return window.Flash = {
      container: $("[data-flash='container']"),

      // Error
      error(message) {
        return this.container.html(this.template({
          message,
          type: 'error',
        }));
      },

      // Success
      success(message) {
        return this.container.html(this.template({
          message,
          type: 'success',
        }));
      },

      template: Handlebars.compile($("[data-flash='template']").html()),
    };
  }
});
