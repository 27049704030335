import type { Ride, StreamableRide } from '@/types';

import moment from 'moment';

import { RideStatusBadgeText } from '@/features/RideStatusBadge/types';
import { RideCurrentStatus } from '@/types';

const remainingTime = (cutoff: moment.Moment) => cutoff.diff(moment.utc(), 's');

const withinWindow = (cutoff: moment.Moment) =>
  cutoff.isValid() && remainingTime(cutoff) > 0;

const COMMUNITY_TRANSPORTATION_COMPANY_ID = 0;

class RideStatusBadgeService {
  private ride: StreamableRide;

  private status: RideStatusBadgeText | null;

  constructor(ride: Ride) {
    this.ride = ride;
    this.status = this.run();
  }

  get isDeclined(): boolean {
    return this.status === RideStatusBadgeText.DECLINED;
  }

  get isResponded(): boolean {
    return this.status === RideStatusBadgeText.RESPONDED;
  }

  get isCompleted(): boolean {
    return this.status === RideStatusBadgeText.COMPLETED;
  }

  get isCanceled(): boolean {
    return this.status === RideStatusBadgeText.CANCELED;
  }

  get isProcessing(): boolean {
    return this.status === RideStatusBadgeText.PROCESSING;
  }

  get isSubmitETA(): boolean {
    return this.status === RideStatusBadgeText.SUBMIT_ETA;
  }

  get isNotStarted(): boolean {
    return this.status === RideStatusBadgeText.NOT_STARTED;
  }

  get isNewRide(): boolean {
    return this.status === RideStatusBadgeText.NEW_RIDE;
  }

  get isPreferred(): boolean {
    return this.status === RideStatusBadgeText.PREFERRED;
  }

  get isAvailable(): boolean {
    return this.status === RideStatusBadgeText.AVAILABLE;
  }

  get isInProgress(): boolean {
    return this.status === RideStatusBadgeText.IN_PROGRESS;
  }

  get isAssigned(): boolean {
    return this.status === RideStatusBadgeText.ASSIGNED;
  }

  // get isCheckingAvailability(): boolean {
  //   return this.status === RideStatusBadgeText.CHECKING_AVAILABILITY;
  // }

  // get isRequested(): boolean {
  //   return this.status === RideStatusBadgeText.REQUESTED;
  // }

  // get isClaimed(): boolean {
  //   return this.status === RideStatusBadgeText.CLAIMED;
  // }

  private run() {
    const biddingWindowCutoff = moment
      .utc(this.ride.timerStartTime)
      .add(this.ride.biddingWindow, 's');

    const ppCutoffTime = moment
      .utc(this.ride.timerStartTime)
      .add(this.ride.ppBiddingWindow, 's');

    if (
      this.ride.isDeclined &&
      this.ride.transportationCompanyId === COMMUNITY_TRANSPORTATION_COMPANY_ID
    ) {
      return RideStatusBadgeText.DECLINED;
    }

    if (this.ride.hasBid && withinWindow(biddingWindowCutoff)) {
      return RideStatusBadgeText.RESPONDED;
    }

    if (this.ride.currentStatus === RideCurrentStatus.COMPLETED) {
      return RideStatusBadgeText.COMPLETED;
    }

    if (this.ride.currentStatus === RideCurrentStatus.CANCELED) {
      return RideStatusBadgeText.CANCELED;
    }

    if (this.ride.processingRide) {
      return RideStatusBadgeText.PROCESSING;
    }

    if (this.ride.autoAssigned && !this.ride.hasBid) {
      return RideStatusBadgeText.SUBMIT_ETA;
    }

    if (this.ride.willCall) {
      return RideStatusBadgeText.NOT_STARTED;
    }

    if (
      this.ride.currentStatus === RideCurrentStatus.QUEUED &&
      !this.ride.claimed
    ) {
      // if (isCcScheduled()) {
      //   if (this.ride.rideshare) {
      //     return RideStatusBadgeText.REQUESTED;
      //   }

      //   return RideStatusBadgeText.CHECKING_AVAILABILITY;
      // }

      if (this.ride.streamed) {
        return RideStatusBadgeText.NEW_RIDE;
      }

      if (this.ride.preferredProviderArrival && withinWindow(ppCutoffTime)) {
        return RideStatusBadgeText.PREFERRED;
      }

      return RideStatusBadgeText.AVAILABLE;
    }

    if (this.ride.timeliness !== null) {
      return RideStatusBadgeText.IN_PROGRESS;
    }

    // if (this.ride.claimed) {
    //   if (isCcScheduled()) {
    //     return RideStatusBadgeText.CLAIMED;
    //   }
    // }

    return RideStatusBadgeText.ASSIGNED;
  }
}

export default RideStatusBadgeService;
