import React from 'react';

import './Spinner.scss';

type LoaderSize = 'extra-large' | 'small' | 'etc';

interface Props {
  size?: LoaderSize;
}

const Loader: React.FC<Props> = ({ size = 'extra-large' }) => {
  return <div className={`loader ${size}`} alt="Loader" />;
};

export default Loader;
