/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// activator.coffee
//= ==============================================================================

// Adds/removes an "is-active" class on a target based on element state.
new Component('activator', ['activatable', 'deactivator'], ((c) => {
  // Trigger change event on child select elements that are not disabled for
  // an active activator.
  const triggerChildSelects = function () {
    if ($(c.this).is('select') || $(c.this).is(':checked')) {
      return $(c.activatable).find('select:not(:disabled)').trigger('change');
    }
  };

  if ($(c.this).is('select')) {
    // Handle dropdowns slightly differently.
    $(c.this).on('change', (e, params) => {
      e.preventDefault();
      $(c.activatable).removeClass('is-active');
      $(c.activatable).attr('disabled', true);
      $(c.activatable).find('input, select, textarea').attr('disabled', true);

      // Determine the particular activatable target to enable based on selected
      // value.
      const selectedVal = $(c.this).val();
      const $activatable = $(c.activatable).filter(`[data-activator-id*='${selectedVal}']`);

      // Activate the corresponding activatable.
      $activatable.addClass('is-active');
      $activatable.attr('disabled', false);
      $activatable.find('input, select, textarea').attr('disabled', false);
      const options = {
        clear: '',
        format: 'h:i A',
        interval: 5,
      };

      $('.authorize-time-picker').each(function () {
        return $(this).pickatime(options).pickatime('picker').render();
      });

      // Trigger child selects to allow for child components to trigger if
      // needed.
      return setTimeout(triggerChildSelects, 0);
    });

    const selectedVal = $(c.this).val();
    const hasNonBlankValue = (selectedVal !== null) && (selectedVal !== '');
    if (hasNonBlankValue || (c.deactivator === 'true')) {
      return $(c.this).trigger('change', { loadChange: true });
    }
  } else {
    const isWillCall = $(c.this).attr('name') === 'will_call';

    // Handle non-dropdowns.
    $(c.this).on('change', (e, params) => {
      params ??= { loadChange: false };
      if (($(c.this).is(':checked') && !isWillCall)
         || (!$(c.this).is(':checked') && isWillCall)) {
        // Element is checked, activate the activatables.
        $(c.activatable).each(function () {
          if (!(($(this).attr('data-activator-no-load-event') === 'true') && params.loadChange)) {
            $(this).addClass('is-active');
          }
          $(this).attr('disabled', false);
          return $(this).find('input, select, textarea').attr('disabled', false);
        });
      } else if ((!$(c.this).is(':checked') && !isWillCall)
              || ($(c.this).is(':checked') && isWillCall)) {
        // Element is unchecked, deactivate the activatables.
        $(c.activatable).removeClass('is-active');
        $(c.activatable).attr('disabled', true);
        $(c.activatable).find('input, select, textarea').attr('disabled', true);
      }

      // Trigger child selects to allow for child components to trigger if
      // needed.
      return setTimeout(triggerChildSelects, 0);
    });

    // Trigger change event on load if checked OR if it's unchecked only if it's
    // a will call button.
    const triggerOnLoad = $(c.this).is(':checked') || (!$(c.this).is(':checked') && isWillCall);
    if (triggerOnLoad) { $(c.this).trigger('change', { loadChange: true }); }

    // Optionally trigger the change handler if a deactivator is specified.
    return $(c.deactivator).on('change', (e) => $(c.this).change());
  }
}));
