/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// pagination.coffee
//= ==============================================================================

new Component('records-per-page', [], (c) => $('select#records_per_page').on('change', function (e) {
  e.preventDefault();
  const url = new URL(window.location.href);
  let params = `?per_page=${$(this).val()}`;

  if (url.searchParams.get('search')) {
    params += `&search=${url.searchParams.get('search')}`;
  }

  return window.location.search = params;
}));
