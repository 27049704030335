/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
new Component('will-call-filter', [], (c) => $(document).ready(() => {
  const elem = document.querySelector('.will-call-js-switch');
  if (elem) {

    return $(elem).on('change', function () {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('hide_will_call', this.checked);

      return window.location.search = urlParams.toString();
    });
  }
}));
