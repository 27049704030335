/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () => {
  const {
    controller,
  } = document.body.dataset;
  const {
    action,
  } = document.body.dataset;
  if ((controller === 'dispatcher') && (action === 'scheduled')) { return dashboardInit(); }
});

// Adds event on page reload or when tab/browser is closed
$(window).bind('beforeunload', () => {
  const {
    controller,
  } = document.body.dataset;
  const roleElement = document.querySelector('#current-user-role');

  if (roleElement && (roleElement.dataset.role === 'dispatcher')) {
    const currentTime = moment.utc(new Date()).toISOString();
    document.cookie = `closed_at=${currentTime}; path=/`;
  }

  return undefined; // this will not launch dialogue box when page is refreshed/closed
});

var dashboardInit = () => $(document).ready(() => $('#remove_filter').on('click', (e) => {
  let currentTime = new Date();
  currentTime = new Date(currentTime.setSeconds(currentTime.getSeconds() - 60));
  currentTime = moment.utc(currentTime).toString();

  const cookies = document.cookie.split('; ');

  for (const cookie of Array.from(cookies)) {
    const keyValue = cookie.split('=');
    if (keyValue[0] === 'filters') {
      const filterObj = JSON.parse(decodeURIComponent(keyValue[1]));
      delete filterObj.ride_status;
      document.cookie = `filters=${JSON.stringify(filterObj)}; path=/`;
    }
  }

  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('ride_status[]');
  return window.location.search = urlParams.toString();
}));
