/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// account-settings.coffee
//= ==============================================================================

new Component('account-settings', ['template', 'output'], ((c) => {
  // Disable submit button if error-field is present.
  let validateRecipient;
  $('#submit-account-settings').on('click', function (e) {
    if ($(c.this).find('.cc-error-field.-error:visible').length > 0) {
      return $(this).attr('disabled', true);
    }
  });

  // Add a new TigerConnect Account.
  $('#add-tiger-connect-account').on('click', function (e) {
    const template = Handlebars.compile($(c.template).html());
    const userHospitalCount = $('#user-hospital-count').data().count;
    $(c.output).append(template);
    const elementsCount = $('[name="user_hospitals[][hospital_id]"]').length;

    // Check whether elements count is equal to hospital count.
    if (elementsCount === userHospitalCount) {
      $(this).hide();
    }

    return e.preventDefault();
  });

  // Validates the user on the TigerConnect
  $('.tiger_connect_cc_credentials').on('change', '[name="user_hospitals[][tiger_connect_id]"]', function () {
    const value = $(this).val();
    const parent = $(this).parents('.field-group');
    const hospitalId = parent.find('[name="user_hospitals[][hospital_id]"]').val();
    const errorField = parent.find('[id="error-field"]');
    const submitButton = $('#submit-account-settings');
    submitButton.attr('disabled', true);

    const successFunction = function (response) {
      submitButton.removeAttr('disabled');
      if (response.data.valid) {
        const elements = $('[name="user_hospitals[][hospital_id]"]');
        const unique = validateUniqueHospital(elements, hospitalId);

        if (unique) {
          return clearErrorField(errorField);
        }
        return addErrorMessage(
          errorField,
          'A TigerConnect account has already been linked for this hospital',
        );
      }
      return addErrorMessage(
        errorField,
        'User ID not found, please check your TigerConnect ID',
      );
    };

    const errorFunction = function () {
      submitButton.removeAttr('disabled');
      return addErrorMessage(
        errorField,
        'User ID not found, please check your TigerConnect ID',
      );
    };

    if (!hospitalId) {
      addErrorMessage(
        errorField,
        'Please select the hospital that your TigerConnect account is set up with',
      );
      return submitButton.removeAttr('disabled');
    }
    return validateRecipient(value, hospitalId, successFunction, errorFunction);
  });

  // Validate that any duplicate hospital is present or not.
  $('.tiger_connect_cc_credentials').on('change', '[name="user_hospitals[][hospital_id]"]', function () {
    const parent = $(this).parents('.field-group');
    const errorField = parent.find('[id="error-field"]');

    // Initially error message is added so when we call removeErrorFromUniqueElements
    // function then it will remove this error if it is a unique hospital.
    addErrorMessage(
      errorField,
      'A TigerConnect account has already been linked for this hospital',
    );
    const elements = $('[name="user_hospitals[][hospital_id]"]');
    const submitButton = $('#submit-account-settings');
    submitButton.attr('disabled', true);
    removeErrorFromUniqueElements(elements);
    return submitButton.removeAttr('disabled');
  });

  // Remove TigerConnect Account
  $('.tiger_connect_cc_credentials').on('click', '[name="remove_tiger_connect_cc_credentials"]', function (e) {
    $(this).parents('.field-group').remove();
    let submitButton = $('#submit-account-settings');
    const elements = $('[name="user_hospitals[][hospital_id]"]');
    submitButton = $('#submit-account-settings');
    const userHospitalCount = $('#user-hospital-count').data().count;
    submitButton.attr('disabled', true);
    const addTigerConnectButton = $('#add-tiger-connect-account');
    removeErrorFromUniqueElements(elements);
    submitButton.removeAttr('disabled');

    if (elements.length < userHospitalCount) {
      addTigerConnectButton.show();
    }

    return e.preventDefault();
  });

  // Returns headers for the Request.
  const headers = function () {
    const authTokenElement = document.querySelector('#auth-token');
    const authToken = authTokenElement.dataset.token;
    return { Authorization: `Token token=${authToken}` };
  };

  // Clear the error field.
  //
  // @param {HtmlElement} - errorField
  var clearErrorField = function (errorField) {
    errorField.html(null);
    return errorField.removeClass('-error');
  };

  // Add error message to the field.
  //
  // @param {HtmlElement} - errorField
  // @param {String} - msg
  var addErrorMessage = function (errorField, msg) {
    errorField.addClass('-error');
    return errorField.html(msg);
  };

  // Validate that hospital is unique or not.
  //
  // @param {Array<HtmlElement>} - elements
  // @param {String} - value
  // @return {Boolean}
  var validateUniqueHospital = function (elements, value) {
    let matchCount = 0;
    elements.each(function () {
      if ($(this).val() === value) {
        return matchCount++;
      }
    });

    if (matchCount > 1) { return false; } return true;
  };

  // Remove error from unique elements.
  //
  // @param {Array<HtmlElement>} - elements
  var removeErrorFromUniqueElements = (elements) => elements.each(function () {
    const value = $(this).val();
    const errorField = $(this).parents('.field-group').find('[id="error-field"]');
    const tigerConnectIdFieldValue = $(this).parents('.field-group').find('[name="user_hospitals[][tiger_connect_id]"]').val();
    const uniqueHospital = validateUniqueHospital(elements, value);

    if (uniqueHospital) {
      clearErrorField(errorField);

      const successFunction = function (response) {
        if (!response.data.valid) {
          return addErrorMessage(
            errorField,
            'User ID not found, please check your TigerConnect ID',
          );
        }
      };

      const errorFunction = () => addErrorMessage(
        errorField,
        'User ID not found, please check your TigerConnect ID',
      );

      return validateRecipient(tigerConnectIdFieldValue, value, successFunction, errorFunction);
    }
  });

  // Validate the TigerConnect recipient.
  //
  // @param {String} - value
  // @param {String} - hospitalId
  return validateRecipient = function (value, hospitalId, successFunction, errorFunction) {
    if (value.length > 0) {
      const url = '/api/v1/tiger-connect/validate-user';
      return $.ajax({
        data: { hospital_id: hospitalId, recipient: value },
        dataType: 'json',
        error: errorFunction,
        headers: headers(),
        success: successFunction,
        type: 'GET',
        url,
      });
    }
  };
}));
