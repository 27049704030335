import { useEffect, useState } from 'react';

import type { Ride, StreamableRide } from '@/types';
import type { RideFilterState } from '../types';

import { QueryStatus } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import { useLazyGetRidesQuery } from '@/api';
import { useAuth } from '@/contexts/AuthProvider';
import { dispatcherRideBadgeText } from '@/features/RideStatusBadge/helpers';
import { RideStatusBadgeText } from '@/features/RideStatusBadge/types';
import { useActionCable, useChannel } from '@/hooks/useActionCable';
import { error } from '@/lib/@datadog/browser-logs';
import { isCommunity, isScheduled } from '@/path_defs';
import { camelizeKeys } from '@/utils/camelizeKeys';

import { setPage } from '../store/ridesFilterSlice';

const useRides = (filters: RideFilterState) => {
  const { actionCable } = useActionCable();
  const { currentUser } = useAuth();

  const dispatch = useDispatch();

  /**
   * Using a lazy query because refetch is set to always.
   *
   * WebSocket rides would cause a refetch.
   */
  const [
    getRidesQuery,
    {
      data: apiRides = {
        rides: [],
        facets: { hospitals: [] },
        totalCount: 0,
        pages: 0,
      },
      isFetching,
      status: ridesListApiStatus,
      isError,
      error: rideFetchError,
    },
  ] = useLazyGetRidesQuery();

  const { subscribe: subRidesChannel, unsubscribe: unsubRidesChannel } =
    useChannel(actionCable, {
      verbose: process.env.NODE_ENV === 'development',
    });
  const { subscribe: subDashChannel, unsubscribe: unsubDashChannel } =
    useChannel(actionCable, {
      verbose: process.env.NODE_ENV === 'development',
    });

  /**
   * Local state so websockets can update the list.
   */
  const [rides, setRides] = useState<StreamableRide[]>([]);

  /**
   * Used to determine the number of pages for pagination.
   *
   * We cannot use `apiRides.totalCount` directly because that is the count of the last API request,
   * before rides have potentially been removed client-side,  nor can we use `apiRides.pages`
   * because it may no longer be correct.
   */
  const [totalCount, setTotalCount] = useState<number>(
    apiRides.totalCount || 0,
  );

  const pageLimit = filters.items;
  const currPage = filters.page;
  const finalPage = Math.ceil(totalCount / pageLimit);

  const getRides = () => getRidesQuery(filters).catch((e: unknown) => error(e));

  const removeRideById = (id: number) =>
    setRides((currRides) => {
      const filtered = currRides.filter((r) => {
        if (r.id === id) {
          setTotalCount((currCount) => currCount - 1);
          return false;
        }

        return true;
      });

      const listIsEmpty = filtered.length === 0;
      const serverSideRidesAvailable = totalCount > 1;
      const isOnLastPage = currPage === finalPage;

      if (listIsEmpty && serverSideRidesAvailable) {
        if (isOnLastPage) {
          dispatch(setPage({ page: currPage - 1 }));
        } else {
          getRides(); // eslint-disable-line @typescript-eslint/no-floating-promises
        }
      }

      return filtered;
    });

  const updateRideById = (id: number, params: Partial<StreamableRide>) => {
    setRides((curr) => {
      const idx = curr.findIndex((r) => r.id === id);

      // Do not _add_ new rides.
      if (idx === -1) {
        return curr;
      }

      const newRides = [...curr];

      newRides[idx] = {
        ...newRides[idx],
        ...params,
        updated: true,
      };

      return newRides;
    });
  };

  /**
   * Update the local state after API requests complete.
   */
  useEffect(() => {
    if (ridesListApiStatus === QueryStatus.fulfilled) {
      setRides(apiRides.rides || []);
      setTotalCount(apiRides.totalCount);
    }
  }, [ridesListApiStatus]);

  // Used to add new rides to the _community_ table when the v3 flag is off
  useEffect(() => {
    const ridesChannel: ActionCable.ChannelNameWithParams = {
      channel: 'DecoratedRidesChannel', // New Rides
      current_user_id: currentUser.id, // eslint-disable-line camelcase
    };

    subRidesChannel(ridesChannel, {
      received: (data: { ride: Ride }) => {
        const incoming = camelizeKeys(data) as Ride;

        // TODO: Error handler with toast notification
        setRides((ridesList) => {
          const idx = ridesList.findIndex((r) => r.id === incoming.id);

          if (idx === -1) {
            /**
             * Prevent auto assigned rides from showing up in the community
             */
            if (incoming.autoAssigned && isScheduled()) {
              setTotalCount((currCount) => currCount + 1);
              return [{ ...incoming, streamed: true }, ...ridesList];
            }

            /**
             * Currently do not support adding new rides to Assigned tab
             * Can't differentiate if a ride is claimed by this user, a different user or belongs in community
             */
            if (isScheduled()) {
              return ridesList;
              // return [{ ...incoming, streamed: true }, ...ridesList];
            }

            if (isCommunity()) {
              setTotalCount((currCount) => currCount + 1);
              return [{ ...incoming, streamed: true }, ...ridesList];
            }

            return ridesList;
          }

          /**
           * This would be the user on the scheduled/assigned page.
           * Ignoring incoming ride because we can't check if this ride actually belongs to the user.
           *
           * TODO: Only broadcast rides users are authorized to view
           */
          return ridesList;
        });
      },
    });

    return unsubRidesChannel;
  }, []);

  // Used to update _existing rides_ within the current dataset.
  useEffect(() => {
    const dashboardChannel: ActionCable.ChannelNameWithParams = {
      channel: 'DecoratedDashboardChannel',
      scope: 'dispatcher',
    };

    subDashChannel(dashboardChannel, {
      received: (data: { ride: Ride }) => {
        const incoming = camelizeKeys(data) as Ride;

        setRides((curr) => {
          const idx = curr.findIndex((r) => r.id === incoming.id);

          // Do not _add_ new rides to the current page with this WS channel.
          if (idx === -1) {
            return curr;
          }

          const newRides = [...curr] as StreamableRide[];
          // const rideStatus = new RideStatusBadgeService(incoming);
          const rideStatus = dispatcherRideBadgeText(incoming);

          /**
           * We currently broadcast all ride changes to the frontend which results in
           * rides, awarded to other transit companies, receiving an 'Assigned' badge.
           * When users click on this ride, the backend will redirect them with a flash
           * stating they cannot view this ride.
           *
           * Current UX design is to remove the ride from the community page.
           */
          if (isCommunity() && rideStatus === RideStatusBadgeText.ASSIGNED) {
            newRides.splice(idx, 1);

            return newRides;
          }

          newRides[idx] = {
            ...newRides[idx],
            ...incoming,
            updated: true,
          };

          return newRides;
        });
      },
    });

    return unsubDashChannel;
  }, []);

  return {
    count: totalCount,
    facets: apiRides.facets,
    getRides,
    isError,
    isFetching,
    rides,
    messages: rideFetchError?.data?.messages as {
      description: string;
      statusCode: string;
    }[],
    removeRideById,
    updateRideById,
  };
};

export default useRides;
