import React, { useEffect, useState } from 'react';

import type { GlobalSSRData } from '@/types';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { pushFlashMessage } from '../../store/flashMessagesSlice';
import FlashMessages from '../FlashMessages';

const Container = ({ children }: { children: React.ReactNode }) => {
  // eslint-disable-next-line no-underscore-dangle
  const { flash } = (window as GlobalSSRData)._unsafeSSRData.global;
  const dispatch = useDispatch();
  const { enableReactRouter } = useFlags();
  /**
   * Flag updates are streamed from launch darkly to the client. If rails serves the HTML with the flag off
   * and then LD streams a switch, the screen goes blank.
   *
   * This is a stopgap for prevent a blank screen. The next hard refresh will result in the new page.
   */
  const [flagInitVal, setFlagInitVal] = useState<boolean | undefined>(
    enableReactRouter as boolean | undefined,
  );
  useEffect(() => {
    if (flagInitVal === undefined && enableReactRouter !== undefined) {
      setFlagInitVal(enableReactRouter as boolean);
    }
  }, [enableReactRouter]);

  useEffect(() => {
    if (flash?.length > 0) {
      flash.forEach(([type, text]) => {
        // Rails passes in `['alert', nil]` when flash list is empty and `[true, 'timeout']` when sessions expire.
        if (!type || !text || typeof type !== 'string') {
          return;
        }

        dispatch(
          pushFlashMessage({
            text,
            type,
          }),
        );
      });
    }
  }, [flash]);

  const Child = flagInitVal ? Outlet : () => children;

  return (
    <div
      className="wrapper mt-6 pb-10 -wide"
      id="main-community-container"
      role="main"
    >
      <FlashMessages />

      <Child />
    </div>
  );
};

export default Container;
