/* eslint-disable consistent-return */
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// care-coordinator-edit.coffee
//= ==============================================================================

// eslint-disable-next-line no-new
new Component('care-coordinator', ['edit', 'close', 'cancel', 'add_hospital',
  'hospitals', 'hospital_output', 'template', 'update_user', 'remove_user', 'remove_circle'], ((c) => {
  let confirmExit;
  let editMode = false;

  const headers = { Authorization: `Token token=${$(c.this).data('auth-token')}` };

  $(document).on('cc-sidebar-open', () => editMode = false);

  // Clear modal fields
  const get_assigned_hospitals = function () {
    const hospitals = [];
    $('.data-row.hospital:visible').each(function () {
      return hospitals.push($(this).attr('id').split('-')[1]);
    });
    return hospitals;
  };

  $(document).on('click', c.edit, function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    editMode = true;

    const sidebar = $(this).parents('.right-sidebar');
    const userId = sidebar.attr('id');

    $(`#user-phone-${userId}`).mask('000-000-0000');

    sidebar.addClass('-show');

    sidebar.find('.sidebar-right .view').hide();
    sidebar.find('.sidebar-right .edit').show();

    sidebar.find('.sidebar-content .view').hide();
    return sidebar.find('.sidebar-content .edit').show();
  });

  $(document).on('click', c.close, function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    const sidebar = $(this).parents('.right-sidebar');

    const hideSidebar = function () {
      $(sidebar).removeClass('-show');
      $(sidebar).attr('id', null);
      return editMode = false;
    };

    if (editMode) {
      return confirmExit()
        .then(hideSidebar)
        .catch(() => {});
    }
    return hideSidebar();
  });

  $(document).on('click', c.cancel, function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    const sidebar = $(this).parents('.right-sidebar');

    return confirmExit()
      .then(() => {
        editMode = false;

        const userId = $('.right-sidebar').attr('id');
        $(`#user_current_hospital_id-${userId} option`).show();

        sidebar.find('.data-row-right.show').show();
        sidebar.find('.data-row.hospital .button').not('.hidden-lg').addClass('hidden-lg');
        sidebar.find('.data-row.hospital:hidden').show();
        sidebar.find('.sidebar-content .view').show();
        sidebar.find('.sidebar-content .edit').hide();
        sidebar.find('.sidebar-right .view').show();
        return sidebar.find('.sidebar-right .edit').hide();
      })
      .catch(() => {});
  });

  $(document).on('change', c.add_hospital, function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    const userId = $(this).parents('.right-sidebar').attr('id');
    const hospitalId = $(`#user_hospital_id-${userId}`).val();

    if (!hospitalId) {
      return;
    }

    $(this).addClass('disabled');

    return $.ajax({
      data: { hospital_id: hospitalId },
      dataType: 'json',
      headers,
      // eslint-disable-next-line consistent-return
      success(data, status) {
        const template = Handlebars.compile($(c.template).html());
        const html = template({ id: data.data.hospital_id, user_hospital: data.data.name });

        $(c.hospital_output).append(html);
        $(`#${userId} select option[value='${hospitalId}']`).attr('hidden', 'true');
        $(`#user_hospital_id-${userId}`).val('Select a Hospital to Assign');
        hideIfAllHospitalSelected(userId);
        $(c.add_hospital).removeClass('disabled');
        $(`#user-${userId}-current_hospital`).html('<span>Multiple...</span>');

        const $new_assigned_hospital = document.createElement('option');
        $new_assigned_hospital.value = hospitalId;
        $new_assigned_hospital.text = data.data.name;

        $(`#user_current_hospital_id-${userId}`).append($new_assigned_hospital);
        $(`#hospital-${data.data.hospital_id} .edit`).show();
        $(`#tiger-connect-id-view-${hospitalId}-${userId}`).hide();
        $(`#user-hospital-id-${hospitalId}-${userId}`).data('id', data.data.user_hospital_id);

        if (data.data.tiger_connect_active === false) {
          return $(`#tiger-connect-credentials-link-${hospitalId}-${userId}`).hide();
        } if (data.data.tiger_connect_id) {
          $(`#tiger-connect-id-view-${hospitalId}-${userId}`).html(data.data.tiger_connect_id);
          $(`#tiger-connect-credentials-input-${hospitalId}-${userId}`).val(data.data.tiger_connect_id);

          return $(`#tiger-connect-credentials-link-${hospitalId}-${userId}`).html(data.data.tiger_connect_id);
        }
      },
      type: 'POST',
      url: `/api/v1/super-user/care-coordinator/${userId}/assign-hospital`,
    });
  });

  var hideIfAllHospitalSelected = function (userId) {
    const options = $(`#user_hospital_id-${userId} option`);
    let all_true = true;
    for (const option of Array.from(options)) {
      if ((option.value !== 'Select a Hospital') && !option.hidden) {
        all_true = false;
        break;
      }
    }

    if (all_true) {
      return $(`#user_hospital_id-${userId}-dropdown`).hide();
    }
  };

  $(document).on('click', c.update_user, function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    $(c.update_user).attr('disabled', true);

    const userId = $(this).parents('.right-sidebar').attr('id');
    const sidebar = $(this).parents('.right-sidebar');
    const payLoad = {
      active: $(`#user-active-${userId}`).val(),
      assigned_hospitals: get_assigned_hospitals(),
      email: $(`#user-email-${userId}`).val(),
      emr_user_id: $(`#user-emr-id-${userId}`).val(),
      first_name: $(`#user-first-name-${userId}`).val(),
      hospital_id: $(`#user_current_hospital_id-${userId}`).val(),
      last_name: $(`#user-last-name-${userId}`).val(),
      limited_access: $(`#user-limited-access-${userId}`).val(),
      phone: $(`#user-phone-${userId}`).val(),
      show_facility_rides: $(`#user-show-facility-ride-${userId}`).prop('checked'),
      time_zone: $(`#user-time-zone-${userId}`).val(),
    };

    return $.ajax({
      data: payLoad,
      dataType: 'json',
      error(xhr, status, e) {
        const {
          errors,
        } = xhr.responseJSON;
        if (errors.email) {
          $(`#user-email-${userId}`).parent('.field').addClass('-error');
        }
        if (errors.emr_user_id) {
          $(`#user-emr-id-${userId}`).parent('.field').addClass('-error');
        }
        if (errors.first_name) {
          $(`#user-first-name-${userId}`).parent('.field').addClass('-error');
        }
        if (errors.last_name) {
          $(`#user-last-name-${userId}`).parent('.field').addClass('-error');
        }
        if (errors.phone) {
          $(`#user-phone-${userId}`).parent('.field').addClass('-error');
          return $(sidebar).attr('id', null);
        }
      },
      headers,
      success(response, status) {
        editMode = false;
        $(sidebar).removeClass('-show');
        $(`#user-${userId}-first-name`).html(payLoad.first_name);
        $(`#user-${userId}-last-name`).html(payLoad.last_name);
        $(`#user-${userId}-email`).html(payLoad.email);
        $(`#user-${userId}-active`).html(payLoad.active === 'true' ? '<span class="check-mark"></span>' : '<span class="cross-mark"></span>');
        $(`#user-${userId}-limited-access`).html(payLoad.limited_access === 'true' ? '<span class="check-mark"></span>' : '<span class="cross-mark"></span>');

        let facility = 'Multiple...';

        if (response.data.user_hospitals && (response.data.user_hospitals.length === 1)) {
          facility = response.data.current_hospital?.name;
        }

        $(`#user-${userId}-current_hospital`).html(`<span>${facility}</span>`);
        return $(sidebar).attr('id', null);
      },
      type: 'PUT',
      url: `/api/v1/super-user/care-coordinator/${userId}/update`,
    });
  });

  $(document).on('click', c.remove_user, function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    $(this).closest('.data-row').hide();
    const userId = $('.right-sidebar').attr('id');
    const hospitalID = $(this).closest('.data-row')[0].id.split('-')[1];
    if ($(`#user_current_hospital_id-${userId} option[value=${hospitalID}]`).is(':selected')) {
      $(`#user_current_hospital_id-${userId} option`)[0].selected = true;
    }
    return $(`#user_current_hospital_id-${userId} option[value=${hospitalID}]`).hide();
  });

  $(document).on('click', c.remove_circle, function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    $(this).closest('.data-row-right').hide();
    return $(this).closest('.data-row').find('.button').removeClass('hidden-lg');
  });

  return confirmExit = () => new Promise((resolve, reject) => {
    const confirmed = confirm('Unsaved changes will be lost. Are you sure you want to continue?');

    if (confirmed) {
      return resolve();
    }
    return reject();
  });
}));
