/* eslint-disable import/prefer-default-export */

import { useEffect, useState } from 'react';

import type { Colors } from '@/features/RideStatusBadge/types';
import type { Ride, StreamableRide } from '@/types';

import {
  CheckIcon,
  ClockIcon,
  Cross2Icon,
  DotIcon,
  MinusIcon,
} from '@radix-ui/react-icons';
import moment from 'moment';

import { COLORS, RideStatusBadgeText } from '@/features/RideStatusBadge/types';
import { useInterval } from '@/hooks/useInterval';
import { TIMELINESS } from '@/types';

import RideStatusBadgeService from './RideStatusBadgeService';

const INTERVAL = 1000;

const humanizedTimeliness = ({ timeliness }: Ride) => {
  switch (timeliness) {
    case TIMELINESS.on_time:
      return 'On time';
    case TIMELINESS.late_15_min:
      return 'Late 15 min';
    case TIMELINESS.late_30_min:
      return 'Late 30 min';
    case TIMELINESS.late_45_min:
      return 'Late 45 min';
    case TIMELINESS.late_60_min:
      return 'Late 60 min';
    case TIMELINESS.late_over_60_min:
      return 'Late over 60 min';
    default:
      return 'On time';
  }
};

const doubleDigits = (num: number) =>
  num.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

const formatDisplay = (seconds: number) =>
  `${Math.floor(seconds / 60)}:${doubleDigits(seconds % 60)} left`;

export const useStatusBadge = (ride: StreamableRide) => {
  const [text, setText] = useState<RideStatusBadgeText>();
  const [style, setStyle] = useState<Colors>();
  const [subtext, setSubtext] = useState('');
  const [icon, setIcon] = useState<typeof CheckIcon | null>(null);
  const [subIcon, setSubIcon] = useState<typeof CheckIcon | null>(null);
  const [delay, setDelay] = useState(1000);
  const [isRunning, setIsRunning] = useState(false);
  const biddingWindowCutoff = moment
    .utc(ride.communityArrival)
    .add(ride.biddingWindow, 's');

  const ppCutoffTime = moment
    .utc(ride.preferredProviderArrival)
    .add(ride.ppBiddingWindow, 's');

  // TODO: Clean up this and RideStatusBadgeService
  const remainingTime = (cutoff: moment.Moment) =>
    cutoff.diff(moment.utc(), 's');

  const withinWindow = (cutoff: moment.Moment) =>
    cutoff.isValid() && remainingTime(cutoff) > 0;

  const rideStatus = new RideStatusBadgeService(ride);

  const setProcessing = () => {
    setStyle(COLORS.processing);
    setText(RideStatusBadgeText.PROCESSING);
    setSubtext('');
    setIcon(null);
    setIsRunning(false);
  };

  const clearSubtext = () => {
    setSubIcon(null);
    setSubtext('');
    setIsRunning(false);
  };

  /**
   * Sets the countdown ticker
   */
  const setAfterTickValues = () => {
    if (!withinWindow(biddingWindowCutoff)) {
      // TODO: Should show processing if loss bid?
      setProcessing();

      return;
    }

    setSubIcon(ClockIcon);

    if (withinWindow(ppCutoffTime)) {
      setSubtext(formatDisplay(remainingTime(ppCutoffTime)));

      return;
    }

    if (withinWindow(biddingWindowCutoff)) {
      setSubtext(formatDisplay(remainingTime(biddingWindowCutoff)));

      return;
    }

    clearSubtext();
  };

  useInterval(() => setAfterTickValues(), isRunning ? delay : null);

  useEffect(() => {
    if (rideStatus.isDeclined) {
      setStyle(COLORS.deepRed);
      setText(RideStatusBadgeText.DECLINED);
      setIcon(Cross2Icon);
      clearSubtext();

      return;
    }

    if (rideStatus.isCompleted) {
      setStyle(COLORS.green);
      setText(RideStatusBadgeText.COMPLETED);
      clearSubtext();

      return;
    }

    if (rideStatus.isCanceled) {
      setStyle(COLORS.orange);
      setText(RideStatusBadgeText.CANCELED);
      clearSubtext();

      return;
    }

    if (rideStatus.isProcessing) {
      setProcessing();

      return;
    }

    if (rideStatus.isAssigned) {
      // Default to assigned
      setStyle(COLORS.green);
      setText(RideStatusBadgeText.ASSIGNED);
      clearSubtext();
    }

    if (rideStatus.isNotStarted) {
      setStyle(COLORS.grey);
      setText(RideStatusBadgeText.NOT_STARTED);
      setIcon(MinusIcon);
      clearSubtext();

      return;
    }

    if (rideStatus.isInProgress) {
      setStyle(COLORS.green);
      setText(RideStatusBadgeText.IN_PROGRESS);
      setSubtext(humanizedTimeliness(ride));

      return;
    }

    if (withinWindow(biddingWindowCutoff)) {
      setAfterTickValues(); // Run once so the badge isn't `null` for 1 sec.
      setIsRunning(true);
    }

    if (rideStatus.isResponded) {
      setStyle(COLORS.grey);
      setText(RideStatusBadgeText.RESPONDED);
      setIcon(CheckIcon);

      return;
    }

    if (rideStatus.isSubmitETA) {
      setStyle(COLORS.orange);
      setText(RideStatusBadgeText.SUBMIT_ETA);

      return;
    }

    if (rideStatus.isNewRide) {
      setStyle(COLORS.newRide);
      setText(RideStatusBadgeText.NEW_RIDE);

      return;
    }

    if (rideStatus.isPreferred) {
      setStyle(COLORS.preferred);
      setText(RideStatusBadgeText.PREFERRED);

      return;
    }

    if (rideStatus.isAvailable) {
      setStyle(COLORS.green);
      setText(RideStatusBadgeText.AVAILABLE);
      setIcon(DotIcon);
    }
  }, [ride]);

  return {
    style,
    text,
    subtext,
    Icon: icon,
    SubIcon: subIcon,
    isInBiddingWindow: isRunning,
    isInPpBiddingWindow: withinWindow(ppCutoffTime),
  };
};
