const reactCommunityRidesTable = () =>
  document.getElementById('dispatcher-community-rides-table-react');

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).on('turbolinks:load', () => {
  if (reactCommunityRidesTable()) {
    return;
  }
  let ride_id;
  let scope;
  const disable_socket_element = document.querySelector('#disable-web-sockets');

  if (
    disable_socket_element &&
    disable_socket_element.dataset &&
    disable_socket_element.dataset.disable === 'true'
  ) {
    return;
  }

  const path = window.location.pathname.split('/');
  if (path[1] === 'admin' && path[2] === 'rides') {
    ride_id = path[3];
    scope = 'admin';
  } else if (path[1] === 'rides') {
    ride_id = path[2];
    scope = 'care_coordinator';
  }

  if (ride_id && scope && typeof parseInt(ride_id) === 'number') {
    return (App.ride_share_notifications = App.cable.subscriptions.create(
      {
        channel: 'RideShareNotificationsChannel',
        ride: ride_id,
        scope,
      },
      {
        connected() {},
        // Called when the subscription is ready for use on the server

        disconnected() {},
        // Called when the subscription has been terminated by the server

        received(data) {
          if (reactCommunityRidesTable()) {
            return;
          }
          const updateRideStatus = function (status) {
            $('[data-status]').attr('class', `-large status ${status.class}`);
            $('[data-status-message]').html(status.copy);
            if (data.tooltip_info != null) {
              $('.show_icon').removeClass('-hidden');
              return $('.tooltip_text').html(data.tooltip_info);
            }
            return $('.show_icon').addClass('-hidden');
          };
          const updateDriverDetails = function (driver) {
            let driverInfoPresent = false;
            if (driver.image_url) {
              driverInfoPresent = true;
              $('[data-driver-image]').attr('src', driver.image_url);
              $('[data-driver-image]').removeClass('ride-share-hidden');
            }

            if (driver.name) {
              driverInfoPresent = true;
              $('[data-driver-name]').removeClass('ride-share-hidden');
              $('[data-driver-name] dd').html(driver.name);
            }

            if (driver.phone_number) {
              driverInfoPresent = true;
              $('[data-driver-phone]').removeClass('ride-share-hidden');
              $('[data-driver-phone] dd').html(
                driver.phone_number.replace(
                  /(\d{1})(\d{3})(\d{3})/,
                  '$1 $2 $3 ',
                ),
              );
            }

            if (driver.rating) {
              driverInfoPresent = true;
              $('[data-driver-rating]').removeClass('ride-share-hidden');
              $('[data-driver-rating] dd').html(driver.rating);
            }

            if (driverInfoPresent) {
              return $('[data-driver]').show();
            }
          };
          const updateVehicleDetails = function (vehicle) {
            let vehicleInfoPresent = false;
            if (vehicle.image_url) {
              vehicleInfoPresent = true;
              $('[data-vehicle-image]').removeClass('ride-share-hidden');
              $('[data-vehicle-image]').attr('src', vehicle.image_url);
            }

            if (vehicle.color || vehicle.make || vehicle.model) {
              vehicleInfoPresent = true;

              $('[data-vehicle-color-make-model]').removeClass(
                'ride-share-hidden',
              );
              $('[data-vehicle-color-make-model] dd').html(
                [vehicle.color, vehicle.make, vehicle.model].join(' '),
              );
            }

            if (vehicle.license_plate) {
              vehicleInfoPresent = true;
              $('[data-vehicle-license]').removeClass('ride-share-hidden');
              $('[data-vehicle-license] dd').html(vehicle.license_plate);
            }

            if (vehicleInfoPresent) {
              return $('[data-vehicle]').show();
            }
          };

          const hideSelectedVehicleSection = () =>
            $('#selected-vehicle').addClass('hide');

          const { driver } = data;
          const { vehicle } = data;
          const status = {
            class: data.current_status_class,
            copy: data.current_status,
          };
          const { distance } = data;
          const { duration } = data;

          // Update details in the DOM
          if (driver != null) {
            updateDriverDetails(driver);
          }
          if (vehicle != null) {
            updateVehicleDetails(vehicle);
          }
          if (driver != null || vehicle != null) {
            hideSelectedVehicleSection();
          }
          if (status != null) {
            updateRideStatus(status);
          }
          if (distance != null) {
            $(['data-distance']).html(distance);
          }
          if (duration != null) {
            return $(['data-duration']).html(duration);
          }
        },
      },
    ));
  }
  if (App.ride_share_notifications != null) {
    const activeSubscriptions = App.cable.subscriptions.findAll(
      App.ride_share_notifications.identifier,
    );
    activeSubscriptions.map((sub) => sub.unsubscribe());
    return (App.ride_share_notifications = null);
  }
});
