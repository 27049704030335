/* eslint-disable no-new */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// trip-map.coffee
//= ==============================================================================

// Shows pick and dropoff locations of a trip on a map
new Component('trip-map', [], ((c) => {
  let bounds_opts; let driver_coords; let driver_marker; let dropoff_coords; let map; let offset; let pickup_coords; let
    timer;
  const bounds = (bounds_opts = (driver_coords = (driver_marker = (dropoff_coords = (map = (offset = (pickup_coords = (timer = null))))))));

  var pollRideDetails = function (ride_id, init_call) {
    const auth_token = document.querySelector('.auth-token').dataset.token;
    const headers = { Authorization: `Token token=${auth_token}` };
    let data = { ride_id };
    const url = getPollingUrl();

    return $.ajax({
      complete() {
        if (isRideTrackable() && getRideId()) {
          return timer = setTimeout(pollRideDetails, 1000 * 5, ride_id, false);
        }
        return clearTimeout(timer);
      },
      data,
      dataType: 'json',
      error(error) {
        $(window).trigger('trip-map-pickup-eta-removed');
        return console.log('error', error);
      },
      headers,
      success(response, status) {
        if (response && response.data && response.data.ride && response.data.ride.location) {
          const ride_location = response.data.ride.location;
          const vehicle = response.data.ride.ride_type;
          const location = {
            bearing: ride_location.bearing,
            latitude: ride_location.lat,
            longitude: ride_location.lng,
            pickUpEta: response.data.ride.pickup_eta_minutes,
            updateMapBounds: init_call,
            vehicle,
          };
          data = {
            current_status: response.data.ride.current_status,
            location,
          };
          return $(window).trigger('trip-map-driver-location-updated', [data]);
        }
        return $(window).trigger('handle-absent-driver-location');
      },
      type: 'GET',
      url,
    });
  };

  const isLyftRide = () => $('[data-lyft]').length;

  const isUberRide = () => $('[data-uber]').length;

  const isWellrydeRide = () => $('[data-wellryde]').length;

  const isCompleted = () => $('.status-message').text() === 'Completed';

  var isRideTrackable = () => (isLyftRide() || isWellrydeRide() || isUberRide()) && !isCompleted();

  var getRideId = () => $('#ride-info').data('ride-id');

  var getPollingUrl = function () {
    let url = '';

    if (isLyftRide()) {
      url = '/integrations/lyft/ride_details';
    } else if (isUberRide()) {
      url = '/integrations/uber/ride_details';
    }
    return url;
  };

  if (!/PhantomJS/.test(window.navigator.userAgent)) {
    // Parse coordinate values
    pickup_coords = $(c.this).data('pickup');
    dropoff_coords = $(c.this).data('dropoff');
    const params = {
      driver_image: $(c.this).data('driverimage'),
      dropoff_image: $(c.this).data('dropoffimage'),
      dropoff_lat: dropoff_coords[1],
      dropoff_lng: dropoff_coords[0],
      multistop_image: $(c.this).data('multistopimage'),
      pickup_image: $(c.this).data('pickupimage'),
      pickup_lat: pickup_coords[1],
      pickup_lng: pickup_coords[0],
    };

    if ($(c.this).data('multistop-0')) {
      const multistop_coords = $(c.this).data('multistop-0');
      params.multistop_image = $(c.this).data('multistopimage');
      params.multistop_lat = multistop_coords[1];
      params.multistop_lng = multistop_coords[0];
    }

    $(document).trigger('initialize-static-google-map', params);

    // Adjust map on load
    if (isRideTrackable()) {
      const ride_id = getRideId();
      if (ride_id != null) { pollRideDetails(ride_id, true); }
    }
  }
}));
