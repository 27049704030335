import React from 'react';

import type { RideStatusQueryParams } from '@/types';
import type { MouseEventHandler } from 'react';

import useRideCount from '../../hooks/useRideCount';

const CountIcon = ({ count }: { count: number }) =>
  count ? <span className="indicator-circle">{count}</span> : null;

// TODO: Better name?
const CountFetcher = ({ status }: { status: RideStatusQueryParams }) => {
  const { count } = useRideCount(status);

  return <CountIcon count={count} />;
};

const StatusButton = ({
  active,
  children,
  count,
  href = '#',
  onClick,
  status,
}: {
  active: boolean;
  children: React.ReactNode;
  count?: number;
  href?: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  status?: RideStatusQueryParams;
}) => {
  return (
    <a
      href={`${href}`}
      className={`toggle-option ${active ? 'is-active' : null}`}
      onClick={onClick}
    >
      {children}

      {status && <CountFetcher status={status} />}
      {count && <CountIcon count={count} />}
    </a>
  );
};

export default StatusButton;
