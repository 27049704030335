import React, { useEffect, useMemo, useState } from 'react';

import type { AuthStatus, Entries } from '@/types';

import { ChevronDownIcon } from '@radix-ui/react-icons';

import * as Popover from '@/lib/@radix-ui/react-popover';
import { RIDE_AUTH_STATUS } from '@/types';

import CheckboxItem from './CheckboxItem';

const AUTH_DISPLAY_NAMES = {
  authNotByRoundtrip: 'Auth. not by Roundtrip',
  authorized: 'Authorized',
  authorizing: 'Authorizing',
  denied: 'Denied',
  moreInfoNeeded: 'More info needed',
  noAuthorizationRequired: 'No auth. required',
};

const authList = Object.entries(RIDE_AUTH_STATUS) as Entries<
  typeof RIDE_AUTH_STATUS
>;

const filterLabel = 'Auth. Status';

const getStatusDisplayText = (value: string) => {
  const lookupKey = (
    Object.keys(RIDE_AUTH_STATUS) as (keyof typeof RIDE_AUTH_STATUS)[]
  ).find((key) => RIDE_AUTH_STATUS[key] === value);

  return AUTH_DISPLAY_NAMES[lookupKey as keyof typeof RIDE_AUTH_STATUS];
};

const getFilterLabel = (statusTypes: AuthStatus[]) => {
  if (authList.length === statusTypes.length) {
    return filterLabel;
  }

  if (statusTypes.length === 1) {
    return `${filterLabel}: ${getStatusDisplayText(statusTypes[0])}`;
  }

  return `${filterLabel}: Multiple`;
};

const AuthorizationStatus: React.FC<{
  setStatuses: (statuses: AuthStatus[]) => void;
  statuses: AuthStatus[];
}> = ({ statuses, setStatuses }) => {
  const [updatedStatuses, setUpdatedStatuses] = useState(statuses);
  const label = useMemo(() => getFilterLabel(statuses), [statuses]);

  useEffect(() => setUpdatedStatuses(statuses), [statuses]);

  const handleClose = () =>
    statuses !== updatedStatuses && setStatuses(updatedStatuses);

  const handleCheck = (status: AuthStatus) => {
    const statusPresent = updatedStatuses.includes(status);

    if (statusPresent) {
      setUpdatedStatuses(
        updatedStatuses.filter((statuss) => status !== statuss),
      );
    } else {
      setUpdatedStatuses([...updatedStatuses, status]);
    }
  };

  return (
    <div className="">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {label}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content"
            align="start"
            onCloseAutoFocus={handleClose}
          >
            {authList.map(([key, value]) => {
              return (
                <CheckboxItem
                  key={key}
                  text={AUTH_DISPLAY_NAMES[key]}
                  checked={updatedStatuses.includes(value)}
                  onCheckedChange={() => handleCheck(value)}
                />
              );
            })}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default AuthorizationStatus;
