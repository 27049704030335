import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SortDownIcon from '../../../../../assets/images/icon-sort-down.svg';
import SortUpIcon from '../../../../../assets/images/icon-sort-up.svg';
import SortIcon from '../../../../../assets/images/icon-sort.svg';

import './styles.scss';

import {
  selectSortField,
  selectSortType,
  setSortField,
  setSortType,
} from '../../store/ridesFilterSlice';

interface ColumnHeader {
  label?: string;
  sortKey?: string;
}

const Header = ({ sortKey, label }: ColumnHeader) => {
  const dispatch = useDispatch();
  const currActive = useSelector(selectSortField);
  // On initial render, currActive is undefined at first so we need to handle that edge case
  const isActive = currActive === sortKey && currActive !== undefined;
  const currOrder = useSelector(selectSortType);

  const onClick = () => {
    if (isActive) {
      const newOrder =
        currOrder === 'ASC' || currOrder === undefined ? 'DESC' : 'ASC';
      dispatch(setSortType(newOrder));
    } else {
      dispatch(setSortType('DESC'));
      dispatch(setSortField(sortKey));
    }
  };

  let Icon = SortIcon;
  if (isActive && currOrder === 'ASC') {
    Icon = SortDownIcon;
  }
  if (isActive && currOrder === 'DESC') {
    Icon = SortUpIcon;
  }

  return (
    <th
      onClick={onClick}
      key={sortKey}
      className={`header-label ${isActive ? 'active' : ''}`}
    >
      <div
        className="d-inline-flex flex-items-center"
        style={{ gap: '0.125rem' }}
      >
        {label}
        <Icon />
      </div>
    </th>
  );
};

export default Header;
