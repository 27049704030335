/* eslint-disable no-param-reassign */
import type { RootState } from '@/store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ToastNotification } from '../types';

import { createSlice } from '@reduxjs/toolkit';

interface toastNotificationsState {
  notifications: ToastNotification[];
}

const initState: toastNotificationsState = {
  notifications: [],
};

const toastNotificationsSlice = createSlice({
  initialState: initState,
  name: 'toastNotifications',
  reducers: {
    popToastNotification: (state, action: PayloadAction<ToastNotification>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.text !== action.payload.text,
      );
    },
    pushToastNotification: (
      state,
      action: PayloadAction<ToastNotification>,
    ) => {
      state.notifications = [...state.notifications, action.payload];
    },
  },
});

export const selectNotifications = (state: RootState) =>
  state.toastNotifications.notifications;

export const { pushToastNotification, popToastNotification } =
  toastNotificationsSlice.actions;
export default toastNotificationsSlice.reducer;
