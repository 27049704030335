/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// sort-data.coffee
//= ==============================================================================

new Component('sort-data', [], ((c) => {
  const sort_fields = $(c.this).find('*[data-field-name]').map(function () { return $(this).data('field-name'); });
  const sort_orders = ['asc', 'desc'];

  const default_field = $(c.this).attr('data-default-field');
  const default_order = $(c.this).attr('data-default-order');

  const search = new URLSearchParams(window.location.search);
  let field = search.get('field') || default_field;
  let order = search.get('order') || default_order;

  if (!Array.from(sort_fields).includes(field) || !Array.from(sort_orders).includes(order)) {
    field = default_field;
    order = default_order;
  }

  const column = $(c.this).find(`*[data-field-name=${field}]`);
  column.addClass('active-column');

  if (order === 'asc') {
    column.find('.arrow-down').hide();
  } else {
    column.find('.arrow-up').hide();
  }

  const selector = $(c.this).find('*[data-field-name]');

  return selector.on('click', (e) => {
    let new_order;
    const new_field = $(e.target).closest('.column-heading').data('field-name');

    if ((new_field === field) && (order === 'asc')) {
      new_order = 'desc';
    } else {
      new_order = 'asc';
    }

    search.set('field', new_field);
    search.set('order', new_order);

    return window.location.search = search.toString();
  });
}));
