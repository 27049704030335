import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useDeleteRideMutation } from '@/api';
import { pushFlashMessage } from '@/store/flashMessagesSlice';

import './main.scss';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { pushToastNotification } from '@/store/toastNotificationsSlice';

import Modal from './Modal';

const CancelModal: React.FC<{
  hide: () => void;
  rideId: number;
  show: boolean;
}> = ({ rideId, hide, show }) => {
  const [additionalInfo, setAdditionalInfo] = useState('');
  const { removeRideById } = useTableContext();

  const dispatch = useDispatch();

  const [deleteRide] = useDeleteRideMutation();

  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setAdditionalInfo(event.target.value);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const body = {
      additionalCancelationInfo: additionalInfo,
      cancelationReason: '',
      sendNotifications: false,
    };

    deleteRide({ body, rideId })
      .unwrap()
      .then(() => {
        removeRideById(rideId);
        hide();
        dispatch(
          pushToastNotification({
            text: 'Ride successfully canceled.',
            rideId,
            action: 'view ride',
          }),
        );
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: 'Sorry, this ride cannot be canceled at the moment. Please try again in a few minutes.',
            type: 'error',
          }),
        );
      });
  };

  return (
    <Modal
      open={show}
      hide={hide}
      title="Cancel ride"
      subtitle="Cancel this ride if the patient no longer needs transport. If you cannot complete this ride, please release it to the Community."
    >
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="field mt-1">
          <label htmlFor="additional_cancelation_info" className="label">
            Reason <span className="required">*</span>
          </label>

          <textarea
            id="additional_cancelation_info"
            name="additional_cancelation_info"
            value={additionalInfo}
            onChange={handleAdditionalInfoChange}
            className="input -tall"
          />
        </div>

        <div className="alert-dialog-actions">
          <button
            type="button"
            className="button -medium -inverted-blue"
            onClick={hide}
          >
            Nevermind
          </button>

          <button
            data-test="cancel-ride-submit-btn"
            type="submit"
            className="button -medium -cancel"
          >
            Cancel ride
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CancelModal;
