/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-arrow-callback */

function handleClick(e) {
  e.preventDefault();

  const elm = document.getElementById('patient-name-heading');
  const headers = { Authorization: `Bearer ${window.getCookie('JWT')}` };

  $.ajax({
    data: {
      patient: {
        do_not_book: !(elm.dataset.patientDoNotBook === 'true'),
      },
    },
    dataType: 'json',
    headers,
    success() {
      window.location.reload();
    },
    type: 'PATCH',
    url: `/api/v3/patients/${elm.dataset.patientId}`,
  });
}

document.addEventListener('turbolinks:load', function () {
  const {
    controller,
  } = document.body.dataset;
  const {
    action,
  } = document.body.dataset;

  if (controller !== 'patients'
    || !['scheduled', 'completed', 'canceled'].includes(action)) {
    return undefined;
  }

  const elm = document.getElementById('do-not-book') || document.getElementById('safe-to-book');

  elm.addEventListener('click', handleClick);
});
