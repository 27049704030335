/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// confirm_arrival.coffee
//= ==============================================================================

new Component('confirm_arrival', ['overlay', 'token'], ((c) => {
  const showBidFailureModal = function (error) {
    $(c.this).attr('disabled', false);
    $('#bid-error-modal').addClass('is-active');
    return $('#failure_message').html(error);
  };

  const showSuccessModal = function (id) {
    $('#confirm-arrival-modal').removeClass('is-active');
    $(c.this).attr('disabled', false);
    return $(`#${id}-modal`).addClass('is-active');
  };

  return $('[data-modal-target="confirm-arrival-open"]').on('click', (e) => setTimeout(showText, 100));
}));

var showText = function () {
  let is_dropoff = $('.is-active[data-modal-target] [name="is_dropoff"]').val();
  is_dropoff = is_dropoff === 'true';
  if (is_dropoff) {
    return $('.-arrival-message').html('When was the rider dropped off?');
  }
  return $('.-arrival-message').html('When was the rider picked up?');
};
