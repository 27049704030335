import React from 'react';

import { useSelector } from 'react-redux';

import { useCreateReportMutation } from '@/api/rides/createReport';
import { useAuth } from '@/contexts/AuthProvider';
import {
  selectEndTime,
  selectStartTime,
} from '@/features/RidesTable/store/ridesFilterSlice';
import * as AlertDialog from '@/lib/@radix-ui/react-alert-dialog';

const ExportModal: React.FC = () => {
  const { currentUser } = useAuth();
  const startDate = useSelector(selectStartTime);
  const endDate = useSelector(selectEndTime);

  const [
    createReport,
    { isError, isLoading, isSuccess, isUninitialized, reset },
  ] = useCreateReportMutation();

  const onChange = () => !isUninitialized && reset();

  const handleExport = () =>
    createReport({ startDate, endDate })
      .unwrap()
      .catch((error: unknown) => console.error(error));

  return (
    <AlertDialog.Root onOpenChange={onChange}>
      <AlertDialog.Trigger asChild>
        <button
          className="button -inverted-blue -small d-none d-sm-inline-block"
          data-modal-target="generate-report-open"
          type="button"
        >
          Export
        </button>
      </AlertDialog.Trigger>

      <AlertDialog.Portal>
        <AlertDialog.Overlay className="alert-dialog-overlay" />

        <AlertDialog.Content className="alert-dialog-content">
          <AlertDialog.Title className="mb-6">Export report</AlertDialog.Title>

          {isUninitialized && (
            <div id="request-report" className="page is-active">
              <p>
                Once generated, a CSV download link of this report will be
                emailed to {currentUser?.email}.
              </p>
            </div>
          )}

          {isLoading && (
            <div id="loader">
              <p>Loading...</p>
            </div>
          )}

          {isSuccess && (
            <div id="success">
              <p>
                A download link will be sent to your email once your report
                request has been completed. The link will expire one day after
                the email is received.
              </p>
            </div>
          )}

          {isError && (
            <div id="failed">
              <p>We couldn&#39;t complete your export. Please try again.</p>
            </div>
          )}

          <div className="modal-actions">
            <AlertDialog.Cancel asChild>
              <button
                className={`modal-action ${isError || isSuccess ? 'button' : 'modal-link'}`}
                type="button"
              >
                {isError || isSuccess ? 'Close' : 'Cancel'}
              </button>
            </AlertDialog.Cancel>

            {!(isError || isSuccess) && (
              <button
                className="modal-action button"
                onClick={handleExport}
                type="button"
              >
                Export
              </button>
            )}
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default ExportModal;
