import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import './styles.scss';

interface ColumnHeader {
  sortKey?: string;
}

const ActionHeader = ({ sortKey }: ColumnHeader) => {
  const { reactDashboardBulkActionsSelectAll } = useFlags();

  return (
    <th
      key={sortKey}
      className={`header-label`}
    >
      <div
        className="d-inline-flex flex-items-center"
        style={{ gap: '0.125rem' }}
      >
        {/* {
          reactDashboardBulkActionsSelectAll &&
          label === 'Bulk Action' && <BulkActionIcon />} */}
      </div>
    </th>
  );
};

export default ActionHeader;