import React from 'react';

import type { CellComponent } from '@/features/RidesTable/types';

import Cell from '@/features/RidesTable/components/Table/Cell';
import RideStatusBadge from '@/features/RideStatusBadge';
import { RideStatusBadgeText } from '@/features/RideStatusBadge/types';

import { useStatusBadge } from './useStatusBadge';

const RideStatusBadgeCell: CellComponent = ({ ride }) => {
  const normalizedName = ride.riderFullName.split(' ').join('-').toLowerCase();

  const { text, style, subtext, Icon, SubIcon, isInPpBiddingWindow } =
    useStatusBadge(ride);

  const isProcessing = text === RideStatusBadgeText.PROCESSING;

  return (
    <Cell
      label="Status"
      tdClass="time-remaining -overflow"
      href={`/dispatcher/ride/${ride.id}`}
      anchorClass="py-8"
    >
      <RideStatusBadge.Root color={style}>
        {isProcessing && (
          <div className="loader-spin" data-testid="loader-spin" />
        )}

        <RideStatusBadge.Content id={`${normalizedName}-bid-status-message`}>
          {Icon && <Icon />}
          {text}
        </RideStatusBadge.Content>

        {isInPpBiddingWindow && (
          <RideStatusBadge.Tooltip>
            <p>
              You can claim this trip prior to others in the area for a set
              period of time
            </p>
          </RideStatusBadge.Tooltip>
        )}

        {subtext && (
          <RideStatusBadge.Subtext>
            {SubIcon && <SubIcon />} {subtext}
          </RideStatusBadge.Subtext>
        )}
      </RideStatusBadge.Root>
    </Cell>
  );
};

export default RideStatusBadgeCell;
