/* eslint-disable import/prefer-default-export */

export const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const shortWeekdays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

export const getDate = (date: string | Date = new Date()) => (new Date(date)).getDate();
export const getDay = (date: string | Date = new Date()) => (new Date(date)).getDay();
export const getDayNameByNumber = (day: number) => weekdays[day];
export const getDayNameByDate = (date: string | Date = new Date()) => (
  getDayNameByNumber((new Date(date)).getDay())
);
export const getShortDayNameByNumber = (day: number) => shortWeekdays[day];
export const getShortDayNameByDate = (date: string | Date = new Date()) => (
  getShortDayNameByNumber((new Date(date)).getDay())
);

export const getShortMonth = (date: string | Date = new Date()) => (new Date(date)).toLocaleDateString('en', { month: 'short' });

export const getTimeHHMM = (date: string | Date = new Date()) => (new Date(date)).toLocaleString('en-US', {
  hour: 'numeric',
  hour12: true,
  minute: 'numeric',
});

export const getShortTz = (date: string | Date = new Date()) => (new Date(date)).toLocaleString('en', { timeZoneName: 'short' })
  .split(' ')
  .pop() as string;
