/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// location-adder.coffee
//= ==============================================================================

new Component('location-adder', ['locations', 'empty', 'fields', 'hfields',
  'overlay', 'open', 'close', 'add', 'edit', 'update', 'remove', 'output',
  'template'], ((c) => {
  // Config
  const template = Handlebars.compile($(c.template).html());
  let location_id = parseInt($(c.this).data('id'));

  // # Enable/Disable the button depending on if locations are present
  const location_adder_button_set_state = function () {
    if ($('.-no-locations-message').hasClass('is-active')) {
      return $('.common-location-save-button').attr('disabled', true);
    }
    return $('.common-location-save-button').attr('disabled', false);
  };

  location_adder_button_set_state();

  const sortLocations = function () {
    const sortedLocations = $(c.locations).sort((location1, location2) => {
      if ($(location1).find('.location-name > span').html() < $(location2).find('.location-name > span').html()) {
        return -1;
      }
      return 1;
    });

    return sortedLocations.each((i, item) => $("[data-location-adder-target='locations']").append(item));
  };

  sortLocations();

  const scope = $(c.this).attr('data-scope');
  $('.dropdown.venue-zones').attr('required', false);
  const insertLocationAtDesiredPlace = function (locationID, locationName) {
    const locationComponent = new CommonLocationComponent();
    return locationComponent.insertLocation({
      nameSelector: '.location-name > span',
      selector: $("[data-location-adder-target='location']"),
    }, {
      location: $(`#location-${locationID}`),
      name: locationName,
    });
  };

  const replaceLocationId = function (type, id) {
    const selector = `data-google-autocomplete-${type}`;
    // Convert all _ to - for the ID selector
    const id_selector = `#${scope}_locations_attributes_${id}_${type.replace(/\-/g, '_')}`;
    // Replace all the location ID's
    $(c.fields).find(`[${selector}]`).each(function () {
      return $(this).attr(selector, $(this).attr(selector).replace(/\-\d+/g, `-${id}`));
    });
    return $(id_selector).attr('data-google-autocomplete-target', $(id_selector).attr('data-google-autocomplete-target').replace(/\-\d+/g, `-${id}`));
  };

  // Clear modal fields
  const clear_modal_fields = function () {
    $(c.overlay).find(c.fields).find('.field.-error').removeClass('-error');
    $(c.overlay).find(c.fields).find('input').attr('checked', false);
    $(c.overlay).find(c.fields).find("input:not([type='radio']):not([type='checkbox'])").val('');
    return $(c.overlay).find(c.fields).find('textarea').val('');
  };

  // Open modal (set modal to state 1), and clear fields
  $(c.open).on('click', (e) => {
    e.preventDefault();
    $(c.this).removeClass('state-2').addClass('state-1');
    clear_modal_fields();
    $(c.overlay).addClass('is-active');

    // Add an incrementing index to the names/ids of modal fields (and labels).
    location_id += 1;
    $(c.fields).find('[for]').each(function () {
      return $(this).attr('for', $(this).attr('for').replace(/\_\d+\_/g, `_${location_id}_`));
    });
    $(c.fields).find('[name]').each(function () {
      return $(this).attr('name', $(this).attr('name').replace(/\[\d+\]/g, `[${location_id}]`));
    });
    $(c.fields).find('[id]').each(function () {
      return $(this).attr('id', $(this).attr('id').replace(/\_\d+\_/g, `_${location_id}_`));
    });
    $('.modal-overlay.is-active').find('[data-component="google-maps"]').find('.map').attr('id')
      .replace(/\_\d+\_/g, `_${location_id}_`);

    replaceLocationId('street-address', location_id);
    replaceLocationId('city', location_id);
    replaceLocationId('state', location_id);
    replaceLocationId('zipcode', location_id);
    replaceLocationId('latitude', location_id);
    replaceLocationId('longitude', location_id);

    return $('body').trigger('locationAdderOpened', [location_id]);
  });

  // Click close icon to close modal
  $(c.close).on('click', (e) => {
    e.preventDefault();
    $('body').trigger('locationModalClosed');
    $(c.overlay).removeClass('is-active');
    return location_id -= 1;
  });

  // Click overlay to close modal
  $(c.overlay).on('click', (e) => {
    clear_modal_fields();
    $('body').trigger('locationModalClosed');
    $(c.overlay).removeClass('is-active');
    return location_id -= 1;
  });

  // Prevent modal close by clicking within modal
  $(c.this).on('click', (e) => e.stopPropagation());

  // Add a new location. Copy modal fields into template and append to output
  // container.
  $(c.add).on('click', (e) => {
    e.preventDefault();
    // If primary location was checked, uncheck all other primary location
    // checkboxes.
    if ($(c.fields).find('.primary-location').is(':checked')) {
      $('.location .primary-location').attr('checked', false);
    }

    // Clone modal input values and set up template parameters
    const $location_fields = $(c.fields).clone(true, true);
    const location_select = `#${scope}_locations_attributes_${location_id}`;
    let location_name = $(c.fields).find(`${location_select}_name`).val();
    const location_longitude = $(c.fields).find(`${location_select}_longitude`).val();
    const location_latitude = $(c.fields).find(`${location_select}_latitude`).val();
    let location_addr1 = $(c.fields).find(`${location_select}_street_address`).val();

    // Don't display addr1 if it's the same as location name
    if (location_name.toLowerCase() === location_addr1.toLowerCase()) {
      location_addr1 = '';
    }

    const location_addr2 = `${$(c.fields).find(`${location_select}_city`).val()}, ${
      $(c.fields).find(`${location_select}_state`).val()} ${
      $(c.fields).find(`${location_select}_zipcode`).val()}`;
    const $zonesBlock = $('.modal-overlay.is-active .venue-zones-block');
    const $zonesSelect = $zonesBlock.find('.venue-zones');
    const $venueMappingId = $('.modal-overlay.is-active').find('.venue-mapping-id');
    const venue_mapping_id = $venueMappingId.val();
    const $venue_name = $zonesSelect.find('option:selected');
    const venue_name_html = $venue_name.val() > 0 ? $venue_name.html() : false;
    if (venue_name_html) { location_name = `${location_name} - ${venue_name_html}`; }

    // Render new location element, append cloned fields, and clean up data
    // attributes. Needed to manually remove data-location-adder-target to
    // prevent default selectors from targeting dynamically added elements.

    const html = template({
      address1: location_addr1, address2: location_addr2, latitude: location_latitude, longitude: location_longitude, name: location_name,
    });
    $(c.output).append(html);
    const $new_location = $(c.output).find(c.locations).last();
    $new_location.attr('id', `location-${location_id}`);
    $new_location.find(c.hfields).append($location_fields);
    $new_location.find(c.fields).removeAttr('data-location-adder-target');

    insertLocationAtDesiredPlace(location_id, location_name);

    $(c.empty).removeClass('is-active');
    $('body').trigger('locationModalClosed');
    // Close modal
    $(c.overlay).removeClass('is-active');
    // Clear modal fields
    clear_modal_fields();
    // Enable save if location is added
    return location_adder_button_set_state();
  });

  // Redisplay empty message if all locations get removed
  $(document).on('click', c.remove, function (e) {
    e.preventDefault();
    $(this).parents(c.locations).hide();
    $(this).parents(c.locations).find('.destroy').val(true);
    if ($(`${c.locations}:visible`).length === 0) {
      return $(c.empty).addClass('is-active');
    }
  });

  // Edit an existing location. Copy modal fields from location element to
  // modal and set modal to state 2.
  $(document).on('click', c.edit, function (e) {
    e.preventDefault();

    // Copy location fields over to modal
    const $location = $(this).parents(c.locations).first();
    const $fields = $location.find(c.hfields).find('div').first();
    $(c.fields).html('').append($fields.clone(true, true));

    // Set modal to state 2 and open
    $(c.this).removeClass('state-1').addClass('state-2');
    $(c.overlay).addClass('is-active');

    const locationId = $location.attr('id').match(/\w+-(\d+)/)[1];
    // fire event

    const $google_map_canvas = $('.modal-overlay.is-active').find('[data-component="google-maps"]');
    const $google_map = $google_map_canvas.find('.map');
    const updatedId = $google_map.attr('id').replace('add', 'update');
    $google_map.attr('id', updatedId);
    const location_select = `#${scope}_locations_attributes_${locationId}`;
    const longitude = `${location_select}_longitude`;
    const latitude = `${location_select}_latitude`;
    $(document).trigger('google-autocomplete-place-changed-trigger-map', {
      lat: $(latitude).val(),
      lng: $(longitude).val(),
    });
    $(document).trigger('locationUpdaterOpened', [locationId]);
  });

  // Update an existing location. Copy modal fields back into associated
  // location element.
  return $(c.update).on('click', (e) => {
    let location_address;
    e.preventDefault();

    // Get location id
    location_id = parseInt($(c.fields).find('[for]')
      .first()
      .attr('for')
      .match(/\_\d+\_/)[0]
      .replace(/\_/g, ''));

    // Find corresponding location element
    const $location = $(`#location-${location_id}`);

    // If primary location was checked, uncheck all other primary location
    // checkboxes.
    if ($(c.fields).find('.primary-location').is(':checked')) {
      $('.location .primary-location').attr('checked', false);
    }

    // Copy modal fields into location
    const cloned = $location.find(c.hfields).html('').append($(c.fields).clone(true, true));
    $location.find(c.hfields).find(c.fields).removeAttr('data-location-adder-target');

    // Update location display
    const location_select = `#${scope}_locations_attributes_${location_id}`;
    let location_name = $(`${location_select}_name`).val();
    const location_addr1 = $(`${location_select}_street_address`).val();
    const location_addr2 = `${$(`${location_select}_city`).val()}, ${
      $(`${location_select}_state`).val()} ${
      $(`${location_select}_zipcode`).val()}`;

    // Only display location_addr1 if different from location_name
    if (location_name.toLowerCase() === location_addr1.toLowerCase()) {
      location_address = location_addr2;
    } else {
      location_address = `${location_addr1}<br>${location_addr2}`;
    }

    const $zonesBlock = $('.modal-overlay.is-active .venue-zones-block');
    const $zonesSelect = $zonesBlock.find('.venue-zones');
    const $venueMappingId = $('.modal-overlay.is-active').find('.venue-mapping-id');
    const venue_mapping_id = $venueMappingId.val();
    const $venue_name = $zonesSelect.find('option:selected');
    const venue_name_html = $venue_name.val() > 0 ? $venue_name.html() : false;
    if (venue_name_html) { location_name = `${location_name} - ${venue_name_html}`; }

    $location.find('.location-name span').html(location_name);
    $location.find('.location-address').html(location_address);

    $(cloned).find(`${location_select}_venue_mapping_id`).val(venue_mapping_id);

    insertLocationAtDesiredPlace(location_id, location_name);

    // Clear and close the modal
    clear_modal_fields();
    return $(c.overlay).removeClass('is-active');
  });
}));
