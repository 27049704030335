/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// section.coffee
//= ==============================================================================

// Hide the section if no child is present.
new Component('section', ['hider'], ((c) => {
  if ($(c.this).find(`${c.hider}:visible`).length === 0) {
    return $(c.this).hide();
  }
}));
