import React from 'react';

import type { StreamableRide } from '@/types';

import TimeCell from './TimeCell';

const BookTimeCell = ({ ride }: { ride: StreamableRide }) => (
  <TimeCell time={ride.createdAt} id={ride.id} />
);

export default BookTimeCell;
