/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// email_validator.coffee
//= ==============================================================================

new Component('email-validator', ['button'], (c) => $(c.this).on('input change blur', function (e) {
  const keyCode = e.keyCode || e.which;
  if (keyCode === 13) {
    e.preventDefault();
    $(c.button).click();
    return false;
  }
  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
  if (emailRegex.test($(this).val())) {
    return $(c.button).attr('disabled', false);
  } return $(c.button).attr('disabled', true);
}));
