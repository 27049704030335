/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

const initETA = () => {
  $('#transportation_company_id').on('change', function () {
    return $('.eta-field')[$(this).val() === '0' ? 'addClass' : 'removeClass']('-hidden');
  });

  return $('#transportation_company_id').trigger('change');
};

// Synchronize certain duplicated fields.
const syncFields = () => ['[data-activator-target="auth-activatable"] textarea'].forEach((selector) => $(selector).on('change', function () {
  const fieldValue = $(this).val();
  return $(selector).val(fieldValue);
}));

document.addEventListener('turbolinks:load', () => {
  if ($('#ride-info')) { initETA(); }

  return syncFields();
});
