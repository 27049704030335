import React from 'react';

import Container from '@/components/dispatcher/Container';
import Scheduled from '@/pages/dispatcher/Scheduled';

// TODO: Remove file with enable-react-router removal
const RailsScheduledPage = () => {
  return (
    <Container>
      <Scheduled />
    </Container>
  );
};

export default RailsScheduledPage;
