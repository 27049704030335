/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// generate-report.coffee
//= ==============================================================================

new Component('generate-report', ['overlay', 'token'], ((c) => {
  const authToken = $(c.this).data('authToken');
  const startDate = $(c.this).data('startDate');
  const endDate = $(c.this).data('endDate');
  const url = $(c.this).data('url');
  const headers = { Authorization: `Token token=${authToken}` };

  const generateReport = function () {
    $('#request-report').removeClass('is-active');
    $('#loader').addClass('is-active');
    return $.ajax({
      data: {
        end_date: endDate,
        start_date: startDate,
      },
      dataType: 'json',
      error(xhr, status, e) {
        $('#loader').removeClass('is-active');
        return $('#failed').addClass('is-active');
      },
      headers,
      success(response, status) {
        $('#loader').removeClass('is-active');
        return $('#success').addClass('is-active');
      },
      type: 'POST',
      url,
    });
  };

  return $(c.this).on('click', (e, params) => {
    if (!params) {
      e.preventDefault();
      generateReport();
    }

    // Reset Modal State
    return $("[data-modal-target='generate-report-close']").on('click', (e) => {
      $('#request-report').addClass('is-active');
      $('#loader').removeClass('is-active');
      $('#failed').removeClass('is-active');
      return $('#success').removeClass('is-active');
    });
  });
}));
