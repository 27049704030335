/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// mask.coffee
//= ==============================================================================

// Masks inputs based on type of mask specified (using jQuery masks).
new Component('mask', [], ((c) => {
  switch ($(c.this).attr('data-mask-type')) {
    case 'date':
      return $(c.this).mask('00/00/0000');
    case 'ccn':
      return $(c.this).mask('0000 0000 0000 0000');
    case 'exp':
      return $(c.this).mask('00/00');
    case 'phone':
      return $(c.this).mask('000-000-0000');
    case 'ext':
      return $(c.this).mask('0000');
    case 'integer':
      return $(c.this).mask('0#');
    case 'decimal':
      return $(c.this).mask('000,000,000,000,000.00', { reverse: true });
    case 'card_expiry':
      return $(c.this).mask('00/00');
  }
}));
