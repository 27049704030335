const reactCommunityRidesTable = () =>
  document.getElementById('dispatcher-community-rides-table-react');

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).on('turbolinks:load', () => {
  if (reactCommunityRidesTable()) {
    return;
  }

  let identifier;
  if ($('table[data-dashboard]').length > 0) {
    const disable_socket_element = document.querySelector(
      '#disable-web-sockets',
    );

    if (
      disable_socket_element &&
      disable_socket_element.dataset &&
      disable_socket_element.dataset.disable === 'true'
    ) {
      return;
    }

    const path = window.location.pathname.split('/');

    const user_role_element = document.querySelector('#current-user-role');
    const scope =
      user_role_element && user_role_element.dataset
        ? user_role_element.dataset.role
        : undefined;

    identifier = { channel: 'DashboardChannel', scope };

    // This ensures that new subscription is not created with same identifier
    if (App.cable.subscriptions.findAll(JSON.stringify(identifier)).length) {
      return;
    }

    return (App.dashboard = App.cable.subscriptions.create(identifier, {
      connected() {},
      // Called when the subscription is ready for use on the server
      disconnected() {},
      // Called when the subscription has been terminated by the server

      received(data) {
        if (reactCommunityRidesTable()) {
          return;
        }

        let cutoffTime;
        if ($('[data-controller="trips"]').length) {
          const activeSubscriptions = App.cable.subscriptions.findAll(
            App.dashboard.identifier,
          );
          activeSubscriptions.map((sub) => sub.unsubscribe());
          App.dashboard = null;
        }

        const _getURLParameter = (name) =>
          decodeURIComponent(
            (new RegExp(`[?|&]${name}=` + '([^&;]+?)(&|#|;|$)').exec(
              window.location.search,
            ) || [null, ''])[1].replace(/\+/g, '%20'),
          ) || null;

        const updateVehicleImage = function (data, $ride) {
          if (
            !['queued', 'completed', 'canceled'].includes(data.current_status)
          ) {
            return $('.vehicle-image', $ride).html('');
          }
          if (data.vehicle_image != null) {
            if (data.vehicle_image != null) {
              return $('.vehicle-image', $ride).html(data.vehicle_image);
            }
          }
        };

        const $row = $(`table tr[data-ride-id='${data.ride_id}']`);
        const $status = $('.status', $row);
        const $processingLoader = $('.loader-spin', $row);
        const $statusIcon = $('.status-icon', $row);
        const declined = $status.data()?.declined;
        const $biddingHelper = new BiddingHelper();
        const $preferredProviderTooltip = $(
          '.preferred-provider-tooltip',
          $row,
        );
        const $ppTimer = $('.countdown', $row);
        const $ppTimerText = $('.countdown-text', $row);

        // Remove completed and canceled rides, show them otherwise
        if (['completed', 'canceled'].includes(data.current_status)) {
          $row.toggle('slide', () =>
            $('[data-patients-table]').trigger('recount_rows'),
          );
        } else {
          $row.show(() => $('[data-patients-table]').trigger('recount_rows'));
        }

        // Set ride status class
        $row.attr('class', data.current_status_class);
        $status.attr('class', `status relative ${data.current_status_class}`);

        // Show the timeliness for assigned rides, otherwise show the current status
        if (data.current_status === 'claimed' && !data.customized_dispatch) {
          $('.status-message', $status).html(data.timeliness);
        } else {
          $('.status-message', $status).html(data.humanized_current_status);
        }

        if (data.tooltip_info != null) {
          $('.show_icon', $row).removeClass('-hidden');
          $('.tooltip_text', $row).html(data.tooltip_info);
        } else {
          $('.show_icon', $row).addClass('-hidden');
        }

        // Stops the previous timer if in progress
        //
        // @return {void}
        const clearRunningTimer = function () {
          const intervalData = $('#status-data', $row).data();
          const intervalId = intervalData?.interval_id;
          if (intervalId != null) {
            clearInterval(intervalId);
          }
          if ($preferredProviderTooltip?.is(':visible')) {
            $preferredProviderTooltip.hide();
          }
          if ($ppTimer?.is(':visible')) {
            return $ppTimer.hide();
          }
        };

        // Sets the status message and do some UI changes
        // in `Status` column on the dispatcher dashboard page
        // Also, display message for tooltip timer
        //
        // @param {String} str
        // @return {void}
        const setDisplay = function (str) {
          if (str === 'Processing') {
            $('.status-message', $row).html(str);
            $status.addClass('-processing');
            if ($processingLoader.is(':hidden')) {
              $processingLoader.show();
            }
          } else {
            $status.removeClass('-processing');
            if ($processingLoader.is(':visible')) {
              $processingLoader.hide();
            }
          }

          if (str === 'Preferred') {
            $('.status-message', $row).html(str);
            $status.addClass('-aqua tooltip');
            if ($preferredProviderTooltip?.is(':hidden')) {
              $preferredProviderTooltip.show();
            }
            if ($ppTimer?.is(':hidden')) {
              return $ppTimer.show();
            }
          } else {
            $status.removeClass('-aqua tooltip');
            if ($preferredProviderTooltip?.is(':visible')) {
              $preferredProviderTooltip.hide();
            }
            if ($ppTimer?.is(':visible')) {
              return $ppTimer.hide();
            }
          }
        };

        // resets the timer
        //
        // @param {Time} cutoffTime
        // @param {Integer} interval (milliseconds)
        // @param {Boolean} biddingTimer (is timer shown on community dashboard)
        // @return {void}
        const timer = function (cutoffTime, interval, biddingTimer) {
          const $tooltip_time_display = $('.tooltip_time_display', $row);
          const isInPpCommunity = $('.tooltip_timer', $row).data(
            'isInPpCommunity',
          );
          let timeDisplayInterval = null;

          const updateTooltipInfo = function (timeToCutoff) {
            let display;
            const minutes = Math.max(1, Math.round(timeToCutoff / 60));
            if (isInPpCommunity) {
              display = `${minutes} mins left for preferred`;
            } else {
              display = `${minutes} mins left`;
            }
            return $tooltip_time_display.html(display);
          };

          const updateBiddingTimerInfo = function (timeToCutoff) {
            const display = `${Math.floor(timeToCutoff / 60)} MIN ${timeToCutoff % 60} S`;
            $('.status-message', $row).html(display);
            return $('#status-data', $row).data(
              'interval_id',
              timeDisplayInterval,
            );
          };

          const run = function () {
            if (moment().add(1, 's').isAfter(cutoffTime)) {
              if (biddingTimer) {
                setDisplay('Processing');
              } else {
                $tooltip_time_display.html('');
              }
              return clearInterval(timeDisplayInterval);
            }
            const timeToCutoff = cutoffTime.diff(moment(), 's');
            if (biddingTimer) {
              return updateBiddingTimerInfo(timeToCutoff);
            }
            return updateTooltipInfo(timeToCutoff);
          };

          run();
          return (timeDisplayInterval = setInterval(run, interval));
        };

        // TODO: Rename to generic name when general community timer is also moved
        // under status badge
        //
        // Display timer under the status badge on the dispatcher community page
        //
        // @param {Time} cutoffTime
        // @return {void}
        const setPpTimer = function (cutoffTime) {
          let timeDisplayInterval = null;
          const TIME_INTERVAL = 1000;

          const run = function () {
            const timeLeft = $biddingHelper.remainingTimeCalculator(cutoffTime);
            if (timeLeft) {
              $ppTimerText.html(timeLeft);
              return $('#status-data', $row).data(
                'interval_id',
                timeDisplayInterval,
              );
            }
            setDisplay('Processing');
            return clearInterval(timeDisplayInterval);
          };

          run();
          return (timeDisplayInterval = setInterval(run, TIME_INTERVAL));
        };

        if (data.humanized_current_status === 'Checking Availability') {
          const tooltip_timer = document.createElement('div');
          tooltip_timer.className = 'tooltip_timer';
          const tooltip_time_display = document.createElement('div');
          tooltip_time_display.className = 'tooltip_time_display';
          tooltip_timer.appendChild(tooltip_time_display);
          $('.tooltip_text', $row).append(tooltip_timer);
          const countdownLength = data.ride_bidding_window;
          cutoffTime = moment
            .utc(data.bidding_countdown_starts_at)
            .add(countdownLength, 's');
          timer(cutoffTime, 60 * 1000, false);
        }

        const currentStatus = $row.data('status');
        if (currentStatus !== data.current_status) {
          $row.data('status', data.status);
        }

        // Update the eta if the bid is set
        const currentETA = $row.data('pickup-time');
        if (currentETA !== data.eta) {
          $row.data('pickup-time', data.new_eta);
        }

        if (data.tc_name != null) {
          $('.transportation_company', $row).html(data.tc_name);
        }
        if (data.tc_phone != null) {
          $('.transportation-company-phone', $row).html(data.tc_phone);
        }
        if (data.vehicle != null) {
          $('.vehicle-make-model', $row).html(data.vehicle);
        }
        if (data.license != null) {
          $('.vehicle-license', $row).html(data.license);
        }
        if (data.driver != null) {
          $('.driver', $row).html(data.driver);
        }
        if (data.book_time != null) {
          $('.book-time', $row).html(data.book_time);
        }
        if (data.pickup_time != null) {
          $('.pickup-time', $row).html(data.pickup_time);
        }
        $status.removeClass('-processing');
        if ($processingLoader?.is(':visible')) {
          $processingLoader.hide();
        }
        if ($statusIcon?.is(':visible')) {
          $statusIcon.hide();
        }

        // Update Status message.
        if (data.ride_share_status != null) {
          $('.status-message', $row).html(data.ride_share_status);
        } else if (declined && data.humanized_current_status !== 'Assigned') {
          $('.status-message', $row).html('Declined');
        } else if (
          data.within_bidding_window &&
          data.humanized_current_status === 'Available'
        ) {
          clearRunningTimer();
          cutoffTime = moment
            .utc(data.bidding_countdown_starts_at)
            .add(data.ride_bidding_window, 's');
          if (data.pp_community) {
            setDisplay('Preferred');
            setPpTimer(cutoffTime);
          } else {
            timer(cutoffTime, 1000, true);
          }
        } else if (data.humanized_current_status != null) {
          clearRunningTimer();
          $('.status-message', $row).html(data.humanized_current_status);
        }

        // Update ETA pickup time.
        if (data.eta_pickup_time) {
          if ($('[data-controller="dispatcher"]').length > 0) {
            $('.pickup-time span:last-of-type', $row).html(
              data.eta_pickup_time,
            );
          } else {
            $('.pickup-time span', $row).html(data.eta_pickup_time);
            $('.ride_time_column span:last-of-type', $row).html(
              data.eta_pickup_time,
            );
          }
        }

        // Update vehicle image
        if (data.vehicle_image != null && $row != null) {
          updateVehicleImage(data, $row);
        }
      },
    }));
  }
  if (App.dashboard != null) {
    const activeSubscriptions = App.cable.subscriptions.findAll(
      App.dashboard.identifier,
    );
    activeSubscriptions.map((sub) => sub.unsubscribe());
    return (App.dashboard = null);
  }
});
