import React from 'react';

import type { Location } from '@/types';

import Cell from '@/features/RidesTable/components/Table/Cell';
import convertToStateCode from '@/utils/convertToStateCode';

const LocationCell: React.FC<{
  href: string;
  label: string;
  location: Location;
}> = ({ location, href, label }) => {
  return (
    <Cell label={label} tdClass="pickup-location" href={href}>
      {/* Note: Usually streetAddress and location name will equal each other but in the event that they do not, display both */}

      {location.name && location.streetAddress !== location.name ? (
        <div className="t-line-clamp-2">{location.name}</div>
      ) : null}

      <span>{location.streetAddress}</span>
      <br />
      <span>
        {`${location.city}, `}

        {location.stateCode ? (
          <abbr title={location.state}>{location.stateCode}</abbr>
        ) : (
          <abbr title={location.state}>
            {convertToStateCode(location.state)}
          </abbr>
        )}

        {` ${location.zipcode}`}
      </span>
    </Cell>
  );
};

export default LocationCell;
