import {
  CheckIcon,
  Cross2Icon,
  DotIcon,
  MinusIcon,
} from '@radix-ui/react-icons';

export enum RideStatusBadgeText {
  ASSIGNED = 'Assigned',
  AVAILABLE = 'Available',
  CANCELED = 'Canceled',
  COMPLETED = 'Completed',
  DECLINED = 'Declined',
  IN_PROGRESS = 'In Progress',
  NEW_RIDE = 'New Ride',
  NOT_STARTED = 'Not Started',
  PREFERRED = 'Preferred',
  PROCESSING = 'Processing',
  RESPONDED = 'Responded',
  SUBMIT_ETA = 'Submit ETA',
}

export const BASE_CC_STATUS_BADGE_TEXT = {
  brokered: 'Brokered',
  callingTransports: 'Calling Transports',
  canceled: 'Canceled',
  checkingAvailability: 'Checking Availability',
  claimed: 'Claimed',
  completed: 'Completed',
  incomplete: 'Incomplete',
  inProgress: 'In Progress',
  notStarted: 'Not Started',
  onHold: 'On Hold',
  pickedUp: 'Picked Up',
} as const;

export type BaseCcStatusBadgeText =
  (typeof BASE_CC_STATUS_BADGE_TEXT)[keyof typeof BASE_CC_STATUS_BADGE_TEXT];

export const RIDE_SHARE_STATUS_BADGE_TEXT = {
  atDropoff: 'At Dropoff',
  atPickup: 'At Pickup',
  completed: 'Completed',
  findingDriver: 'Finding Driver',
  requested: 'Requested',
  toDestination: 'To Destination',
  toPickup: 'To Pickup',
} as const;

export type RideShareRideStatusBadgeText =
  (typeof RIDE_SHARE_STATUS_BADGE_TEXT)[keyof typeof RIDE_SHARE_STATUS_BADGE_TEXT];

export const CAD_RIDE_STATUS_BADGE_TEXT = {
  atDestination: 'At Destination',
  atDropoff: 'At Dropoff',
  atPickup: 'At Pickup',
  findingProviders: 'Finding Providers',
  toDestination: 'To Destination',
  toPickup: 'To Pickup',
} as const;

export type CadRideStatusBadgeText =
  (typeof CAD_RIDE_STATUS_BADGE_TEXT)[keyof typeof CAD_RIDE_STATUS_BADGE_TEXT];

export const CC_STATUS_BADGE_TEXT = {
  ...BASE_CC_STATUS_BADGE_TEXT,
  ...RIDE_SHARE_STATUS_BADGE_TEXT,
  ...CAD_RIDE_STATUS_BADGE_TEXT,
} as const;

export type CcRideStatusBadgeText =
  (typeof CC_STATUS_BADGE_TEXT)[keyof typeof CC_STATUS_BADGE_TEXT];

/**
 * Status values used for ride filtering on the CC assigned dashboard.
 */
export const CC_BASE_ASSIGNED_STATUSES = {
  brokered: 'brokered',
  canceled: 'canceled',
  checkingAvailability: 'checking_availability',
  claimed: 'claimed',
  completed: 'completed',
  incomplete: 'incomplete',
  inProgress: 'in_progress',
  notStarted: 'not_started',
  onHold: 'on_hold',
  pickedUp: 'picked_up',
} as const;

export const CC_CAD_ASSIGNED_STATUSES = {
  atDestination: 'at_destination',
  atDropoff: 'at_dropoff',
  atPickup: 'at_pickup',
  findingProviders: 'finding_providers',
  toDestination: 'to_destination',
  toPickup: 'to_pickup',
} as const;

export const CC_RIDE_SHARE_STATUSES = {
  atDropoff: 'at_dropoff',
  atPickup: 'at_pickup',
  completed: 'completed',
  findingDriver: 'finding_driver',
  requested: 'requested',
  toDestination: 'to_destination',
  toPickup: 'to_pickup',
} as const;

/**
 * Status values used for ride filtering on the CC assigned dashboard.
 */
export const CC_ASSIGNED_STATUSES = {
  ...CC_BASE_ASSIGNED_STATUSES,
  ...CC_CAD_ASSIGNED_STATUSES,
  ...CC_RIDE_SHARE_STATUSES,
} as const;

export type CcAssignedStatuses =
  (typeof CC_ASSIGNED_STATUSES)[keyof typeof CC_ASSIGNED_STATUSES];

export const DEFAULT_CC_STATUSES = Object.values(
  CC_ASSIGNED_STATUSES,
) as CcAssignedStatuses[];

export const COLORS = {
  deepRed: '-deep-red',
  green: '-green',
  grey: '-grey',
  orange: '-orange',
  newRide: '-yellow',
  preferred: '-aqua tooltip',
  processing: '-processing d-inline-flex',
  red: '-red',
  yellow: '-yellow',
} as const;

export type Colors = (typeof COLORS)[keyof typeof COLORS];

type DispatcherStatusBadgeStyles = {
  [key in RideStatusBadgeText]: Colors;
};

export const STYLES: DispatcherStatusBadgeStyles = {
  Assigned: COLORS.green,
  Available: COLORS.green,
  Canceled: COLORS.orange,
  Completed: COLORS.green,
  Declined: COLORS.deepRed,
  'In Progress': COLORS.green,
  'New Ride': COLORS.yellow,
  'Not Started': COLORS.grey,
  Preferred: COLORS.preferred,
  Processing: COLORS.processing,
  Responded: COLORS.grey,
  'Submit ETA': COLORS.orange,
} as const;

type DispatcherStatusBadgeIcons = {
  [key in RideStatusBadgeText]: typeof DotIcon | null;
};

export const ICONS: DispatcherStatusBadgeIcons = {
  Assigned: null,
  Available: DotIcon,
  Canceled: null,
  Completed: null,
  Declined: Cross2Icon,
  'In Progress': null,
  'New Ride': null,
  'Not Started': MinusIcon,
  Preferred: null,
  Processing: null,
  Responded: CheckIcon,
  'Submit ETA': null,
} as const;
