window.CommonLocationComponent = class CommonLocationComponent {
  insertLocation(locations, currentLocation) {
    const currentLocationName = currentLocation.name.toUpperCase();
    locations.selector.each(function () {
      const location = $(this);
      const locationName = $(this).find(`${locations.nameSelector}`).html();

      if (locationName) {
        if ((locationName.toUpperCase() > currentLocationName) && location.is(':visible')) {
          currentLocation.location.insertBefore(location);
          return false;
        }
      }
    });
    return true;
  }
};
