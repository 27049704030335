/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// auto_downloader.coffee
//= ==============================================================================

// Initiates the download.
new Component('auto-downloader', [], ((c) => {
  // Trigger report download on Page Load.
  const selector = $(c.this).data('selector');
  const downloadLink = $(selector);
  if (downloadLink[0]) {
    return downloadLink[0].click();
  }
}));
