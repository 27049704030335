/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// location-chooser.coffee
//= ==============================================================================

new Component('location-chooser', ['locations', 'overlay', 'open', 'close',
  'choose', 'filters'], ((c) => {
  // Open modal and remember which element opened it. Preselect a modal location
  // if one was previously selected. Disable a modal location if it is already
  // selected by another location selector.
  $(c.open).on('click', function (e) {
    e.preventDefault();
    c.memory.opened_with = $(this);

    // Clear and enable all previous modal selections and disable button
    $(c.locations).parent().find("[type='radio']").attr('checked', false);
    $(c.locations).parent().find("[type='radio']").attr('disabled', false);
    $(c.choose).attr('disabled', true);

    // Preselect modal location if this element has a selected location and also
    // preselect the corresponding tab.
    let location_id = $(this).find("[type='hidden']").val();
    const $location = $(c.overlay).find(`[value='${location_id}']`);
    $location.parents('.-radio').click();
    const location_type = $location.parents('.modal-state').attr('id');
    if (location_type === 'facilities') {
      $(c.filters).last().click();
    } else {
      $(c.filters).first().click();
    }

    // Disable modal location if another element has selected it
    $(c.open).each(function () {
      if ($(this).attr('data-type') !== c.memory.opened_with.attr('data-type')) {
        location_id = $(this).find("[type='hidden']").val();
        return $(c.overlay).find(`[value='${location_id}']`).parents('.-radio').find("[type='radio']")
          .attr('disabled', true);
      }
    });

    // Open modal
    return $(c.overlay).addClass('is-active');
  });

  // Close modal
  $(c.close).on('click', (e) => {
    e.preventDefault();
    $(document).trigger('location-modal-closed');
    $(c.overlay).removeClass('is-active');
    if ($('.indicator-circle.-left-spacing')) {
      return $('.indicator-circle.-left-spacing').html($(c.locations).length);
    }
  });

  // Click overlay to close modal
  $(c.overlay).on('click', (e) => {
    $(document).trigger('location-modal-closed');
    $(c.overlay).removeClass('is-active');
    if ($('.indicator-circle.-left-spacing')) {
      return $('.indicator-circle.-left-spacing').html($(c.locations).length);
    }
  });

  // Prevent modal close by clicking within modal
  $(c.this).on('click', (e) => e.stopPropagation());

  // Handle flipping between different states
  $(c.filters).on('click', function (e) {
    e.preventDefault();

    // Remove all state/active classes
    $(c.filters).removeClass('is-active');
    for (let i = 0, end = $(c.filters).length, asc = end >= 0; asc ? i < end : i > end; asc ? i++ : i--) {
      $(c.this).removeClass(`state-${i + 1}`);
    }

    // Add state to modal & active class to filter
    $(c.this).addClass(`state-${$(this).index(c.filters) + 1}`);
    return $(this).addClass('is-active');
  });

  // Re-enable button when a selection is made
  return $(c.this).on('click', '.-radio', function (e) {
    if (!window.getSelection().toString()) {
      if ($(this).find(c.locations).length && !$(this).find("[type='radio']:disabled").length) {
        const $selected_location = $("[name='location-chooser-choice']:checked");
        const $location_data = $selected_location.siblings(c.locations).first().clone(true, true);
        if ($location_data[0]) {
          c.memory.opened_with.find("[type='hidden']").val('1').blur();

          c.memory.opened_with.html($location_data);
          c.memory.opened_with.find("[name='location']").attr('name', c.memory.opened_with.attr('data-type'));
          if (c.memory.opened_with.data('type') === 'locations[stop_locations]') {
            c.memory.opened_with.find("[type='hidden']").prop('required', false);
            c.memory.opened_with.parent().show();
            c.memory.opened_with.show();
          }
          $(c.overlay).removeClass('is-active');

          $(document).trigger('location-updated', {
            direction: c.memory.opened_with.data('type').replace(/locations\[(\w+)_location\]/gi, '$1'),
            location: $location_data[0].innerText.trim().split(/\n/)[0],
          });

          // Notify the document that a change occurred
          return $(document).trigger('change');
        }
      }
    }
  });
}));
