/* eslint-disable import/prefer-default-export */
import type { RideFilterState } from '@/features/RidesTable/types';
import type { Entries, RideList } from '@/types';
import type { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel, toSnake } from '@/src/util/CaseConvert';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getRides: build.query({
      query: (params: RideFilterState) => {
        const args: Partial<RideFilterState> = {};

        (Object.entries(params) as Entries<typeof params>).forEach(
          ([key, value]) => {
            if (Array.isArray(value) && value.length <= 0) {
              return;
            }

            if (value === null) {
              return;
            }

            if (typeof value === 'string' && value === '') {
              return;
            }

            args[toSnake(key) as typeof key] = value as keyof typeof value;
          },
        );

        return {
          url: 'rides_list',
          params: args,
          method: 'GET',
          refetchOnMountOrArgChange: true,
        };
      },
      // https://redux-toolkit.js.org/rtk-query/api/createApi#refetchonmountorargchange
      // Can be a number? Maybe set it to 3-5 seconds?
      forceRefetch: () => true,
      transformResponse: (response: RideList, meta: FetchBaseQueryMeta) => {
        const rideList = keysToCamel(response) as RideList;
        rideList.pages = +(meta.response?.headers.get('Total-Pages') || 1);
        rideList.totalCount = +(meta.response?.headers.get('Total-Count') || 1);
        rideList.currentPage = +(
          meta.response?.headers.get('Current-Page') || 1
        );

        return rideList;
      },
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as Record<string, unknown>, // TODO: Type error response
    }),
  }),
});

export const { useGetRidesQuery, useLazyGetRidesQuery } = extendedApi;
