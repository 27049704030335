import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { pushToastNotification } from '@/store/toastNotificationsSlice';

const ErrorPanel: React.FC<{
  messages: { description: string; statusCode: string }[];
}> = ({ messages }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (messages?.[0]?.statusCode === 'invalid_role') {
      dispatch(
        pushToastNotification({
          text: messages[0]?.description,
          action: 'refresh',
        }),
      );
    }
  }, []);

  return (
    <div className="empty-state" id="no-rides-placeholder">
      <h2 className="empty-state-heading">An error has occurred.</h2>

      <button
        onClick={() => window.location.reload()}
        type="button"
        className="button -inverted-blue mt-2 -medium"
      >
        Refresh Page
      </button>
    </div>
  );
};

export default ErrorPanel;
