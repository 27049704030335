/* eslint-disable no-param-reassign */
import type { RootState } from '@/store';
import type { ExcludibleColumns } from '@/types';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export interface UserColumnPreferencesState {
  userPreference: {
    dashboardColumnPreferences: {
      admin?: {
        // Does not exist yet
        scheduled?: {
          columnsToExcludeFromRendering?: ExcludibleColumns | null | object;
        };
      };
      care_coordinator?: {
        scheduled?: {
          columnsToExcludeFromRendering?: ExcludibleColumns | null | object;
        };
      };
      dispatcher?: {
        assigned?: {
          columnsToExcludeFromRendering?: ExcludibleColumns | null | object;
        };
        community?: {
          columnsToExcludeFromRendering?: ExcludibleColumns | null | object;
        };
      };
    };
    id?: number;
  };
}

const initialState: UserColumnPreferencesState = {
  userPreference: {
    dashboardColumnPreferences: {
      dispatcher: {
        assigned: {},
        community: {},
      },
    },
  },
};
export interface CreateUserPreferencesParams {
  dashboardColumnPreferences: {
    dispatcher?: {
      assigned?: {
        columnsToExcludeFromRendering?: ExcludibleColumns | null | object;
      };
      community?: {
        columnsToExcludeFromRendering?: ExcludibleColumns | null | object;
      };
    };
  };
}

export interface UpdateUserPreferencesParams {
  userId: number;
  userPreference: {
    dashboardColumnPreferences: {
      dispatcher?: {
        assigned?: {
          columnsToExcludeFromRendering?: ExcludibleColumns | null | object;
        };
        community?: {
          columnsToExcludeFromRendering?: ExcludibleColumns | null | object;
        };
      };
    };
  };
}

const slice = createSlice({
  name: 'userColumnPreferenceFilters',
  initialState,
  reducers: {
    setDispatcherColumnPreferences: (
      state,
      action: PayloadAction<
        Partial<
          UserColumnPreferencesState['userPreference']['dashboardColumnPreferences']['dispatcher']
        >
      >,
    ) => {
      state.userPreference.dashboardColumnPreferences.dispatcher = {
        ...state.userPreference.dashboardColumnPreferences.dispatcher,
        ...action.payload,
      };
    },
  },
});

export const selectUserColumnPreferences = (state: RootState) =>
  state.userColumnPreferences.userPreference.dashboardColumnPreferences;

export const { setDispatcherColumnPreferences } = slice.actions;

export const userColumnPreferences = slice.reducer;
