import React from 'react';

import Container from '@/components/dispatcher/Container';
import Community from '@/pages/dispatcher/Community';

// TODO: Remove file with enable-react-router removal
const RailsCommunityPage = () => (
  <Container>
    <Community />
  </Container>
);

export default RailsCommunityPage;
