import React, { createContext, useContext } from 'react';

import type { Facet, StreamableRide } from '@/types';

interface TableCTX {
  hospitals: Facet[];
  removeRideById: (id: number) => void;
  updateRideById: (rideId: number, params: Partial<StreamableRide>) => void;
}

export const TableContext = createContext({} as TableCTX);

const TableProvider: React.FC<{ children: React.ReactNode; ctx: TableCTX }> = ({
  children,
  ctx,
}) => {
  return <TableContext.Provider value={ctx}>{children}</TableContext.Provider>;
};

export const useTableContext = () => {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider');
  }

  return context;
};

export default TableProvider;
