import React, { useEffect, useMemo, useState } from 'react';

import type { Entries, VehicleType } from '@/types';

import { ChevronDownIcon } from '@radix-ui/react-icons';

import * as Popover from '@/lib/@radix-ui/react-popover';
import { VEHICLE_TYPES } from '@/types';

import CheckboxItem from './CheckboxItem';

const VEHICLE_DISPLAY_NAMES = {
  als: 'ALS Vehicle',
  bls: 'BLS Vehicle',
  cct: 'CCT Vehicle',
  medicalSedan: 'Medical Sedan',
  psychTransport: 'Psych Transport',
  stretcherVan: 'Stretcher Van',
  taxi: 'Taxi',
  wheelchair: 'Wheelchair Van',
};

const vehicleList = Object.entries(VEHICLE_TYPES) as Entries<
  typeof VEHICLE_TYPES
>;
const filterLabel = 'Vehicle type';

const getVehicleDisplayText = (value: string) => {
  const lookupKey = (
    Object.keys(VEHICLE_TYPES) as (keyof typeof VEHICLE_TYPES)[]
  ).find((key) => VEHICLE_TYPES[key] === value);

  return VEHICLE_DISPLAY_NAMES[lookupKey as keyof typeof VEHICLE_TYPES];
};

const getFilterLabel = (vehicleTypes: VehicleType[]) => {
  if (vehicleList.length === vehicleTypes.length) {
    return filterLabel;
  }

  if (vehicleTypes.length === 1) {
    return `${filterLabel}: ${getVehicleDisplayText(vehicleTypes[0])}`;
  }

  return `${filterLabel}: Multiple`;
};

const VehicleTypes: React.FC<{
  setTypes: (types: VehicleType[]) => void;
  types: VehicleType[];
}> = ({ types, setTypes }) => {
  const [updatedVehicleTypes, setUpdatedVehicleType] = useState(types);
  const label = useMemo(() => getFilterLabel(types), [types]);

  useEffect(() => setUpdatedVehicleType(types), [types]);

  const handleClose = () =>
    types !== updatedVehicleTypes && setTypes(updatedVehicleTypes);

  const handleCheck = (vehicle: VehicleType) => {
    const vehiclePresent = updatedVehicleTypes.includes(vehicle);

    if (vehiclePresent) {
      setUpdatedVehicleType(
        updatedVehicleTypes.filter((vehicles) => vehicle !== vehicles),
      );
    } else {
      setUpdatedVehicleType([...updatedVehicleTypes, vehicle]);
    }
  };

  return (
    <div className="">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {label}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content"
            align="start"
            onCloseAutoFocus={handleClose}
          >
            {vehicleList.map(([key, value]) => {
              return (
                <CheckboxItem
                  key={key}
                  text={VEHICLE_DISPLAY_NAMES[key]}
                  checked={updatedVehicleTypes.includes(value)}
                  onCheckedChange={() => handleCheck(value)}
                />
              );
            })}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default VehicleTypes;
