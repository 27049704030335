/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFlashMesssage } from '../types';

interface FlashMessagesState {
  messages: IFlashMesssage[];
}

const initState: FlashMessagesState = {
  messages: [],
};

const flashMessagesSlice = createSlice({
  initialState: initState,
  name: 'flashMessages',
  reducers: {
    popFlashMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message) => message.text !== action.payload.text,
      );
    },
    pushFlashMessage: (state, action: PayloadAction<IFlashMesssage>) => {
      state.messages = [...state.messages, action.payload];
    },
  },
});

export const { pushFlashMessage, popFlashMessage } = flashMessagesSlice.actions;
export default flashMessagesSlice.reducer;
