/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
new Component(
  'plan-manager',
  [
    'auth-token',
    'searchbar',
    'plan-template',
    'plan-remove-buttons',
    'search-results',
    'search-result-template',
    'no-plans',
    'selected-plans',
  ],
  ((c) => {
    // Compile Handlebars templates.
    const planTemplate = Handlebars.compile($(c['plan-template']).html());
    const searchResultTemplate = Handlebars.compile($(c['search-result-template']).html());

    // Fetch auth token and set up request headers.
    const authToken = $(c['auth-token']).val();
    const headers = { Authorization: `Token token=${authToken}` };

    // Hits API endpoint for plan search with a given term and populates search
    // results area.
    //
    // @param {String} term
    // @return {void}
    const search = function (term) {
      if (term.length < 3) { return; }

      // Remove previous search results and make results area active.
      $(c['search-results']).find('.search-result').remove();
      $(c['search-results']).addClass('is-active');

      // Hit the API to search for plans.
      return $.ajax({
        data: { q: term },
        dataType: 'json',
        headers,
        success(data, status) {
          const plans = data.data;
          return plans.forEach((plan) => $(c['search-results']).append(
            searchResultTemplate(
              {
                carrier: plan.carrier,
                id: plan.id,
                name: plan.name,
              },
            ),
          ));
        },
        type: 'GET',
        url: '/api/v1/plans/search',
      });
    };

    // Attach search functionality to searchbar.
    let defer = null;
    $(document).on('click', (e) => {
      clearTimeout(defer);
      return $(c['search-results']).removeClass('is-active');
    });
    $(c.searchbar).on('keydown', (e) => clearTimeout(defer));
    $(c.searchbar).on('keyup click', (e) => {
      e.stopPropagation();
      return defer = setTimeout(
        () => search(e.target.value),
        700,
      );
    });

    // Allow addition of plans.
    $(document).on('click', `${c.selector} .search-result`, function (e) {
      const planID = $(this).find('[type="hidden"]').val();
      const planName = $(this).find('.search-result-name').text();
      const carrierName = $(this).find('[data-carrier-name]').data('carrier-name');

      // Add but prevent duplicates.
      if ($(c['selected-plans']).find(`input[value='${planID}']`).length === 0) {
        $(`${c['selected-plans']} tbody`).append(
          planTemplate({ carrier: carrierName, id: planID, name: planName }),
        );
      }

      // Show selected plans area.
      $(c['selected-plans']).addClass('is-active');
      return $(c['no-plans']).removeClass('is-active');
    });

    // Allow removal of selected plans.
    return $(document).on('click', c['plan-remove-buttons'], function (e) {
      $(this).parents('tr').first().remove();

      // Show no plans content if we remove the last plan.
      if ($(`${c['selected-plans']} tbody tr`).length === 0) {
        $(c['selected-plans']).removeClass('is-active');
        return $(c['no-plans']).addClass('is-active');
      }
    });
  }),
);
