export const CC_SCHEDULED_PATH = '/trips/scheduled';
export const SCHEDULED_PATH = '/dispatcher/scheduled';
export const COMMUNITY_PATH = '/dispatcher/community';

export const isCommunity = () => window.location.pathname === COMMUNITY_PATH;
export const isScheduled = () => window.location.pathname === SCHEDULED_PATH;
export const isCcScheduled = () =>
  window.location.pathname === CC_SCHEDULED_PATH;

/**
 * This is only necessary for React Router/Turbo integration.
 *
 * Links that exist _outside_ of the Router (files in `rails_components`),
 * but route from one React page to another, must navigate with both
 * Turbo and the React Router. This is NOT necessary for `Link` components
 * that exist within the Router.
 *
 * Turbo hotswaps the body of a page without rerunning existing JS.
 * When both pages render `App`, the React Router is not aware of that
 * change and will continue to display the old page.
 *
 * This can be removed after moving away from Turbo.
 */
export const routes = [COMMUNITY_PATH, SCHEDULED_PATH, CC_SCHEDULED_PATH];
