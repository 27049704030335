/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// timepicker.coffee
//= ==============================================================================

// Handles timepicker toggles
new Component('timepicker', ['wrapper', 'checkable'], ((c) => {
  // Need some data for referencing instance
  let options = null;
  let dateInstance = null;
  const initialize = function () {
    if ($(c.this).data('timepicker-type') !== undefined) {
      const interval = parseInt($(c.this).data('interval')) || 30;

      // Activate pickatime plugin.
      options = {
        clear: '',
        format: 'h:i A',
        interval,
      };

      if ($(c.this).data('format')) { options.format = $(c.this).data('format'); }
      if ($(c.this).data('format-label')) { options.formatLabel = $(c.this).data('format-label'); }
      if ($(c.this).data('format-submit')) { options.formatSubmit = $(c.this).data('format-submit'); }
      if ($(c.this).data('hidden-name')) { options.hiddenName = true; }

      if ($(c.this).data('min-ride-start-time')) {
        options.min = [
          $(c.this).data('hour'),
          $(c.this).data('minute'),
        ];
      }

      if ($(c.this).data('max-ride-start-time')) {
        options.max = [
          $(c.this).data('hour'),
          $(c.this).data('minute'),
        ];
      }

      dateInstance = $(c.this).pickatime(options).pickatime('picker');

      if ($(c.this).attr('set_initial_default')) {
        return dateInstance.set('select', $(c.this).attr('default_value'));
      }
    }
  };

  initialize();

  $(c.this).on('change', () => {
    $(c.checkable).prop('checked', false);
    $('#outbound_trip_will_call').prop('checked', false);
    $('#return_trip_will_call').prop('checked', false);
    $('#outbound_trip_will_call_responsive').prop('checked', false);
    return $('#return_trip_will_call_responsive').prop('checked', false);
  });

  const set_time = (time) => dateInstance.set('select', time);

  $(c.this).on('set-time', (event, time) => set_time(time));

  $(c.this).on('reset', (e) => {
    const default_value = $(c.this).attr('default_value');
    if (default_value && (default_value !== $(c.this).val())) {
      $(c.this).val(default_value);
      return set_time(default_value);
    }
  });

  // Set a minimum when the date chosen from the datepicker is the same as the
  // datepicker minimum date
  return $(document).off('datepicker-closed').on('datepicker-closed', (event, element) => {
    if (element?.id && dateInstance && $(c.this).data('min-ride-start-time')) {
      const date = $(c.this).data('min-ride-start-time');
      if ($(element).data('min-ride-start-time') === $(element).val()) {
        return dateInstance.set('min', [$(c.this).data('hour'),
          $(c.this).data('minute'),
        ]);
      }
      return dateInstance.set('min', false);
    }
  });
}));
