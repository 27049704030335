/* eslint-disable import/prefer-default-export */

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';

import type { TransportationCompanyResponse } from '../../types';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getTransportationCompany: build.query({
      query: (transportationCompanyId: number) => {
        return {
          url: `transportation_companies/${transportationCompanyId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: TransportationCompanyResponse) =>
        keysToCamel(response) as TransportationCompanyResponse,
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as Record<string, unknown>, // TODO: Type error response
    }),
  }),
});

export const { useGetTransportationCompanyQuery } = extendedApi;
