import React, { useState } from 'react';

import type { AppDispatch } from '@/store';

import { useDispatch, useSelector } from 'react-redux';

import { selectSearch, setSearch } from '../../store/ridesFilterSlice';

const Search = () => {
  const dispatch = useDispatch<AppDispatch>();

  const search = useSelector(selectSearch);
  const [inputSearchString, setInputSearchString] = useState(search);

  return (
    <>
      <div className="field -inline -full-width-sm my-1 my-sm-0">
        <input
          className="input -inline -filter -thick"
          type="text"
          name="search"
          placeholder="Name or Ride ID"
          onChange={(e) => setInputSearchString(e.target.value)}
          value={inputSearchString}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              dispatch(setSearch(inputSearchString));
            }
          }}
        />
      </div>

      <div className="field -inline -full-width-sm my-1 my-sm-0">
        <button
          className="button -search -block-sm"
          type="button"
          aria-label="Search rides"
          onClick={() => {
            dispatch(setSearch(inputSearchString));
          }}
        />
      </div>
    </>
  );
};

export default Search;
