/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// location-updater.coffee
//= ==============================================================================

new Component('location-updater', ['fields', 'overlay', 'open', 'close',
  'submit', 'token', 'patient', 'location', 'results', 'result', 'result_name',
  'result_address_1', 'result_address_2', 'remove', 'result_timezone'], ((c) => {
  // Helper to select correct modal fields
  const $field = (name) => $(c.fields).find(`[name='location[${name}]']`);

  // Set auth constants
  const AUTH_TOKEN = $field('auth_token').val();
  const HEADERS = { AUTHORIZATION: `Token token=${AUTH_TOKEN}` };
  let SCOPE = false;

  // Track current location ID
  let currLocationID = null;

  const applyGeofence = () => $('.form').data('apply-geofence');

  const locationError = function (xhr, status, e) {
    $field('street_address').parent('.field').addClass('-error');
    $field('city').parent('.field').addClass('-error');
    $field('state').parent('.field').addClass('-error');
    $field('zipcode').parent('.field').addClass('-error');

    // Show boundary validation error when the location is outside geofence boundary
    const {
      errors,
    } = xhr.responseJSON;
    if (errors.boundary_validation) {
      $(c.fields).find('.field-error-note small').html(errors.boundary_validation[0]);
      return $(c.fields).find('.field.full-address').addClass('-error');
    }
    return $(c.fields).find('.field-error-note small').html('Please enter a valid address');
  };

  // Get location by ID and prefill modal fields
  const getLocation = function (locationID, scope) {
    let url;
    if (scope === 'user') {
      url = '/api/v1/care-coordinators/get-common-location';
      url += `?location_id=${locationID}`;
    } else {
      url = '/api/v1/care-coordinators/get-patient-location';
      url += `?patient_id=${$field('patient_id').val()}`;
      url += `&location_id=${locationID}`;
    }

    return $.ajax({
      dataType: 'json',
      error: locationError,
      headers: HEADERS,
      success(data, status) {
        ({
          data,
        } = data);

        // Prefill modal fields by returned location data
        $field('location_type').filter(`[value='${data.location_type}']`).click();
        $field('name').val(data.name);
        $field('street_address').val(data.street_address);
        $field('city').val(data.city);
        $field('state').val(data.state);
        $field('zipcode').val(data.zipcode);
        $field('latitude').val(data.latitude);
        $field('longitude').val(data.longitude);
        $field('venue_mapping_id').val(data.venue_mapping_id);
        $field('id').val(data.id);

        $field('full_address').val(`${data.street_address}, ${data.city}, ${data.state}, ${data.zipcode}`);

        // Show modal
        $(c.submit).attr('disabled', false);
        $(c.overlay).addClass('is-active');
        $(c.overlay).on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function (e) {
          if ((e.target === this) && $(this).hasClass('is-active')) {
            return $('#update_full_address').focus();
          }
        });

        // fire event
        $('body').trigger('locationUpdaterOpened', [data.id]);
        $(document).trigger('google-autocomplete-place-changed-trigger-map', { lat: data.latitude, lng: data.longitude, location_update: true });
      },
      type: 'GET',
      url,
    });
  };

  // Send location update request
  const updateLocation = function (locationParams) {
    let url;
    if (SCOPE === 'user') {
      url = '/api/v1/care-coordinators/update-common-location';
    } else {
      url = '/api/v1/care-coordinators/update-patient-location';
    }

    return $.ajax({
      data: locationParams,
      error: locationError,
      headers: HEADERS,
      success(data, status) {
        ({
          data,
        } = data);
        const street_address = data.name.toLowerCase() === data.street_address.toLowerCase() ? '' : data.street_address;
        insertLocationAtDesiredPlace(data);
        const $result = $(`${c.result}[data-location-id='${data.id}']`);
        $result.find(c.result_name).html(data.name);
        $result.find(c.result_address_1).html(street_address);
        $result.find(c.result_address_2).html(`${data.city}, ${data.state} ${data.zipcode}`);
        $result.find(c.result_timezone).val(data.time_zone);
        $result.find("input[name='location']").attr('data-geofence-boundary-type-id', data.geofence_boundary_type_id);
        if (applyGeofence()) {
          // Show service area batch, enable location for selection and reset error message
          $result.find('.service-area-batch').removeClass('hidden');
          $result.find('.label.unservicable-location').removeClass('unservicable-location');
        }
        return $(c.close).click();
      },
      type: 'POST',
      url,
    });
  };

  var insertLocationAtDesiredPlace = function (locationData) {
    const locationComponent = new CommonLocationComponent();
    return locationComponent.insertLocation({
      nameSelector: "[data-location-updater-target='updater-result-name']",
      selector: $(`[data-location-id=${locationData.id}]`).parent().find('> div'),
    }, {
      location: $(`[data-location-id='${locationData.id}']`),
      name: locationData.name,
    });
  };

  // Open modal
  $(c.results).on('click', c.open, function (e) {
    e.preventDefault();

    // Remove error class and reset error message.
    $(c.fields).find('.field.full-address').removeClass('-error');
    $(c.fields).find('.field-error-note small').html('Please enter a valid address');

    const locationID = $(this).parents(c.result).attr('data-location-id');
    SCOPE = $(this).parents(c.result).attr('data-scope');
    currLocationID = locationID;
    const $locationType = document.querySelector('.location-type');

    if (SCOPE === 'patient') {
      $locationType.style.display = 'block';
    } else {
      $locationType.style.display = 'none';
    }

    return getLocation(locationID, SCOPE);
  });

  // Remove patient location
  $(c.results).on('click', c.remove, function (e) {
    e.preventDefault();
    const search_result = $(this).closest('.search-result');
    const data = {
      location_id: search_result.attr('data-location-id'),
      patient_id: $field('patient_id').val(),
    };

    return $.ajax({
      data,
      headers: HEADERS,
      // Append newly created location to appropriate section
      success(data, status) {
        return search_result.remove();
      },

      type: 'POST',

      url: '/api/v1/care-coordinators/remove-patient-location',
    });
  });

  // Submit form data
  $(c.submit).on('click', function (e) {
    e.preventDefault();

    $(this).attr('disabled', true);

    const locationParams = {
      apply_geofence: applyGeofence(),
      city: $field('city').val(),
      default: '0',
      latitude: $field('latitude').val(),
      location_id: currLocationID,
      longitude: $field('longitude').val(),
      name: $field('name').val(),
      state: $field('state').val(),
      street_address: $field('street_address').val(),
      venue_mapping_id: $field('venue_mapping_id').val(),
      zipcode: $field('zipcode').val(),
    };

    if (SCOPE === 'patient') {
      locationParams.patient_id = $field('patient_id').val();
      locationParams.location_type = $field('location_type').filter(':checked').val();
    } else {
      locationParams.care_coordinator_id = $field('care_coordinator_id').val();
      locationParams.location_type = 'facility';
    }

    return updateLocation(locationParams);
  });

  // Close modal
  $(c.close).on('click', (e) => {
    e.preventDefault();
    $(c.overlay).removeClass('is-active');
    return $('body').trigger('locationModalClosed');
  });

  // Close modal on mousedown event on overlay
  $(c.overlay).on('mousedown', (e) => {
    $(c.overlay).removeClass('is-active');
    return $('body').trigger('locationModalClosed');
  });

  // Prevent modal close by mousedown within modal
  return $(c.this).on('mousedown', (e) => e.stopPropagation());
}));
