/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// ride-decline.coffee
//= ==============================================================================

new Component('ride-decline', ['close', 'decline'], ((c) => {
  let declineRide;
  const authToken = $(c.this).data('authToken');
  const headers = { Authorization: `Token token=${authToken}` };

  $(document).ready(() => {
    const declineParam = window.location.search.match(/[?&]decline[=&]*/);
    const $declineRideModal = $('#decline-ride-modal');
    if (declineParam && declineParam.length && $declineRideModal.length) {
      return $declineRideModal.addClass('is-active');
    }
  });

  $(c.close).on('click', (e) => {
    e.preventDefault();
    return closeRideDeclineModal();
  });

  $(c.decline).on('click', (e) => {
    e.preventDefault();
    return declineRide();
  });

  $("[data-modal-target='decline-ride-success-overlay']").on('click', (e) => window.location = window.location.pathname);

  $("[data-modal-target='decline-ride-success-close']").on('click', (e) => window.location = window.location.pathname);

  $('#decline_reason').on('blur change', () => validateData());

  $('#decline_additional_info').on('blur change', () => validateData());

  var validateData = function () {
    const additionalInfoRequiredFor = ['other'];
    const $field = $('#decline_additional_info').parents('.field').first();
    const isAdditionalInfoRequired = ~additionalInfoRequiredFor.indexOf($('#decline_reason').val());

    if (isAdditionalInfoRequired && !$('#decline_additional_info').val().trim()) {
      return $field.addClass('-error');
    }
    return $field.removeClass('-error');
  };

  const showErrorModal = function () {
    $(c.this).attr('disabled', false);
    return $('#decline-ride-error-modal').addClass('is-active');
  };

  const showSuccessModal = function () {
    $(c.this).attr('disabled', false);
    return $('#decline-ride-success-modal').addClass('is-active');
  };

  var closeRideDeclineModal = () => $('[data-modal-target="decline-ride-close"]').trigger('click');

  return declineRide = function () {
    $(c.this).attr('disabled', true);
    const rideId = $(c.this).data('rideId');
    return $.ajax({
      data: {
        additional_info: $('#decline_additional_info').val(),
        reason: $('#decline_reason').val(),
        ride_id: rideId,
      },
      dataType: 'json',
      error(xhr, status, e) {
        return showErrorModal();
      },
      headers,
      success(response, status) {
        closeRideDeclineModal();
        return showSuccessModal();
      },
      type: 'POST',
      url: `/api/v1/dispatchers/ride/${rideId}/decline`,
    });
  };
}));
