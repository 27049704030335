import type {
  BaseCcStatusBadgeText,
  CadRideStatusBadgeText,
  CcRideStatusBadgeText,
  RideShareRideStatusBadgeText,
} from '@/features/RideStatusBadge/types';
import type { Ride, StreamableRide } from '@/types';

import moment from 'moment';

import {
  BASE_CC_STATUS_BADGE_TEXT,
  CAD_RIDE_STATUS_BADGE_TEXT,
  RIDE_SHARE_STATUS_BADGE_TEXT,
  RideStatusBadgeText,
} from '@/features/RideStatusBadge/types';
import { RIDE_AUTH_STATUS, RideCurrentStatus, TIMELINESS } from '@/types';

export const remainingTime = (cutoff: moment.Moment) =>
  cutoff.diff(moment.utc(), 's');

export const withinWindow = (cutoff: moment.Moment) =>
  cutoff.isValid() && remainingTime(cutoff) > 0;

export const humanizedTimeliness = ({ timeliness }: Ride) => {
  switch (timeliness) {
    case TIMELINESS.on_time:
      return 'On time';
    case TIMELINESS.late_15_min:
      return 'Late 15 min';
    case TIMELINESS.late_30_min:
      return 'Late 30 min';
    case TIMELINESS.late_45_min:
      return 'Late 45 min';
    case TIMELINESS.late_60_min:
      return 'Late 60 min';
    case TIMELINESS.late_over_60_min:
      return 'Late over 60 min';
    default:
      return 'On time';
  }
};

const rideShareStatus = (ride: Ride) => {
  switch (ride.currentStatus) {
    case RideCurrentStatus.TO_PICKUP:
      return RIDE_SHARE_STATUS_BADGE_TEXT.toPickup;
    case RideCurrentStatus.AT_PICKUP:
      return RIDE_SHARE_STATUS_BADGE_TEXT.atPickup;
    case RideCurrentStatus.TO_DROPOFF:
      return RIDE_SHARE_STATUS_BADGE_TEXT.toDestination;
    case RideCurrentStatus.AT_DROPOFF:
    case RideCurrentStatus.COMPLETED:
      return RIDE_SHARE_STATUS_BADGE_TEXT.completed;
    case RideCurrentStatus.QUEUED:
      return ride.withinAdvanceNoticeForRideShare
        ? RIDE_SHARE_STATUS_BADGE_TEXT.findingDriver
        : RIDE_SHARE_STATUS_BADGE_TEXT.requested;
    case RideCurrentStatus.INCOMPLETE:
    case RideCurrentStatus.CANCELED:
    case RideCurrentStatus.CLAIMED:
      return ride.currentStatus; // .humanize;
    default:
      console.warn('Unknown ride share status for ride id:', ride.id);
      return 'UNKNOWN' as RideShareRideStatusBadgeText;
  }
};

const cadStatus = (ride: Ride) => {
  switch (ride.currentStatus) {
    case RideCurrentStatus.QUEUED:
      return CAD_RIDE_STATUS_BADGE_TEXT.findingProviders;
    case RideCurrentStatus.TO_PICKUP:
      return CAD_RIDE_STATUS_BADGE_TEXT.toPickup;
    case RideCurrentStatus.AT_PICKUP:
      return CAD_RIDE_STATUS_BADGE_TEXT.atPickup;
    case RideCurrentStatus.TO_DROPOFF:
      return CAD_RIDE_STATUS_BADGE_TEXT.toDestination;
    case RideCurrentStatus.AT_DROPOFF:
      return CAD_RIDE_STATUS_BADGE_TEXT.atDestination;
    case RideCurrentStatus.INCOMPLETE:
    case RideCurrentStatus.CANCELED:
    case RideCurrentStatus.CLAIMED:
    case RideCurrentStatus.COMPLETED:
      return ride.currentStatus as unknown as CadRideStatusBadgeText; // .humanize;
    default:
      console.warn('Unknown ride status for ride id:', ride.id);
      return 'UNKNOWN' as CadRideStatusBadgeText;
  }
};

export const doubleDigits = (num: number) =>
  num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

export const formatDisplay = (seconds: number) =>
  `${Math.floor(seconds / 60)}:${doubleDigits(seconds % 60)} left`;

const COMMUNITY_TRANSPORTATION_COMPANY_ID = 0;

export const dispatcherRideBadgeText = (
  ride: StreamableRide,
): RideStatusBadgeText => {
  const biddingWindowCutoff = moment
    .utc(ride.timerStartTime)
    .add(ride.biddingWindow, 's');

  const ppCutoffTime = moment
    .utc(ride.timerStartTime)
    .add(ride.ppBiddingWindow, 's');

  if (
    ride.isDeclined &&
    ride.transportationCompanyId === COMMUNITY_TRANSPORTATION_COMPANY_ID
  ) {
    return RideStatusBadgeText.DECLINED;
  }

  if (ride.hasBid && withinWindow(biddingWindowCutoff)) {
    return RideStatusBadgeText.RESPONDED;
  }

  if (ride.currentStatus === RideCurrentStatus.COMPLETED) {
    return RideStatusBadgeText.COMPLETED;
  }

  if (ride.currentStatus === RideCurrentStatus.CANCELED) {
    return RideStatusBadgeText.CANCELED;
  }

  if (ride.processingRide) {
    return RideStatusBadgeText.PROCESSING;
  }

  if (ride.autoAssigned && !ride.hasBid) {
    return RideStatusBadgeText.SUBMIT_ETA;
  }

  if (ride.willCall) {
    return RideStatusBadgeText.NOT_STARTED;
  }

  if (ride.currentStatus === RideCurrentStatus.QUEUED && !ride.claimed) {
    if (ride.streamed) {
      return RideStatusBadgeText.NEW_RIDE;
    }

    if (
      ride.preferredProviderArrival &&
      withinWindow(ppCutoffTime) &&
      !ride.processingRide
    ) {
      return RideStatusBadgeText.PREFERRED;
    }

    return RideStatusBadgeText.AVAILABLE;
  }

  if (ride.timeliness !== null) {
    return RideStatusBadgeText.IN_PROGRESS;
  }

  return RideStatusBadgeText.ASSIGNED;
};

export const careCoordinatorRideBadgeText = (
  ride: StreamableRide,
): CcRideStatusBadgeText => {
  if (ride.currentStatus === RideCurrentStatus.COMPLETED) {
    return BASE_CC_STATUS_BADGE_TEXT.completed;
  }

  if (ride.currentStatus === RideCurrentStatus.CANCELED) {
    return BASE_CC_STATUS_BADGE_TEXT.canceled;
  }

  if (ride.currentStatus === RideCurrentStatus.INCOMPLETE) {
    return BASE_CC_STATUS_BADGE_TEXT.incomplete;
  }

  if (
    ride.authorizable &&
    ride.medicaid &&
    ride.vehicleNeedType !== 'stretcher'
  ) {
    if (
      ride.authStatus === RIDE_AUTH_STATUS.authorizing ||
      ride.authStatus === RIDE_AUTH_STATUS.authorized ||
      ride.noAuthorizationRequired
    ) {
      return BASE_CC_STATUS_BADGE_TEXT.brokered;
    }

    return BASE_CC_STATUS_BADGE_TEXT.onHold;
  }

  if (ride.willCall) {
    return BASE_CC_STATUS_BADGE_TEXT.notStarted;
  }

  if (ride.rideshare) {
    return rideShareStatus(ride);
  }

  if (ride.currentStatus === RideCurrentStatus.QUEUED) {
    if (ride.claimed) {
      return BASE_CC_STATUS_BADGE_TEXT.claimed;
    }

    return ride.communityCallRequired
      ? BASE_CC_STATUS_BADGE_TEXT.callingTransports
      : BASE_CC_STATUS_BADGE_TEXT.checkingAvailability;
  }

  if (
    ride.currentStatus === RideCurrentStatus.AT_PICKUP &&
    ride.confirmedArrival
  ) {
    return BASE_CC_STATUS_BADGE_TEXT.pickedUp;
  }

  if (ride.usesCustomizeDispatchSoftware) {
    return cadStatus(ride);
  }

  if (ride.timeliness !== null) {
    return BASE_CC_STATUS_BADGE_TEXT.inProgress;
  }

  return 'UNKNOWN STATE' as BaseCcStatusBadgeText;
};
