/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// assign-dropdown-component.coffee
//= ==============================================================================

// Configuring drop down with grouped options (provided by select-2 gem)
$(document).on('turbolinks:load', () => {
  const formatState = function (state) {
    if (!state.id) {
      return state.text;
    }

    $('.select2-search__field').attr('placeholder', 'Filter by company name');
    const $state = $(
      `<span>${state.text}</span>`,
    );
    return $state;
  };

  return $('.js-searchable').select2({
    allowClear: true,
    placeholder: 'Select a company',
    templateResult: formatState,
  });
});
