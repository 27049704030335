/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// schedule-summarizer.coffee
//= ==============================================================================

new Component('schedule-summarizer', ['rule', 'interval', 'days', 'count',
  'end_time', 'monthly_repeat_type', 'output', 'ends'], ((c) => {
  const updateSummary = function () {
    let summary = '';
    switch ($(c.rule).val()) {
      case 'daily':
        if ($(c.interval).val() === '1') {
          summary += 'Daily';
        } else {
          summary += `Every ${$(c.interval).val()} days`;
        }
        break;

      case 'weekly':
        if ($(c.interval).val() === '1') {
          summary += 'Weekly';
        } else {
          summary += `Every ${$(c.interval).val()} weeks`;
        }
        var days = $(c.days).map(function () {
          const day = $(this).val();
          if ($(this).is(':checked')) { return day[0].toUpperCase() + day.slice(1); }
        });
        if (days.length > 0) { summary += ` (${days.get().join(', ')})`; }
        break;

      case 'monthly':
        if ($(c.interval).val() === '1') {
          summary += 'Monthly';
        } else {
          summary += `Every ${$(c.interval).val()} months`;
        }
        if ($(`${c.monthly_repeat_type}[value='day_of_month']`).is(':checked')) {
          summary += ' on the day of the month';
        } else {
          summary += ' on the day of the week';
        }
        break;
    }

    if ($(c.count).length && !$(c.count).attr('disabled')) {
      summary += `, ${$(c.count).val()} time`;
      if ($(c.count).val() > 1) { summary += 's'; }
    } else {
      summary += ` until ${$(c.end_time).val()}`;
    }

    return $(c.output).html(summary);
  };

  $(c.rule).on('change', updateSummary);
  $(c.interval).on('change', updateSummary);
  $(c.days).on('change', updateSummary);
  $(c.count).on('change', updateSummary);
  $(c.end_time).on('change', updateSummary);
  $(c.monthly_repeat_type).on('change', updateSummary);
  $(c.ends).on('change', updateSummary);

  return updateSummary();
}));
