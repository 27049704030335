/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

const load = function () {
  let closeSidebar;
  let sidebar = null;
  $(document).on('click', '[data-user-id]', function (event) {
    event.preventDefault();
    event.stopPropagation();

    const userId = $(this).attr('data-user-id');
    const AUTH_TOKEN = $(this).attr('data-auth-token');

    if (sidebar && (sidebar.id === userId)) {
      closeSidebar();
      return;
    }

    if (sidebar && (sidebar.id !== userId)) {
      closeSidebar();
    }

    sidebar = document.getElementsByClassName('right-sidebar')[0];
    return getUser(userId, AUTH_TOKEN);
  });

  var getUser = function (userId, auth_token) {
    const headers = { Authorization: `Token token=${auth_token}` };

    return $.ajax({
      dataType: 'html',
      headers,
      success(data, status) {
        $(document).trigger('cc-sidebar-open');

        $('#users').html(data);
        $('.right-sidebar').attr('id', userId);
        $(sidebar).addClass('-show');
        return $('.right-sidebar').find('.edit_user').attr('action', `/care_coordinator/${userId}/reset-password`);
      },
      type: 'GET',
      url: `/api/v1/super-user/care-coordinator/${userId}`,
    });
  };

  return closeSidebar = function () {
    $(sidebar).removeClass('-show');
    $(sidebar).find('.sidebar-content .view').show();
    $(sidebar).find('.sidebar-content .edit').hide();
    return sidebar = null;
  };
};

document.addEventListener('DOMContentLoaded', load);
