/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// ride-eta-bid-create.coffee
//= ==============================================================================

new Component('ride-eta-bid-create', ['overlay', 'token', 'form', 'ride_id', 'other_ride_id'], ((c) => {
  const authToken = $(c.this).data('authToken');
  const headers = { Authorization: `Token token=${authToken}` };

  const createBid = function () {
    let is_other_ride_radio_btn_checked;
    $(c.this).attr('disabled', true);
    const rideId = $(c.this).data('rideId');
    const otherRideId = $(c.this).data('otherRideId');

    if (otherRideId !== undefined) {
      is_other_ride_radio_btn_checked = document.getElementById('radio-btn-other-ride').checked;
    }

    // ajax call for first ride
    $.ajax({
      data: {
        datetime: $('[data-component="datetime"]').val(),
        ride_id: rideId,
      },
      dataType: 'json',
      error(xhr, status, e) {
        const {
          errors,
        } = xhr.responseJSON;
        return showBidFailureModal(errors.bid_declined);
      },
      headers,
      success(response, status) {
        if (!is_other_ride_radio_btn_checked) {
          if (response.data.success) {
            $('a[data-modal-target="set-eta-bid-close"]').trigger('click');
            $('.claim-link').html('Update ETA');
            $('.claim-modal-btn').html('Update my ETA');
            $('.decline-btn').show();
            $('.decline-modal-btn').show();
            if (response.data.bid_accepted) {
              $('.decline-link').html('Decline ride');
              $('.decline-modal-btn').val('Decline ride');
              return showSuccessModal('bid-accepted');
            } if (response.data.bid_accepted_ride_assigned) {
              $('.decline-link').html('Release ride');
              $('.decline-modal-btn').val('Release ride');
              $('.submit-btn').hide();
              $('.claim-modal-btn').hide();
              return showSuccessModal('bid-accepted-ride-assigned');
            }
          } else {
            showHoldFailureModal(response.data.error);
            return console.log(response.data.error);
          }
        }
      },
      type: 'POST',
      url: `/api/v1/dispatchers/ride/${rideId}/create-bid`,
    });

    if (is_other_ride_radio_btn_checked) {
      const data = {
        datetime: document.getElementById('assign-datetime2').value,
        ride_id: otherRideId,
      };
      // ajax call for other ride
      return $.ajax({
        data,
        dataType: 'json',
        error(xhr, status, e) {
          const {
            errors,
          } = xhr.responseJSON;
          return showBidFailureModal(errors.bid_declined);
        },
        headers,
        success(response, status) {
          if (response.data.success) {
            $('a[data-modal-target="set-eta-bid-close"]').trigger('click');
            $('.claim-link').html('Update ETA');
            $('.claim-modal-btn').html('Update my ETA');
            $('.decline-btn').show();
            $('.decline-modal-btn').show();
            if (response.data.bid_accepted) {
              $('.decline-link').html('Decline ride');
              $('.decline-modal-btn').val('Decline ride');
              return showSuccessModal('bid-accepted');
            } if (response.data.bid_accepted_ride_assigned) {
              $('.decline-link').html('Release ride');
              $('.decline-modal-btn').val('Release ride');
              $('.submit-btn').hide();
              $('.claim-modal-btn').hide();
              return showSuccessModal('bid-accepted-ride-assigned');
            }
          } else {
            showHoldFailureModal(response.data.error);
            return console.log(response.data.error);
          }
        },
        type: 'POST',
        url: `/api/v1/dispatchers/ride/${otherRideId}/create-bid`,
      });
    }
  };

  var showBidFailureModal = function (error) {
    $(c.this).attr('disabled', false);
    $('#bid-error-modal').addClass('is-active');
    return $('#failure_message').html(error);
  };

  var showSuccessModal = function (id) {
    $('#set-eta-bid-modal').removeClass('is-active');
    $(c.this).attr('disabled', false);
    return $(`#${id}-modal`).addClass('is-active');
  };

  $(c.this).on('click', (e, params) => {
    if (!params) {
      e.preventDefault();
      return createBid();
    }
  });

  $("[data-modal-target='bid-accepted-ride-assigned-overlay']").on('click', (e) => window.location.reload());

  $("[data-modal-target='bid-accepted-ride-assigned-close']").on('click', (e) => window.location.reload());

  $("[data-modal-target='bid-accepted-overlay']").on('click', (e) => window.location.reload());

  return $("[data-modal-target='bid-accepted-close']").on('click', (e) => window.location.reload());
}));
