/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-arrow-callback */
/**
 * Note: This page will not load a dashboard unless you configure TLS for your dev
 * server AND you whitelist your origin URL in the QuickSight settings page.
 * Please send a message in the slack channel #tech if you require whitelisting.
 */
function getDashboard(embedUrl) {
  const div = document.getElementById('quicksight');
  if (div === null) handleError();

  const errorMessage = div.getAttribute('error_message'); // Check if the BE couldn't get a URL
  if (errorMessage) handleError();

  const options = { // https://github.com/awslabs/amazon-quicksight-embedding-sdk#step-2-configure-embedding
    container: div,
    footerPaddingEnabled: true,
    height: 'AutoFit',
    locale: 'en-US',
    resetDisabled: true,
    scrolling: 'no',
    undoRedoDisabled: true,
    url: embedUrl,
  };

  // Package imported via CDN. Find in `layouts/application.html.haml`
  const dashboard = window.QuickSightEmbedding.embedDashboard(options);

  dashboard.on('load', function () { // Hide the cute gif after the dashboard has finished
    $('.reports-loading').addClass('-hidden');
    div.style.display = null;
  });

  dashboard.on('error', function () { handleError(); });
}

function handleError() {
  $('.filter-container').removeClass('-hidden');
  $('.reports-loading').addClass('-hidden');
  $('#graph-content').addClass('report-error');
  $('#graph-content').html(
    // eslint-disable-next-line comma-dangle
    '<div><h1>Oops! Something went wrong while loading reports, please try after some time.</h1></div>'
  );
}

function handleReportChange(e) {
  const uuid = e.target.value;
  const authToken = $('.auth-token').attr('data-token');
  $('#quicksight').empty();
  $('.reports-loading').show();

  const headers = { Authorization: `Token token=${authToken}` };
  $.ajax({
    data: {
      report_uuid: uuid,
    },
    dataType: 'json',
    headers,
    success(response) {
      $('.reports-loading').hide();
      if (response.embed_url) { getDashboard(response.embed_url); }
    },
    type: 'GET',
    url: '/api/v2/reports/quicksight_url/',
  });
}

document.addEventListener('turbolinks:load', function () {
  const {
    controller,
  } = document.body.dataset;
  if (controller !== 'cc_reports') return;

  const div = document.getElementById('quicksight');
  if (div === null) handleError();

  const errorMessage = div.getAttribute('error_message'); // Check if the BE couldn't get a URL
  if (errorMessage) handleError();

  const jsonData = div.getAttribute('embedUrl') || '{}';
  const data = JSON.parse(jsonData);
  if (data.embed_url) {
    getDashboard(data.embed_url);

    document.getElementById('reports-select-dropdown')
      .addEventListener('change', function (e) {
        handleReportChange(e);
      });
  }
});
