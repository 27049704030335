import { datadogLogs } from '@datadog/browser-logs';

import isDev from '@/utils/isDev';

let initialized = false;
if (!isDev && process.env.DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: process.env.ENV,
    service: 'web-frontend',
    site: 'datadoghq.com',
  });

  initialized = true;
}

export const debug = (msg: string, msgCtx?: object, err?: Error) =>
  initialized ? datadogLogs.logger.debug(msg, msgCtx, err) : console.debug(msg);

export const info = (msg: string, msgCtx?: object, err?: Error) =>
  initialized ? datadogLogs.logger.info(msg, msgCtx, err) : console.info(msg);

export const warn = (msg: string, msgCtx?: object, err?: Error) =>
  initialized ? datadogLogs.logger.warn(msg, msgCtx, err) : console.warn(msg);

export const error = (msg: string, msgCtx?: object, err?: Error) =>
  initialized ? datadogLogs.logger.error(msg, msgCtx, err) : console.error(msg);

export { datadogLogs };
