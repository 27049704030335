import React, { useEffect, useState } from 'react';

import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons';
import { useDispatch, useSelector } from 'react-redux';

import * as Popover from '@/lib/@radix-ui/react-popover';
import * as RadioGroup from '@/lib/@radix-ui/react-radio-group';

import {
  selectShowWillCall,
  setShowWillCall,
} from '../../store/ridesFilterSlice';

const getFilterLabel = (willCall: boolean | null) =>
  willCall === null ? 'Will call' : 'Will call: Hide';

const WillCall = () => {
  const dispatch = useDispatch();
  const willCall = useSelector(selectShowWillCall);

  const [showChecked, setShowChecked] = useState<boolean>(willCall === null);
  const [willCallLabel, setWillCallLabel] = useState(getFilterLabel(willCall));

  useEffect(() => {
    setWillCallLabel(getFilterLabel(willCall));
    setShowChecked(willCall === null);
  }, [willCall]);

  const handleClose = () =>
    willCall !== showChecked &&
    dispatch(setShowWillCall(showChecked ? null : false));

  const handleChange = (filter: string) => {
    switch (filter) {
      case 'show':
        setShowChecked(true);
        break;
      case 'hide':
        setShowChecked(false);
        break;
      default:
        setShowChecked(true);
        break;
    }
  };

  return (
    <div>
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {willCallLabel}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content"
            align="start"
            onCloseAutoFocus={handleClose}
          >
            <RadioGroup.Root
              className="RadioButtonGroupContainer"
              defaultValue={showChecked ? 'show' : 'hide'}
              onValueChange={handleChange}
            >
              <div className="d-flex dropdown-option">
                <RadioGroup.Item
                  className="RadioButtonGroupItem"
                  value="show"
                  id="show-radio"
                >
                  <RadioGroup.Indicator className="RadioButtonIndicator">
                    <CheckIcon />
                  </RadioGroup.Indicator>
                </RadioGroup.Item>
                <label
                  className={`Label flex-1 ${showChecked ? 'checked' : ''}`}
                  htmlFor="show-radio"
                >
                  Show
                </label>
              </div>
              <div className="d-flex dropdown-option">
                <RadioGroup.Item
                  className="RadioButtonGroupItem"
                  value="hide"
                  id="hide-radio"
                >
                  <RadioGroup.Indicator className="RadioButtonIndicator">
                    <CheckIcon />
                  </RadioGroup.Indicator>
                </RadioGroup.Item>
                <label
                  className={`Label flex-1 ${showChecked ? '' : 'checked'}`}
                  htmlFor="hide-radio"
                >
                  Hide
                </label>
              </div>
            </RadioGroup.Root>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default WillCall;
