import React, { useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import DatePicker from '../DatePicker';

dayjs.extend(utc);

const RangePicker: React.FC<{
  endTime: string;
  setEndTime: (date: string) => void;
  setStartTime: (date: string) => void;
  startTime: string;
}> = ({ endTime, setEndTime, setStartTime, startTime }) => {
  const [storedStartDate, setStoredStartDate] = useState(dayjs(startTime));
  const [storedEndDate, setStoredEndDate] = useState(dayjs(endTime));

  const setComponentStartDate = (day: dayjs.Dayjs) => {
    setStoredStartDate(day);
    setStartTime(day.utc().format());
  };

  const setComponentEndDate = (day: dayjs.Dayjs) => {
    setStoredEndDate(day);
    setEndTime(day.utc().format());
  };

  return (
    <div className="d-flex flex-items-stretch" style={{ gap: '0.25rem' }}>
      <DatePicker
        startDate={storedStartDate}
        setStartDate={setComponentStartDate}
        endDate={storedEndDate}
        setEndDate={setComponentEndDate}
      />
    </div>
  );
};

export default RangePicker;
