/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// location-creator.coffee
//= ==============================================================================

new Component('location-creator', ['fields', 'overlay', 'open', 'close',
  'empty', 'create', 'output', 'template', 'token', 'patient', 'filters'], ((c) => {
  // Clear modal fields
  const clear_modal_fields = function () {
    $(c.overlay).find(c.fields).find('.field.-error').removeClass('-error');
    $(c.overlay).find(c.fields).find('input').attr('checked', false);
    $(c.overlay).find(c.fields).find("input:not([type='radio']):not([type='checkbox'])").val('');
    $(c.overlay).find(c.fields).find('textarea').val('');
    return $(c.overlay).find(c.fields).find('.field-error-note small').html('Please enter a valid address');
  };

  // Open modal
  $(c.open).on('click', (e) => {
    e.preventDefault();
    $(c.overlay).addClass('is-active');
    clear_modal_fields();
    $(c.overlay).on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function (e) {
      if ((e.target === this) && $(this).hasClass('is-active')) {
        return $('#full_address').focus();
      }
    });
    return $('body').trigger('locationAdderOpened', []);
  });

  // Close modal
  $(c.close).on('click', (e) => {
    e.preventDefault();
    $('body').trigger('locationModalClosed');
    return $(c.overlay).removeClass('is-active');
  });

  // Close modal on mousedown event on overlay
  $(c.overlay).on('mousedown', (e) => {
    $('body').trigger('locationModalClosed');
    return $(c.overlay).removeClass('is-active');
  });

  // Prevent modal close by mousedown within modal
  $(c.this).on('mousedown', (e) => e.stopPropagation());

  const insertLocationAtDesiredPlace = function (locationType, locationData) {
    let newLocationName;
    const locationComponent = new CommonLocationComponent();
    return locationComponent.insertLocation({
      nameSelector: "[data-location-updater-target='updater-result-name']",
      selector: $(`${locationType} > div`),
    }, {
      location: $(`${locationType} [data-location-id='${locationData.id}']`),
      name: locationData.name,
    });
    const newLocation = $(`${locationType} [data-location-id='${locationData.id}']`);
    return newLocationName = locationData.name.toUpperCase();
  };

  // Submit form inputs via ajax to patient location endpoint
  return $(c.create).on('click', function (e) {
    e.preventDefault();
    $(this).attr('disabled', true);

    // Get checkbox values
    let location_type = 'residence';
    if ($('#new_location_location_type_facility').is(':checked')) { location_type = 'facility'; }

    // Define payload
    const data = {
      apply_geofence: $('.form').data('apply-geofence'),
      city: $('#new_location_city').val(),
      default: '0',
      latitude: $('#new_location_latitude').val(),
      location_type,
      longitude: $('#new_location_longitude').val(),
      name: $('#new_location_name').val(),
      patient_id: $(c.patient).val(),
      state: $('#new_location_state').val(),
      street_address: $('#new_location_street_address').val(),
      venue_mapping_id: $('.venue-zones option:selected').val(),
      zipcode: $('#new_location_zipcode').val(),
    };

    // Submit payload
    return $.ajax({
      data,
      // Handle location creation error
      error(xhr, status, e) {
        $('#new_location_street_address').parent('.field').addClass('-error');
        $('#new_location_city').parent('.field').addClass('-error');
        $('#new_location_state').parent('.field').addClass('-error');
        $('#new_location_zipcode').parent('.field').addClass('-error');

        // Show boundary validation error when the location is outside geofence boundary
        const {
          errors,
        } = xhr.responseJSON;
        if (errors.boundary_validation) {
          $(c.fields).find('.field-error-note small').html(errors.boundary_validation[0]);
          return $(c.fields).find('.field.full-address').addClass('-error');
        }
        return $(c.fields).find('.field-error-note small').html('Please enter a valid address');
      },

      headers: {
        AUTHORIZATION: `Token token=${$(c.token).val()}`,
      },

      // Append newly created location to appropriate section
      success(data, status) {
        $(this).attr('disabled', false);
        const template = Handlebars.compile($(c.template).html());
        let $output = $('#residences');
        if (location_type === 'facility') { $output = $('#facilities'); }
        $output.find(c.empty).hide();
        $output.append(template({
          address_1: data.data.name.toLowerCase() === data.data.street_address.toLowerCase() ? '' : data.data.street_address,
          address_2: `${data.data.city}, ${data.data.state} ${data.data.zipcode}`,
          geofenceBoundaryTypeId: data.data.geofence_boundary_type_id,
          id: data.data.id,
          name: data.data.name,
          timezone: `${data.data.time_zone}`,
        }));

        const locationTypeSelector = location_type === 'facility' ? '#facilities' : '#residences';
        insertLocationAtDesiredPlace(locationTypeSelector, data.data);

        // Switch to appropriate tab and autoselect location
        let $switch_tab = $(c.filters).first();
        if (location_type === 'facility') { $switch_tab = $(c.filters).last(); }
        $switch_tab.trigger('click');
        $(`[name='location'][value='${data.data.id}']`).parents('.label.-radio').first().trigger('click');
        $(`[name='location'][value='${data.data.id}']`).parents('.label.-radio').first().trigger('click');
        $('body').trigger('locationModalClosed');
        return $(c.overlay).removeClass('is-active');
      },

      type: 'POST',

      url: '/api/v1/care-coordinators/create-patient-location',
    });
  });
}));
