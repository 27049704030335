/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
new Component('ride-status-filter', ['apply', 'cancel'], ((c) => {
  const attributeKey = $(c.this).data('attributeKey');

  const disableApply = () => $(`#${attributeKey}_filter`).find('#apply').attr('disabled', true);

  const enableApply = () => $(`#${attributeKey}_filter`).find('#apply').attr('disabled', false);

  const handleDisableApply = function () {
    const selectedFilters = $(`#${attributeKey}`).val() || [];

    if (selectedFilters.length === 0) {
      return disableApply();
    }
    return enableApply();
  };

  $(`#${attributeKey}`).multiselect({
    onOptionClick: handleDisableApply,
  });

  const setInitialState = function () {
    $(`#${attributeKey}-multi-select`)
      .find('.ms-options').addClass('custom-ms-options -small-width');
    $(`#${attributeKey}-multi-select`)
      .find('.ms-options-wrap').find(':button').addClass('hide');
    return $(`#${attributeKey}-multi-select`)
      .find('.ms-options-wrap .ms-options').addClass('-small-height');
  };

  setInitialState();

  const apply = function () {
    const filterValues = $(`#${attributeKey}`).val();
    disableApply();

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('ride_status[]');

    for (const val of Array.from(filterValues)) {
      urlParams.append('ride_status[]', val);
    }

    return window.location.search = urlParams.toString();
  };

  $(c.this).on('click', (e) => {
    const isFilterModalOpen = $(`#${attributeKey}_filter`).find('.filter').hasClass('-is-active');
    if (isFilterModalOpen) {
      return $(`#${attributeKey}_filter`).find('.filter').removeClass('-is-active');
    }
    setInitialState();
    return $(`#${attributeKey}_filter`).find('.filter').addClass('-is-active');
  });

  $(document).on('click', (e) => {
    const isFilterModalOpen = $(`#${attributeKey}_filter`).find('.filter').hasClass('-is-active');
    if (isFilterModalOpen && ($(e.target).attr('data-attribute-key') !== attributeKey)) {
      e.preventDefault();
      return $(`#${attributeKey}_filter`).find('.filter').removeClass('-is-active');
    }
  });

  $(c.cancel).on('click', (e) => $(`#${attributeKey}_filter`).find('.filter').removeClass('-is-active'));

  return $(c.apply).on('click', (e) => apply());
}));
