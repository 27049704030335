import React, { useState } from 'react';

import { ChevronDownIcon } from '@radix-ui/react-icons';

import * as Popover from '@/lib/@radix-ui/react-popover';

import CheckboxItem from './CheckboxItem';

const filterLabel = 'Status';

const Status: React.FC<{
  currentlySelected: string[];
  defaultStatuses: string[];
  items: Record<string, string>;
  onClose: (selected: string[]) => void;
}> = ({ defaultStatuses, currentlySelected, items, onClose }) => {
  const [selectedStatuses, setSelectedStatuses] = useState(currentlySelected);

  const handleClose = () =>
    currentlySelected !== selectedStatuses ? onClose(selectedStatuses) : null;

  const handleCheck = (status: string) => {
    const present = selectedStatuses.includes(status);

    if (present) {
      setSelectedStatuses(
        selectedStatuses.filter((statuses: string) => status !== statuses),
      );
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const getFilterLabel = () => {
    if (currentlySelected.length === defaultStatuses.length) {
      return filterLabel;
    }

    if (currentlySelected.length === 1) {
      return `${filterLabel}: ${items[currentlySelected[0]]}`;
    }

    return `${filterLabel}: Multiple`;
  };

  return (
    <div className="">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {getFilterLabel()}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content"
            align="start"
            onCloseAutoFocus={handleClose}
          >
            {Object.keys(items).map((key) => (
              <CheckboxItem
                text={items[key]}
                key={key}
                onCheckedChange={() => handleCheck(key)}
                checked={selectedStatuses.includes(key)}
              />
            ))}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default Status;
