document.addEventListener('turbolinks:load', (event) => {
  const {
    controller,
  } = document.body.dataset;

  if (controller !== 'dispatcher') return;
  // query for checkbox toggle
  const element = document.getElementById('radio-btn-other-ride');
  const submit_btn = document.getElementById('ride_eta_bid_create_both_legs');

  if (element != null) {
    element.addEventListener('change', (e) => {
      const classToToggle = document.getElementById('date-picker');
      if (e.target.checked) {
        classToToggle.classList.remove('hide');
        submit_btn.classList.add('submit-btn-for-both-legs');
      } else {
        classToToggle.classList.add('hide');
        submit_btn.classList.remove('submit-btn-for-both-legs');
      }
    });
  }
});
